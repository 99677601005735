/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// import images here
import groupImage1 from "assets/GroupAssets/images/indra_grps1.jpg";
import groupImage2 from "assets/GroupAssets/images/indra_grps2.jpg";
import grp1 from "assets/GroupAssets/images/grp1.jpg";
import grp2 from "assets/GroupAssets/images/grp2.jpg";
import grp3 from "assets/GroupAssets/images/grp3.jpg";
import grp4 from "assets/GroupAssets/images/grp4.jpg";
import grp5 from "assets/GroupAssets/images/grp5.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import blankGroup from "assets/GroupAssets/images/blannk_group.jpg";
import defaultUser from "assets/ActivityAssests/images/default-user.png";

const GroupListArea = () => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();

  const groupListRef = useRef(null);

  // importing global context data
  const {
    groupTitle,
    groupCity,
    groupCategory,
    refreshGroupList,
    refreshGroupsLists,
    searchGroupsList,
    resetGroupNameOnly,
    resetGroupCategoryOnly,
    resetGroupCityOnly,
    deleteSingleGroupOnly,
    groupFilterType,
    resetGroupFilterOnly,
    groupPageReset,
    setGroupPageReset,
  } = useContext(GlobalIndraInfo);

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(0);

  // array for counting glow rows
  const glowCount = [1, 2, 3];

  // initial useState for API data
  const [groupsData, setGroupsData] = useState([]);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  // function for all events lists
  const getGroupsLists = async () => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GROUP_LIST +
        `?token=${token}&modulefrom=frontend&start=${page * 12}&limit=12`;

      if (groupTitle != "") {
        requestURL += `&filtertitle=${groupTitle}`;
      }

      if (groupCity != "") {
        requestURL += `&filteraddress=${groupCity}`;
      }

      if (groupCategory.length != 0) {
        const filterCategories = groupCategory.map((data) => data.value);

        requestURL += `&filtercategory=${filterCategories.join(",")}`;
      }

      if (groupFilterType != null) {
        requestURL += `&selectedfilter=${groupFilterType.value}`;
      }

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setGroupsData(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/
        } else {
          setGroupsData([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // function for focus to commentbox
  const groupRefToggle = () => {
    groupListRef.current?.scrollIntoView();
  };

  //function for reset group name only
  const resetGroupTitle = () => {
    resetGroupNameOnly(true);
    setPage(0);
  };

  //function for reset group category only
  const resetGroupCategory = () => {
    resetGroupCategoryOnly(true);
    setPage(0);
  };

  //function for reset group city only
  const resetGroupCity = () => {
    resetGroupCityOnly(true);
    setPage(0);
  };

  // function for reset group filter
  const resetGroupFilter = () => {
    resetGroupFilterOnly(true);
    setPage(0);
  };

  useEffect(() => {
    if (groupPageReset) {
      setPage(0);
      setGroupPageReset(false);
    }
  }, [groupPageReset]);

  // function for delete single group category
  const deleteSingleGroup = (index) => {
    if (index === 0) {
      setPage(0);
    }
    let deletedEventGroup = [...groupCategory];
    deletedEventGroup.splice(index, 1);
    deleteSingleGroupOnly(deletedEventGroup);
  };

  //use Effect for search groups query
  useEffect(() => {
    if (
      groupTitle != "" ||
      groupCity != "" ||
      groupCategory.length > 0 ||
      groupFilterType != null
    ) {
      getGroupsLists();
      searchGroupsList(null);
    } else {
      getGroupsLists();
    }
  }, [page, groupTitle, groupCity, groupCategory, groupFilterType]);

  return (
    <>
      <div ref={groupListRef} name="scrollGroupRefDiv" />
      <section className="champions_area placeholder-glow">
        {groupTitle != "" ||
        groupCategory.length > 0 ||
        groupCity != "" ||
        groupFilterType != null ? (
          <div id="filter_result" className="mb-4">
            <div className="container">
              <div className="d-flex flex-wrap gap-2">
                {/* ---------- Search Title Section starts here ---------- */}

                {groupTitle == "" ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Name")}
                      </label>
                      {/* <input
                  type="text"
                  // value="Victor Herrero"
                  className="form-control shadow-none rounded-0"
                /> */}
                      <label>{groupTitle}</label>
                      <Link to="#" className="cancel" onClick={resetGroupTitle}>
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ---------- Search Skills Section starts here ---------- */}

                {groupCategory.length == 0 ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Category")}
                      </label>
                      <ul className="badges d-flex flex-wrap gap-2">
                        {groupCategory.map((groupNames, index) => {
                          return (
                            <li className="d-flex gap-2 border" key={index}>
                              {groupNames.label}
                              <Link
                                to="#"
                                onClick={() => deleteSingleGroup(index)}
                              >
                                <i className="d-block material-icons">clear</i>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetGroupCategory}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ---------- Search Location Section starts here ---------- */}

                {groupCity == "" ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Location")}
                      </label>
                      <label>{groupCity}</label>
                      <Link to="#" className="cancel" onClick={resetGroupCity}>
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ----- searched group filter value ------- */}
                {groupFilterType == null ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Type")}
                      </label>
                      <label>{groupFilterType.label}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetGroupFilter}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className="container">
          {isLoading ? (
            <div className="champions_outer">
              {/* ----------------- Glow placeholder starts here ----------------- */}
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-4 col-sm-6" key={count}>
                      <div className="events_itemBx sameheight_col">
                        <div className="bookmarks_grps">
                          <span
                            className="placeholder rounded-2"
                            style={{ width: "16px", height: "20px" }}
                          ></span>
                        </div>
                        <div className="groupsbx_outer">
                          <div className="groups_textbx_pnl pd_20">
                            <div className="chappions_rating">
                              <span className="placeholder placeholder-md rounded-2 col-2"></span>
                            </div>
                            <div className="leftchampion_text">
                              <span
                                className="placeholder d-block rounded-2 col-6"
                                style={{ height: "20px" }}
                              ></span>
                            </div>
                            <div className="experts_skill">
                              <ul>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-2 me-2"
                                    style={{ width: "80px", height: "30px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-2 me-2"
                                    style={{ width: "80px", height: "30px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-2 me-2"
                                    style={{ width: "80px", height: "30px" }}
                                  ></span>
                                </li>
                              </ul>
                            </div>

                            <div className="ambassadors_users grps_user">
                              <ul>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-circle"
                                    style={{ width: "36px", height: "36px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-circle"
                                    style={{ width: "36px", height: "36px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-circle"
                                    style={{ width: "36px", height: "36px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-circle"
                                    style={{ width: "36px", height: "36px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder d-block rounded-circle"
                                    style={{ width: "36px", height: "36px" }}
                                  ></span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="groups_pic">
                            <span
                              className="placeholder d-block col-12"
                              style={{ height: "197px" }}
                            ></span>
                          </div>
                          <div className="details_btn pd_20">
                            <span
                              className="placeholder d-block rounded-2 col-12"
                              style={{ height: "50px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* ----------------- Glow placeholder ends here ----------------- */}
            </div>
          ) : (
            <div className="champions_outer">
              {/* ----------------- Content starts here ----------------- */}
              {groupsData.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={blankLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>{t("Sorry....!")}</h2>
                        {groupTitle != "" ||
                        groupCategory.length > 0 ||
                        groupCity != "" ||
                        groupFilterType != null ? (
                          <p>{t("No result found.")}</p>
                        ) : (
                          <p>
                            {t("Groups are not available for this moment.")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {groupsData.map((group, index) => (
                    <div className="col-lg-4 col-sm-6" key={index}>
                      <div className="events_itemBx sameheight_col">
                        <div className="bookmarks_grps">
                          <Link to="#">
                            <i className="material-icons-outlined">
                              {" "}
                              bookmark_border{" "}
                            </i>
                          </Link>
                        </div>

                        <div className="groupsbx_outer">
                          <div className="groups_textbx_pnl pd_20">
                            <div className="chappions_rating">
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                                <span>{group.totalratings}</span>
                                <em className="mx-1">({group.totalreviews})</em>
                              </Link>
                            </div>

                            <div className="leftchampion_text">
                              <h3>{group.title}</h3>
                            </div>

                            {/* ----------- Categories sections starts here ----------- */}
                            <div className="experts_skill">
                              {group.groupcategorylist &&
                              group.groupcategorylist.length > 0 ? (
                                <ul>
                                  {group.groupcategorylist
                                    .slice(0, 2)
                                    .map((category, index) => (
                                      <li key={index}>
                                        <Link to="#">
                                          <span>{category.name}</span>
                                        </Link>
                                      </li>
                                    ))}

                                  {group.groupcategorylist.length <=
                                  2 ? null : (
                                    <li>
                                      <Link to="#" className="skills_count">
                                        +{group.groupcategorylist.length - 2}{" "}
                                        more
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              ) : null}
                            </div>

                            {/* ----------- Tags sections ends here ----------- */}

                            {/* ----------- Members sections starts here ----------- */}

                            <div className="ambassadors_users grps_user">
                              {group.groupmemberlist.length > 0 ? (
                                <ul>
                                  {group.groupmemberlist.map(
                                    (member, index) => (
                                      <li key={index}>
                                        <Link to="#">
                                          <img
                                            src={
                                              member.groupmemberimage === ""
                                                ? defaultUser
                                                : url.API_SERVER_URL +
                                                  member.groupmemberimage
                                            }
                                            alt=""
                                          />
                                        </Link>
                                      </li>
                                    )
                                  )}

                                  {group.groupmemberlist.length <= 8 ? null : (
                                    <li>
                                      <p style={{ marginLeft: "10px" }}>
                                        +{group.groupmemberlist.length - 8}
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              ) : null}
                            </div>

                            {/* ----------- Members sections ends here ----------- */}
                          </div>

                          {/* ----------- Group Image section starts here ----------- */}
                          <div className="groups_pic">
                            <img
                              src={
                                group.grouplogo === ""
                                  ? blankGroup
                                  : url.API_SERVER_URL + group.grouplogo
                              }
                              alt=""
                            />
                          </div>

                          {/* ----------- Details button sections starts here ----------- */}
                          <div className="details_btn">
                            <Link
                              to={`/group/details/${group._id}`}
                              className="basic_btn ylw_btn"
                            >
                              {group.moderatorid.toString() ===
                              userInfo._id.toString() ? (
                                <i className="material-icons me-3">done_all</i>
                              ) : (
                                <span>
                                  {group.isjoined ? (
                                    <span>
                                      {group.isapproved ? (
                                        <i className="material-icons me-3">
                                          done_all
                                        </i>
                                      ) : (
                                        <i className="material-icons me-3">
                                          done
                                        </i>
                                      )}
                                    </span>
                                  ) : null}
                                </span>
                              )}
                              {t("See Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* ------------ Pagination starts here ------------ */}
                  <div className="pagination_list">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page !== 0) {
                              nextPage(page - 1);
                              groupRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_back_ios
                          </i>
                        </Link>
                      </li>
                      {totalPages.map((pageNo) => {
                        return (
                          <li
                            className={page + 1 == pageNo ? "active" : ""}
                            key={pageNo}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                nextPage(pageNo - 1);
                                groupRefToggle();
                              }}
                            >
                              {pageNo}
                            </Link>
                          </li>
                        );
                      })}

                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page != lastPageNo - 1) {
                              nextPage(page + 1);
                              groupRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_forward_ios
                          </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* ------------ Pagination ends here ------------ */}
                </div>
              )}
              {/* ----------------- Content ends here ----------------- */}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default GroupListArea;
