/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import { t } from "i18next";
import ViewGalleryImageModal from "../Modals/ViewGalleryImageModal";

const EventGallery = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  const glowCount = [1, 2];

  const [galleryData, setGalleryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewImageUrl, setViewImageUrl] = useState("");

  const getGalleryData = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.GALLERY_LISTS + `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.gallery) {
            setGalleryData(response.data.gallery);
          } else {
            setGalleryData([]);
          }
        } else {
          setGalleryData([]);
        }
        setIsLoading(false);
      } else {
        setGalleryData([]);
      }
    } catch (error) {}
  };

  // function view image
  const ViewImageHandler = (gallery) => {
    setViewImageUrl(url.API_SERVER_URL + gallery.path);
  };

  useEffect(() => {
    if (params.id) {
      getGalleryData();
    }
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <div className="training_outer">
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>
            <div className="portfolio_outer gallery_outer">
              {glowCount.map((count) => {
                return (
                  <div className="mx-1" key={count}>
                    <span
                      className="placeholder d-block rounded-3"
                      style={{ width: "400px", height: "250px" }}
                    ></span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="training_outer">
          {galleryData.length == 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("No photos available for this event.")}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="gallery_main">
              {/* <div className="contnt_hdng">
                <h4>{t("Gallery")}</h4>
              </div> */}
              <div className="portfolio_outer gallery_outer">
                {galleryData.map((gallery, index) => {
                  return (
                    <div className="portfolio_gallery_item" key={index}>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#view_event_gallery_image_modal"
                        onClick={() => ViewImageHandler(gallery)}
                      >
                        <img src={url.API_SERVER_URL + gallery.path} alt="" />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <ViewGalleryImageModal viewImageUrl={viewImageUrl} />
    </>
  );
};

export default EventGallery;
