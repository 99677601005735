// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

export const getCurrentDateInString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getAllCategories = async (parentSlug) => {
  try {
    let requestUrl =
      url.API_BASE_URL + url.API_GET_CATEGORIES + `?ecosystemslug=INDRA`;

    if (parentSlug && parentSlug !== "") {
      requestUrl += `&slug=${parentSlug}`;
    }

    const response = await getData(requestUrl);

    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const truncateHTML = (html, maxLength) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html"); // Parse the HTML content
  const textContent = doc.body.textContent || ""; // Extract text content
  return textContent.length > maxLength
    ? textContent.slice(0, maxLength) + "..."
    : textContent;
};

export const getFormattedDate = (dateString = "") => {
  if (dateString !== "") {
    const parts = dateString.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate; // Output: 17/10/2018
  } else {
    return "";
  }
};
