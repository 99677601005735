/* eslint-disable */
import axios from "axios";

// GET method implementation to fetch data from API
const getData = async (url = "") => {
  let language = localStorage.getItem("language");
  if (language == null) {
    language = "en";
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  } else {
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  }

  return fetch(url, { method: "GET" })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
};

// POST method implementation to send data to API
const postData = async (url = "", data = {}) => {
  // language import starts here
  let language = localStorage.getItem("language");

  if (language == null) {
    language = "en";
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  } else {
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  }

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

// PUT method implementation to send edited/updated data to API
const putData = async (url = "", data = {}) => {
  let language = localStorage.getItem("language");
  if (language == null) {
    language = "en";
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  } else {
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  }

  let result = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return result.json();
};

// DELETE method implementation to send deleted instruction to API
const deleteData = async (url = "", data = {}) => {
  let result = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return result.json();
};

//upload single file method
const uploadFile = async (url, formValues = {}) => {
  try {
    const formData = new FormData();

    formData.append("upload", formValues);

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

//upload multiple file method
const uploadMultipleFile = async (url, formValues = []) => {
  try {
    const formData = new FormData();

    let objArray = Object.keys(formValues);

    objArray.forEach((element) => {
      formData.append("uploads", formValues[element]);
    });

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

// recomended function for sending form data with file and images ids
const postFormdata = async (url, formValues = {}) => {
  let language = localStorage.getItem("language");
  if (language == null) {
    language = "en";
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  } else {
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  }

  const formData = new FormData();

  let objArray = Object.keys(formValues); // get the keys, i.e form input name

  objArray.forEach((element) => {
    if (Array.isArray(formValues[element])) {
      formData.append(element, JSON.stringify(formValues[element]));
    } else {
      formData.append(element, formValues[element]);
    }
  });

  // declare variable for sending in object
  const config = {
    method: "POST",
    body: formData,
  };

  return fetch(url, config).then((response) => response.json());
};

/*------ optional function for send normal formdata with files -------*/
const postFileFormData = async (url, formValues = {}) => {
  let language = localStorage.getItem("language");
  if (language == null) {
    language = "en";
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  } else {
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  }

  const formData = new FormData();

  let objArray = Object.keys(formValues);

  objArray.forEach((element) => {
    formData.append(element, formValues[element]);
  });

  const config = {
    method: "POST",
    body: formData,
  };

  return fetch(url, config).then((response) => response.json());
};

/*------- This is the method for chat section only --------*/
const postUploadFileData = async (url, formValues = {}) => {
  let language = localStorage.getItem("language");
  if (language == null) {
    language = "en";
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  } else {
    if (url.match(/[?]/g) == null) {
      url = url + `?language=${language}`;
    } else {
      url = url + `&language=${language}`;
    }
  }

  const formData = new FormData();

  let objArray = Object.keys(formValues); // get the keys, i.e form input name

  objArray.forEach((element) => {
    if (Array.isArray(formValues[element])) {
      let elementArray = Object.keys(formValues[element]);

      elementArray.forEach((item) => {
        // if multile files are sent in as an array, append each file one by one

        formData.append("uploads[]", formValues[element][item]);
      });
    } else {
      formData.append(element, formValues[element]);
    }
  });

  // declare variable for sending in object
  const config = {
    method: "POST",
    body: formData,
  };

  return fetch(url, config).then((response) => response.json());
};

/*------- end of the formdata with file uploader --------*/

// V2 Gatewat functions -----------------------
//----------------------------------------------

//get method
const getData_v2 = (url = "") => {
  return request("get", url);
};

//post method
const postData_v2 = (url = "", inputData = {}) => {
  return request("post", url, inputData);
};

//put/update method
const putData_v2 = (url = "", inputData = {}) => {
  return request("put", url, inputData);
};

//delete method
const deleteData_v2 = (url = "") => {
  return request("delete", url);
};

//common method
const request = async (method, url = "", params = {}) => {
  try {
    const { data } = await axios[method](url, params);
    return data;
  } catch (error) {
    return error.message;
  }
};

//upload single file method
const uploadSingleFile_v2 = async (url, formValues = {}) => {
  try {
    const formData = new FormData();

    formData.append("upload", formValues);

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

//upload multiple file method
const uploadMultipleFile_v2 = async (url, formValues = []) => {
  try {
    const formData = new FormData();

    let objArray = Object.keys(formValues);

    objArray.forEach((element) => {
      formData.append("uploads", formValues[element]);
    });

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

export {
  postData,
  getData,
  putData,
  deleteData,
  postFormdata,
  uploadFile,
  uploadMultipleFile,
  postFileFormData,
  postUploadFileData,
  getData_v2,
  postData_v2,
  putData_v2,
  deleteData_v2,
  uploadSingleFile_v2,
  uploadMultipleFile_v2,
};
