/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// Importing internal CSS here
import "./css/ChampionsFilter.css";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ActivityAssests/images/blank-champion.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

import { useTranslation } from "react-i18next";

const ChampionListArea = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    searchChampions,
    champName,
    champCategory,
    champCity,
    refreshChampLists,
    refreshChampionLists,
    resetChampNameOnly,
    resetChampSkillsOnly,
    resetChampLocationOnly,
    deleteSingleSkill,
    champPageReset,
    setChampPageReset,
  } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  const championListRef = useRef(null);

  // array for counting glow rows
  const glowCount = [1, 2, 3, 4];

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(0);

  //initial for champions lists
  const [championsLists, setChampionsLists] = useState([]);
  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for toggle eventRef
  const championRefToggle = () => {
    championListRef.current?.scrollIntoView();
  };

  // function for all champion lists
  const getChampionsLists = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_CHAMPIONS +
      `?token=${token}` +
      `&start=${page * 12}&limit=12`;

    if (champName !== "") {
      requestURL = requestURL + `&filterfullname=${champName}`;
    }

    if (champCategory != null && champCategory.length > 0) {
      requestURL += `&filterskills=${champCategory
        .map((skill) => skill.id)
        .join(",")}`;
    }

    if (champCity !== "") {
      requestURL = requestURL + `&filtercity=${champCity}`;
    }

    console.log(requestURL);

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setChampionsLists(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }

          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/
        } else {
          setChampionsLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  //function for reset only champion name
  const resetChampName = () => {
    resetChampNameOnly(true);
    setPage(0);
  };

  // function for reset only chanmpion skills
  const resetChampSkills = () => {
    resetChampSkillsOnly(true);
    setPage(0);
  };

  // function for reset only champion location
  const resetChampLocation = () => {
    resetChampLocationOnly(true);
    setPage(0);
  };

  // function for delete single skill values
  const deleteSingleValue = (index) => {
    if (index === 0) {
      setPage(0);
    }
    let deletedSkill = [...champCategory];
    deletedSkill.splice(index, 1);
    deleteSingleSkill(deletedSkill);
  };

  useEffect(() => {
    if (champPageReset) {
      setPage(0);
      setChampPageReset(false);
    }
  }, [champPageReset]);

  //use Effect for search champion query
  useEffect(() => {
    if (champName != "" || champCategory.length > 0 || champCity != "") {
      getChampionsLists();
      searchChampions(null);
    } else {
      getChampionsLists();
    }
  }, [page, champName, champCategory, champCity]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div ref={championListRef} name="scrollChampionRefDiv" />
      <section className="champions_area">
        {/* ------------- Filter Section starts here ------------- */}
        {champName != "" || champCategory.length > 0 || champCity != "" ? (
          <div id="filter_result" className="mb-4">
            <div className="container">
              <div className="d-flex flex-wrap gap-2">
                {/* ---------- Search Title Section starts here ---------- */}
                {champName != "" ? (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Name")}
                      </label>
                      <label>{champName}</label>
                      <Link to="#" className="cancel" onClick={resetChampName}>
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                ) : null}

                {/* ---------- Search Skills Section starts here ---------- */}
                {champCategory.length > 0 ? (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Skills")}
                      </label>
                      <ul className="badges d-flex flex-wrap gap-2">
                        {champCategory.map((data, index) => {
                          return (
                            <li className="d-flex gap-2 border" key={index}>
                              {data.label}{" "}
                              <Link
                                to="#"
                                onClick={() => deleteSingleValue(index)}
                              >
                                <i className="d-block material-icons">clear</i>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetChampSkills}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                ) : null}

                {/* ---------- Search Location Section starts here ---------- */}
                {champCity != "" ? (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Location")}
                      </label>
                      <label>{champCity}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetChampLocation}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {/* ------------- Filter Section ends here ------------- */}

        <div className="container">
          {isLoading ? (
            <div className="champions_outer">
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-3 col-6" key={count}>
                      <div className="champions_item">
                        <div className="champions_pic">
                          <span
                            className="placeholder d-block"
                            style={{ width: "290px", height: "253px" }}
                          ></span>
                        </div>
                        <div className="champions_textpnl">
                          <div className="champions_txtOuter">
                            <div className="leftchampion_text">
                              <span
                                className="placeholder rounded-2 mb-2"
                                style={{ width: "134px", height: "20px" }}
                              ></span>
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "94px", height: "16px" }}
                              ></span>
                            </div>
                            <div className="chappions_rating">
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "40px", height: "24px" }}
                              ></span>
                            </div>
                          </div>
                          <div className="details_btn">
                            <span
                              className="placeholder d-block rounded-2 col-12"
                              style={{ height: "50px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="champions_outer">
              {championsLists.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={blankLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>{t("Sorry....!")}</h2>
                        <p>
                          {t("Champions are not available at this moment.")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {championsLists.map((champions, index) => {
                    return (
                      <div className="col-lg-3 col-6" key={index}>
                        <div className="champions_item">
                          <div className="champions_pic">
                            <img
                              src={
                                champions.userimage === ""
                                  ? defaultUser
                                  : url.API_SERVER_URL + champions.userimage
                              }
                              alt="user"
                            />
                          </div>
                          <div className="champions_textpnl">
                            <div className="champions_txtOuter">
                              <div className="leftchampion_text">
                                <h3>{champions.username}</h3>
                                <h5>{champions.position}</h5>
                              </div>
                              <div className="chappions_rating">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                  <span>{champions.rating}</span>
                                </Link>
                              </div>
                            </div>
                            <div className="details_btn">
                              <Link
                                to={`/champions/details/${champions._id}`}
                                className="basic_btn ylw_btn"
                              >
                                {champions.id == userInfo.id ? (
                                  <i className="material-icons me-3">
                                    done_all
                                  </i>
                                ) : (
                                  <span>
                                    {champions.isconnect ? (
                                      <span>
                                        {champions.connectstatus ? (
                                          <i className="material-icons me-3">
                                            done_all
                                          </i>
                                        ) : (
                                          <i className="material-icons me-3">
                                            done
                                          </i>
                                        )}
                                      </span>
                                    ) : null}
                                  </span>
                                )}{" "}
                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* ------------ Pagination starts here ------------ */}
                  <div className="pagination_list">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page != 0) {
                              nextPage(page - 1);
                              championRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_back_ios
                          </i>
                        </Link>
                      </li>
                      {totalPages.map((pageNo, index) => {
                        return (
                          <li
                            className={page + 1 == pageNo ? "active" : ""}
                            key={index}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                nextPage(pageNo - 1);
                                championRefToggle();
                              }}
                            >
                              {pageNo}
                            </Link>
                          </li>
                        );
                      })}

                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page != lastPageNo - 1) {
                              nextPage(page + 1);
                              championRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_forward_ios
                          </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* ------------ Pagination ends here ------------ */}
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ChampionListArea;
