/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import images here
import certificate from "assets/CourseDetailsAssests/images/certificate_frame.png";
import blankVideo from "assets/CourseDetailsAssests/images/course-vdo-right-blank.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import certStamp from "assets/CourseDetailsAssests/images/certificate_stamp.png";
import signedCopy from "assets/CourseDetailsAssests/images/signed-copy.png";

// importing context API here
import { GlobalIndraInfo } from "App";

const Docs = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const createPostPermission = userInfo.privilege.PRV_ACTIVITY_CREATE;

  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // get info from context
  const { subscriberStatus, isCourseUploader } = useContext(GlobalIndraInfo);

  const [courseName, setCourseName] = useState("");
  const [joinedDate, setjoinedDate] = useState("");
  const [subscriberName, setSubscriberName] = useState("");
  const [totalScore, settotalScore] = useState("");
  const [obtainedScore, setobtainedScore] = useState("");
  const [certificateFeedback, setCertificateFeedback] = useState("-");
  const [completeDate, setCompleteDate] = useState("");
  const [isCertified, setIsCertified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // function for get course about
  const getSubscriberDetails = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GET_CERTIFICATE_INFORMATION +
      `?token=${token}&course=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log("response in cert", response);

      setIsLoading(false);

      if (response.status) {
        setCourseName(response.data.coursename);
        setjoinedDate(response.data.joindate);
        setSubscriberName(response.data.name);
        settotalScore(response.data.subscribertotalscore);
        setobtainedScore(response.data.subscriberobtainedscore);
        setCertificateFeedback(response.data.certificationcomment || "-");
        setCompleteDate(response.data.certificationdate || "");
        setIsCertified(response.data.iscertified || false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (params.id) {
      getSubscriberDetails();
    }
  }, [params.id]);

  return (
    <>
      {/* --------------- Certification Body starts here --------------- */}
      {isLoading ? null : (
        <div className="tab-pane show active fade" id="doc">
          {/* ------------- if the user is a moderator ------------- */}
          {userInfo.role.slug === "SUPER_ADMIN" ||
          userInfo.role.slug === "ADMIN" ||
          isCourseUploader ? (
            <div className="scores_pnl lesson_pnl">
              {/* -------------- Statistics starts here -------------- */}
              <div className="scorebx_outer">
                <div className="scorebx_item">
                  {joinedDate == "" ? null : (
                    <div className="scorebx_innr">
                      <h5>{t("Date of Joining")}</h5>
                      <h4>{joinedDate}</h4>
                    </div>
                  )}
                </div>
                <div className="scorebx_item">
                  {completeDate == "" ? null : (
                    <div className="scorebx_innr">
                      <h5>{t("Date of Certification")}</h5>
                      <h4>{completeDate}</h4>
                    </div>
                  )}
                </div>
                <div className="scorebx_item">
                  {totalScore == "" ? null : (
                    <div className="scorebx_innr">
                      <h5>{t("Score")}</h5>
                      <h4>
                        {obtainedScore} {t("out of")} {totalScore}
                      </h4>
                    </div>
                  )}
                </div>
              </div>

              {/* -------------- Certificate message starts here -------------- */}
              <div className="certificate_bx">
                <div
                  className="donate_certificate"
                  style={{
                    backgroundImage: certificate,
                  }}
                >
                  <div className="certificate_hdng">
                    <h2>{t("certificate of completion")}</h2>
                    {/* <span className="cerficate_line">
                  <img src="images/line.png" alt="" />
                </span> */}
                  </div>
                  <div className="certificate_nameHdng">
                    <p>{t("We hereby express our sincere appreciation to")}</p>
                    <h3>{subscriberName}</h3>
                  </div>
                  <div className="certificate_para">
                    <p>
                      {t("This certifies that")} {subscriberName} {t("has")}{" "}
                      {t("completed the course")}
                      <span className="fw-bold">
                        {courseName == "" ? null : courseName}
                      </span>{" "}
                      {t("successfully!")}{" "}
                      {t("and has obtained the certificate")}{" "}
                      <span className="fw-bold">
                        {certificateFeedback == "" ? null : certificateFeedback}
                      </span>
                    </p>
                  </div>
                  <div className="crtificate_signOuter">
                    <div className="certificate_date">
                      {completeDate == "" ? null : (
                        <h5>
                          {t("Date")} <span>{completeDate}</span>
                        </h5>
                      )}
                    </div>
                    <div className="certificate_stamp">
                      <img src={certStamp} alt="" />
                    </div>
                    <div className="certificate_sign">
                      <img src={signedCopy} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------- Download button links starts here -------------- */}
              <div className="download_btn">
                {/* <Link to="#" className="basic_btn">
              Download PDF
            </Link> */}
              </div>
            </div>
          ) : (
            <>
              {/* ------------- else if the user is a subscriber ------------- */}
              {subscriberStatus ? (
                <div className="scores_pnl lesson_pnl">
                  {/* {certiFication == true && academyCertification == true ? ( */}
                  {isCertified ? (
                    <>
                      {/* -------------- Statistics starts here -------------- */}
                      <div className="scorebx_outer">
                        <div className="scorebx_item">
                          {joinedDate == "" ? null : (
                            <div className="scorebx_innr">
                              <h5>{t("Date of Joining")}</h5>
                              <h4>{joinedDate}</h4>
                            </div>
                          )}
                        </div>
                        <div className="scorebx_item">
                          {completeDate == "" ? null : (
                            <div className="scorebx_innr">
                              <h5>{t("Date of Certification")}</h5>
                              <h4>{completeDate}</h4>
                            </div>
                          )}
                        </div>
                        <div className="scorebx_item">
                          {obtainedScore == "" ? null : (
                            <div className="scorebx_innr">
                              <h5>{t("Score")}</h5>
                              <h4>
                                {obtainedScore} {t("out of")} {totalScore}
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* -------------- Certificate message starts here -------------- */}
                      <div className="certificate_bx">
                        <div
                          className="donate_certificate"
                          style={{
                            backgroundImage: certificate,
                          }}
                        >
                          <div className="certificate_hdng">
                            <h2>{t("certificate of completion")}</h2>
                            {/* <span className="cerficate_line">
                                <img src="images/line.png" alt="" />
                                </span> 
                            */}
                          </div>
                          <div className="certificate_nameHdng">
                            <p>
                              {t(
                                "We hereby express our sincere appreciation to"
                              )}
                            </p>
                            <h3>{subscriberName}</h3>
                          </div>
                          <div className="certificate_para">
                            <p>
                              {t("This certifies that")} {subscriberName}{" "}
                              {t("has")} {t("completed the course")}
                              <span className="fw-bold">
                                {courseName == "" ? null : courseName}
                              </span>{" "}
                              {t("successfully!")}{" "}
                              {t("and has obtained the certificate")}{" "}
                              <span className="fw-bold">
                                {certificateFeedback == ""
                                  ? null
                                  : certificateFeedback}
                              </span>
                            </p>
                          </div>
                          <div className="crtificate_signOuter">
                            <div className="certificate_date">
                              {completeDate == "" ? null : (
                                <h5>
                                  {t("Date")} <span>{completeDate}</span>
                                </h5>
                              )}
                            </div>
                            <div className="certificate_stamp">
                              <img src={certStamp} alt="" />
                            </div>
                            <div className="certificate_sign">
                              <img src={signedCopy} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* -------------- Download button links starts here -------------- */}
                      <div className="download_btn">
                        <Link to="#" className="basic_btn">
                          {t("Download PDF")}
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="empty_lessonsArea">
                          <div className="empty_lessonpic">
                            <img src={blankLesson} alt="blanklesson" />
                          </div>
                          <div className="empty_lessons_text">
                            <h2>{t("Sorry....!")}</h2>
                            <p>{t("Certification not obtained yet")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="scores_pnl lesson_pnl">
                  {/* ------------- else the user is a niether ------------- */}
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="empty_lessonsArea">
                        <div className="empty_lessonpic">
                          <img src={blankLesson} alt="blanklesson" />
                        </div>
                        <div className="empty_lessons_text">
                          <h2>{t("Sorry....!")}</h2>
                          <p>{t("You don't have privilege")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* --------------- Certification Body ends here --------------- */}
    </>
  );
};

export default Docs;
