/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// import inages
import defaultUser from "assets/ActivityAssests/images/default-user.png";
import { truncateHTML } from "helpers/Common/CommonHelper";

const HomeActivityLists = () => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const dummyActivityData = [
    {
      id: 1,
      hideButton: true,
    },
    {
      id: 2,
      hideButton: true,
    },
    {
      id: 3,
      hideButton: true,
    },
    {
      id: 4,
      hideButton: true,
    },
  ];

  // Custom CSS for the Carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // for routing
  const history = useHistory();

  const [activityPost, setActivityPost] = useState([]);

  // array for counting glow rows
  const glowCount = [1, 2, 3, 4];

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  /*--- function for get all post lists ---*/
  const allForums = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_ACTIVITIES_LIST +
      `?token=${token}&modulefrom=frontend&start=0&limit=10`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          let activityData = response.data.filter((activity) => {
            return activity.isprivate == false;
          });

          if (activityData.length <= 3) {
            let newActivityArr = activityData.concat(dummyActivityData);
            setActivityPost(newActivityArr);
          } else {
            setActivityPost(activityData.slice(0, 10));
          }
        } else {
          setActivityPost([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    allForums();
  }, []);

  return (
    <>
      <section className="activity_sec pd_30">
        <div className="container">
          {/* ----------- Heading section starts here ----------- */}
          <div className="slideHdng_outer">
            <div className="page_hdng">
              <h2>{t("Best Activity")}</h2>
            </div>
            <div className="see_moreBtn">
              <Link to="/activity" className="border_btn ylwbrdr_btn">
                {t("See All")}
              </Link>
            </div>
          </div>
          {/* ----------- Heading section starts here ----------- */}

          {/* ----------- Carousel section starts here ----------- */}
          {isLoading ? (
            <div className="slide_Outer">
              {/* ------------ Glow placeholder section starts here ------------ */}
              <div className="activity_slider slider_innr">
                <Carousel ssr responsive={responsive}>
                  {glowCount.map((count) => {
                    return (
                      <div
                        className={
                          count == 1 ? "slide_Item mb-4" : "slide_Item"
                        }
                        key={count}
                      >
                        <div className="activity_slideItem">
                          <div className="glow_area list_glowArea">
                            <div className="plan p-3 border rounded-3 placeholder-glow">
                              <span className="placeholder placeholder-sm col-12 rounded-3 mb-3"></span>
                              <span className="placeholder placeholder-sm col-10 rounded-3 mb-3"></span>
                              <div className="user_placeHoledr">
                                <div className="default_usr">
                                  <img src={defaultUser} alt="default-user" />
                                </div>
                                <div className="default_usr_name">
                                  <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                </div>
                              </div>
                              <div className="data_btnOuter">
                                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                              </div>
                              <span className="placeholder placeholder-sm col-2 rounded-3 mb-3"></span>
                              <p className="data_btnOuter placeholder-glow">
                                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              {/* ------------ Glow placeholder section ends here ------------ */}
            </div>
          ) : (
            <div className="slide_Outer">
              {activityPost.length > 0 && (
                <div className="activity_slider slider_innr">
                  <Carousel ssr showDots responsive={responsive}>
                    {activityPost.map((activity, index) => {
                      const MAX_LENGTH = 100; // Limit length of content

                      const truncatedContent = truncateHTML(
                        activity.description,
                        MAX_LENGTH
                      );

                      return (
                        <div
                          className={
                            index == 0 ? "slide_Item mb-4" : "slide_Item"
                          }
                          key={index}
                        >
                          {activity.hideButton ? (
                            <div className="activity_slideItem">
                              <div className="glow_area list_glowArea">
                                <div className="plan p-3 border rounded-3 placeholder-glow">
                                  <span className="placeholder placeholder-sm col-12 rounded-3 mb-3"></span>
                                  <span className="placeholder placeholder-sm col-10 rounded-3 mb-3"></span>
                                  <div className="user_placeHoledr">
                                    <div className="default_usr">
                                      <img
                                        src={defaultUser}
                                        alt="default-user"
                                      />
                                    </div>
                                    <div className="default_usr_name">
                                      <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                      <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                    </div>
                                  </div>
                                  <div className="data_btnOuter">
                                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                  </div>
                                  <span className="placeholder placeholder-sm col-2 rounded-3 mb-3"></span>
                                  <p className="data_btnOuter placeholder-glow">
                                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="activity_slideItem">
                              <div className="activity_usrPnl">
                                {/* --------- Activity Moderator Image Section --------- */}
                                <div className="user_activty">
                                  <img
                                    src={
                                      activity.moderatorimage !== ""
                                        ? url.API_SERVER_URL +
                                          activity.moderatorimage
                                        : defaultUser
                                    }
                                    alt="moderatorPic"
                                    className=""
                                  />
                                </div>

                                {/* --------- Activity Moderator Name Section --------- */}
                                <div className="activty_usrname">
                                  <h4>{activity.moderatorname}</h4>
                                  <h5>{activity.forumdate}</h5>
                                </div>
                              </div>

                              {/* --------- Activity Description Section --------- */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: activity.description,
                                }}
                                className="activity_para"
                              >
                                {/* <Link to="#">Read More</Link> */}
                              </div>

                              {/* --------- Activity Likes, Comments Section --------- */}
                              <div className="activty_comnts_likelist">
                                <ul>
                                  {/* --------- Likes Section --------- */}
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        thumb_up
                                      </i>
                                      <span>{activity.likescount}</span>
                                    </Link>
                                  </li>

                                  {/* --------- Chat Section --------- */}
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        chat
                                      </i>
                                      <span>{activity.commentscount}</span>
                                    </Link>
                                  </li>

                                  {/* --------- Share Section --------- */}
                                  {/* <li>
                                    <Link to="#">
                                      <i className="material-icons-outlined">
                                        share
                                      </i>
                                      <span>3</span>
                                    </Link>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              )}
            </div>
          )}

          {/* ----------- Carousel section starts here ----------- */}
        </div>
      </section>
    </>
  );
};

export default HomeActivityLists;
