/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

// import images
import graphBanner from "assets/ActivityAssests/images/graph-bnr.jpg";
import homeBanner1 from "assets/HomeAssets/Images/home-bnr1.jpg";
import homeBanner2 from "assets/HomeAssets/Images/home-bnr2.jpg";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const Banner = () => {
  const token = localStorage.getItem("token");

  const { callSlider, setCallSlider } = useContext(GlobalIndraInfo);

  const [bannerLists, setBannerLists] = useState([]); // initial for banner

  const { t } = useTranslation();

  const [indraRandomNumber, setIndraRandomNumber] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  // function for random number Banner Title
  const generateIndraRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * bannerLists.length);
    setIndraRandomNumber(randomNumber);
  };

  // function for get all banner lists
  const getAllBanners = async () => {
    try {
      setIsLoading(true);

      let requestURL = url.API_BASE_URL + url.API_BANNER_LISTS;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          setBannerLists(response.data);
        } else {
          setBannerLists([]);
        }
      }
    } catch (error) {}
  };

  // use effect get all banner slider data
  useEffect(() => {
    if (callSlider) {
      getAllBanners();
      setCallSlider(false);
    }
  }, [callSlider]);

  // use effect for random number
  useEffect(() => {
    generateIndraRandomNumber();
  }, [bannerLists]);

  // use effect for get all banner data
  useEffect(() => {
    getAllBanners();
  }, []);

  return (
    <section className="home_bnr_area">
      {isLoading ? (
        <div className="home_bnrslide_Outer">
          <div
            className="home_bnrSlider carousel slide"
            id="carouselExampleInterval"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div
                className="homeslide_item carousel-item active"
                style={{ backgroundColor: "#ddd" }}
                data-bs-interval="10000"
              >
                <div className="bnr_text_content">
                  <div className="container">
                    <div className="bnr_graph_textPnl">
                      <span
                        className="placeholder d-block rounded-2 mb-3"
                        style={{ width: "60%", height: "72px" }}
                      ></span>
                      <span
                        className="placeholder d-block rounded-2 mb-5"
                        style={{ width: "50%", height: " 72px" }}
                      ></span>
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "40%", height: "22px" }}
                      ></span>
                      <div className="bnr_btnOuter">
                        <span
                          className="placeholder d-block rounded-2 me-3"
                          style={{ width: "135px", height: "50px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2"
                          style={{ width: "135px", height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="homeslide_item carousel-item"
                style={{ backgroundColor: "#ddd" }}
                data-bs-interval="2000"
              >
                <div className="bnr_text_content">
                  <div className="container">
                    <div className="bnr_graph_textPnl">
                      <span
                        className="placeholder d-block rounded-2 mb-3"
                        style={{ width: "60%", height: "72px" }}
                      ></span>
                      <span
                        className="placeholder d-block rounded-2 mb-5"
                        style={{ width: "50%", height: "72px" }}
                      ></span>
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "40%", height: "22px" }}
                      ></span>
                      <div className="bnr_btnOuter">
                        <span
                          className="placeholder d-block rounded-2 me-3"
                          style={{ width: "135px", height: "50px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2"
                          style={{ width: "135px", height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="home_bnrslide_Outer">
          {bannerLists.length === 0 ? null : (
            <div
              className="home_bnrSlider carousel slide"
              id="carouselExampleInterval"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {/* --- first silde start ---- */}
                <div
                  className="homeslide_item carousel-item active"
                  style={{
                    backgroundImage: `url(${
                      bannerLists[indraRandomNumber].slidebanner === ""
                        ? homeBanner1
                        : url.API_SERVER_URL +
                          bannerLists[indraRandomNumber].slidebanner
                    })`,
                  }}
                  data-bs-interval="10000"
                >
                  <div className="bnr_text_content">
                    <div className="container">
                      <div className="bnr_graph_textPnl">
                        <h2>
                          {bannerLists[indraRandomNumber] ? (
                            <>{bannerLists[indraRandomNumber].title}</>
                          ) : null}
                        </h2>
                        <h3>
                          {bannerLists[indraRandomNumber] ? (
                            <>{bannerLists[indraRandomNumber].titlesecondary}</>
                          ) : null}
                        </h3>
                        {bannerLists[indraRandomNumber] ? (
                          <p>{bannerLists[indraRandomNumber].subtitle}</p>
                        ) : null}
                        {bannerLists[indraRandomNumber] ? (
                          <div className="bnr_btnOuter">
                            {bannerLists[indraRandomNumber].linktext1 == null ||
                            bannerLists[indraRandomNumber].linktext1 ==
                              "" ? null : (
                              <a
                                href={
                                  bannerLists[indraRandomNumber].link1 ==
                                    null ||
                                  bannerLists[indraRandomNumber].link1 == ""
                                    ? "#"
                                    : bannerLists[indraRandomNumber].link1
                                }
                                target="_blank"
                                className="basic_btn ylw_btn"
                              >
                                {bannerLists[indraRandomNumber].linktext1}
                              </a>
                            )}

                            {bannerLists[indraRandomNumber].linktext2 == null ||
                            bannerLists[indraRandomNumber].linktext2 ==
                              "" ? null : (
                              <a
                                href={
                                  bannerLists[indraRandomNumber].link2 ==
                                    null ||
                                  bannerLists[indraRandomNumber].link2 == ""
                                    ? "#"
                                    : bannerLists[indraRandomNumber].link2
                                }
                                target="_blank"
                                className="basic_btn ylw_btn"
                              >
                                {bannerLists[indraRandomNumber].linktext2}
                              </a>
                            )}

                            {/* <Link
                            to="#"
                            className="basic_btn ylw_btn"
                            onClick={() => {
                              document.body.classList.add("contact_open");
                            }}
                          >
                            {t("Contact us")}
                          </Link> */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* --- first silde end ---- */}

                {/* --- second silde start ---- */}
                {bannerLists.map((bannerItem, index) => {
                  return (
                    <div
                      key={index}
                      className="homeslide_item carousel-item"
                      style={{
                        backgroundImage: `url(${
                          bannerItem.slidebanner === ""
                            ? homeBanner1
                            : url.API_SERVER_URL + bannerItem.slidebanner
                        })`,
                      }}
                      data-bs-interval="2000"
                    >
                      <div className="bnr_text_content">
                        <div className="container">
                          <div className="bnr_graph_textPnl">
                            <h2>
                              {bannerItem ? <>{bannerItem.title}</> : null}
                            </h2>
                            <h3>
                              {bannerItem ? (
                                <>{bannerItem.titlesecondary}</>
                              ) : null}
                            </h3>
                            {bannerItem ? <p>{bannerItem.subtitle}</p> : null}
                            <div className="bnr_btnOuter">
                              {bannerItem.linktext1 == null ||
                              bannerItem.linktext1 == "" ? null : (
                                <a
                                  href={bannerItem.link1}
                                  className="basic_btn ylw_btn"
                                  target="_blank"
                                >
                                  {bannerItem.linktext1}
                                </a>
                              )}

                              {bannerItem.linktext2 == null ||
                              bannerItem.linktext2 == "" ? null : (
                                <a
                                  href={bannerItem.link2}
                                  className="basic_btn ylw_btn"
                                  target="_blank"
                                >
                                  {bannerItem.linktext2}
                                </a>
                              )}

                              {/* <Link
                              to="#"
                              className="basic_btn ylw_btn"
                              onClick={() => {
                                document.body.classList.add("contact_open");
                              }}
                            >
                              {t("Contact us")}
                            </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* --- second silde end ---- */}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Banner;
