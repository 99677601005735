import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// import custom components
import Header from "common/Header";
import Footer from "common/Footer";
import FaqSearch from "components/FaqComponents/FaqSearch/FaqSearch";
import FaqSearchResult from "components/FaqComponents/FaqSearchResult/FaqSearchResult";

const FaqList = () => {
  // props states for FaqSearch and FaqSearchResult
  const [searchQuery, setSearchQuery] = useState("");
  const [resetQuery, setResetQuery] = useState(false);

  useEffect(() => {
    document.title = "FAQ";
  }, []);

  return (
    <>
      {/*----header ----*/}
      <Header />

      {/* ------ body ---- */}
      <FaqSearch
        setSearchQuery={setSearchQuery}
        setResetQuery={setResetQuery}
      />

      {/* ---- faq search result ---- */}
      <FaqSearchResult
        searchQuery={searchQuery}
        resetQuery={resetQuery}
        setResetQuery={setResetQuery}
      />

      {/* ---- footer ---- */}
      <Footer />
    </>
  );
};

export default FaqList;
