/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

// importing custom component here
import EmptyMessage from "../Others/EmptyMessage";
import ForwardModal from "../Modals/ForwardModal";

// importing images here
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import PDF from "assets/ConversationAssests/svg/pdf.svg";
import XLS from "assets/ConversationAssests/svg/xls.svg";
import DOCS from "assets/ConversationAssests/svg/docs.svg";
import VIDEO from "assets/ConversationAssests/svg/video.svg";
import ZIP from "assets/ConversationAssests/svg/zip.svg";

// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const GroupChatBoard = (props) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const { setShowToastGroupWarning } = useContext(GlobalIndraInfo);

  // getting token here
  const token = localStorage.getItem("token");

  const [sender, setSender] = useState("");
  const [sendForwardedMessage, setSendForwardedMessage] = useState("");
  const [forwardedMessageID, setForwardedMessageID] = useState(null);

  // assign useref hook for auto scrolling
  const messagesEndRef = useRef(null);

  // function for scroll chat bottom behavior
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  // initial state for contact chat section
  const [chatDetails, setChatDetails] = useState(null);
  const [lastChatId, setLastChatId] = useState(null);
  const [toggleReply, setToggleReply] = useState(false);
  const [groupReplyChat, setGroupReplyChat] = useState({});

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const sendLikeHandler = async (id) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_GROUP_CHAT_LIKE +
      `?token=${token}` +
      `&chatid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        let blockId = "likecount-" + response.chatid;
        document.getElementById(blockId).textContent = response.count;
      }
    } catch (error) {}
  };

  /*---- function for reply group chat -----*/
  const replyHandler = (item, index) => {
    setToggleReply(true);
    if (item) {
      setSender(item.sender);
      setSendForwardedMessage(item.message);
      setGroupReplyChat(item);
    } else {
      setSender("");
      setSendForwardedMessage("");
      setGroupReplyChat({});
    }
  };
  /*---- end of function for reply group chat -----*/

  // function for passing props in tag
  const tagChat = (item) => {
    props.getTag(item.id);
  };

  // function for open file Link
  const openFile = (path) => {
    window.open(path, "_blank");
  };

  // function for handler champion
  const championHandler = async (item) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_GROUP_CHAMPION +
      `?token=${token}` +
      `&chatid=${item.id}`;

    if (props.chatInfo.role == "Moderator") {
      try {
        const response = await getData(requestURL);

        if (response.status) {
          props.refreshGroupChat();
        }
      } catch (error) {}
    } else {
      setShowToastGroupWarning(true);
    }
  };

  // use effect for toggle reply box and send message to chatbox
  useEffect(() => {
    if (toggleReply) {
      props.groupReplyChat(groupReplyChat);
    } else {
      props.groupReplyChat({});
      setGroupReplyChat({});
    }
  }, [toggleReply]);

  // useeffect for hide reply box
  useEffect(() => {
    if (props.hideReplyBox) {
      setToggleReply(false);
    }
  }, [props.hideReplyBox]);

  /*--------- useEffect for get all chat details -----------*/
  useEffect(() => {
    if (props.chatDetails != null) {
      // if not empty then assign to the useState
      setChatDetails(props.chatDetails);

      if (props.lastChatId != lastChatId) {
        setLastChatId(props.lastChatId);
      } else {
        setLastChatId(null);
      }
    } else {
      setChatDetails(null);
    }
  }, [props.chatDetails]);

  /*----- useeffect for scroll to the bottom updating the chats ------*/
  useEffect(() => {
    scrollToBottom();
  }, [chatDetails]);

  return (
    <>
      {/* -------------- Group Middle Panel content starts here -------------- */}
      {props.chatIsLoading == true ? (
        <div className="contact_chat_section">
          <div className="chating_mdlPnl">
            <div className="chatting_innr pd_top_60">
              <div className="placeholder_row placeholder-glow">
                <div className="user_placeHoledr">
                  <div className="default_usr">
                    <img src={defaultUser} alt="" />
                  </div>
                  <div className="default_usr_name">
                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  </div>
                </div>
                <p className="card-text placeholder-glow">
                  <span className="placeholder placeholder-sm col-12 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-9 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-8 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-7 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="chating_mdlPnl grp_chat_Innr">
          <div className="chatting_innr">
            {chatDetails == null ||
            chatDetails == undefined ||
            chatDetails.length == 0 ? (
              <EmptyMessage />
            ) : (
              <div className="msg_outer">
                {/* ----- mapping section of all group chats----- */}
                {chatDetails.map((item, index) => {
                  return (
                    <div key={index} className="group_chat_section">
                      {item.uid == userInfo.id ? (
                        <div
                          className="msg_row own_msgRow grp_ownmsg"
                          chatid={item.id + "-" + index}
                        >
                          <div className="chat_msgright">
                            <div className="chat_info_timeOuter">
                              <h5>
                                {item.time}
                                <span className="msg_status double_tick seen_tick">
                                  <i className="material-icons-round">
                                    done_all
                                  </i>
                                </span>
                              </h5>
                            </div>
                            {item.parent ? (
                              <div className="own_msgArea">
                                <div className="replied_msg_row">
                                  <span>{item.parent.sender}</span>
                                  <p>{item.parent.message}</p>
                                </div>
                                <div className="replied_msg_text">
                                  <p>{item.message}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="own_msgArea">
                                <p>{item.message}</p>
                              </div>
                            )}

                            {item.media.length == 0 ||
                            item.media == null ||
                            item.media == undefined ? null : (
                              <div className="clearfix">
                                <div className="upload_picOuter">
                                  {item.media.map((path, index2) => {
                                    return (
                                      <div
                                        className="upload_pic_item"
                                        key={index2}
                                      >
                                        <figure
                                          className="upload_viewimg"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => openFile(path)}
                                        >
                                          {path.split(".").pop() == "jpg" ||
                                          path.split(".").pop() == "JPG" ||
                                          path.split(".").pop() == "png" ? (
                                            <img src={path} alt="images" />
                                          ) : (
                                            <div className="pdf-section">
                                              {path.split(".").pop() ==
                                              "pdf" ? (
                                                <img src={PDF} alt="docs" />
                                              ) : (
                                                <div className="xls-section">
                                                  {path.split(".").pop() ==
                                                    "xlsx" ||
                                                  path.split(".").pop() ==
                                                    "xls" ? (
                                                    <img src={XLS} alt="docs" />
                                                  ) : (
                                                    <>
                                                      {path.split(".").pop() ==
                                                        "docx" ||
                                                      path.split(".").pop() ==
                                                        "txt" ||
                                                      path.split(".").pop() ==
                                                        "doc" ||
                                                      path.split(".").pop() ==
                                                        "odt" ||
                                                      path.split(".").pop() ==
                                                        "dotx" ||
                                                      path.split(".").pop() ==
                                                        "docm" ||
                                                      path.split(".").pop() ==
                                                        "pptx" ? (
                                                        <img
                                                          src={DOCS}
                                                          alt="docs"
                                                        />
                                                      ) : (
                                                        <>
                                                          {path
                                                            .split(".")
                                                            .pop() == "mp4" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "mkv" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "wmv" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "mov" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "3gp" ? (
                                                            <img
                                                              src={VIDEO}
                                                              alt="image"
                                                            />
                                                          ) : (
                                                            <>
                                                              {path
                                                                .split(".")
                                                                .pop() ==
                                                                "rar" ||
                                                              path
                                                                .split(".")
                                                                .pop() ==
                                                                "zip" ? (
                                                                <img
                                                                  src={ZIP}
                                                                  alt="image"
                                                                />
                                                              ) : (
                                                                <img
                                                                  src={path}
                                                                  alt="image"
                                                                />
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </figure>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="msg_row group_usrmsg_row"
                          chatid={item.id + "-" + index}
                        >
                          <div className="chat_infoLft">
                            <div className="msg_usr">
                              <img src={item.image} alt="sender" />
                            </div>
                          </div>
                          <div className="chat_msgright">
                            {item.tags == null ? null : (
                              <div className="tag_msg">
                                <Link to="#">
                                  <i className="material-icons">label</i>
                                  {item.tags.map((item2, index2) => {
                                    return (
                                      <span key={index2}>{item2.label},</span>
                                    );
                                  })}
                                </Link>
                              </div>
                            )}
                            <div className="grp_usrname_time">
                              <h5>
                                <span className="grp_usr_prsn">
                                  {item.sender} :
                                </span>
                                <span className="grp_usrTime">{item.time}</span>
                              </h5>
                            </div>
                            {item.parent ? (
                              <div className="msg_text">
                                <div className="replied_msg_row_left">
                                  <span>{item.parent.sender}</span>
                                  <p>{item.parent.message}</p>
                                </div>
                                <div className="replied_msg_text_left">
                                  <p>{item.message}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="msg_text">
                                <p>{item.message}</p>
                              </div>
                            )}

                            <div className="msg_shareicon_info">
                              <ul>
                                <li className="active">
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      sendLikeHandler(item.id);
                                    }}
                                  >
                                    <i className="material-icons-round">
                                      thumb_up
                                    </i>
                                    <span id={"likecount-" + item.id}>
                                      {item.likes}
                                    </span>
                                  </Link>
                                </li>
                                <li
                                  className={
                                    item.winner == "0" || item.winner == null
                                      ? ""
                                      : "active"
                                  }
                                >
                                  <Link
                                    to="#"
                                    role="button"
                                    onClick={() => {
                                      championHandler(item);
                                    }}
                                  >
                                    <i className="material-icons-round">
                                      emoji_events
                                    </i>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      replyHandler(item, index);
                                    }}
                                  >
                                    <i className="material-icons-round">
                                      reply
                                    </i>
                                    {t("Reply")}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#forward_mdl"
                                    onClick={() =>
                                      setForwardedMessageID(item.id)
                                    }
                                  >
                                    <i className="material-icons-round">
                                      fast_forward
                                    </i>
                                    {t("Forward")}
                                  </Link>
                                </li>

                                {/* -------- Calling Add Tag Modal here -------- */}
                                <li>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addtag_mdl"
                                    role="button"
                                    onClick={() => tagChat(item)}
                                  >
                                    <i className="material-icons-round">
                                      loupe
                                    </i>
                                    {t("Labels")}
                                  </Link>
                                </li>

                                {/* <li className="dropdown">
                                <Link to="#" data-bs-toggle="dropdown">
                                  <i className="material-icons-round">
                                    expand_circle_down
                                  </i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link to="#">
                                      <i className="material-icons-round">
                                        delete_outline
                                      </i>
                                      <span>Delete</span>
                                    </Link>
                                  </li>
                                </ul>
                              </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      {/*----- receiver side media files area -----*/}
                      {item.media.length == 0 ||
                      item.media == null ||
                      item.media == undefined ||
                      item.uid == userInfo.id ? null : (
                        <div className="upload_picOuter">
                          {item.media.map((path, index2) => (
                            <div className="upload_pic_item" key={index2}>
                              <figure
                                className="upload_viewimg"
                                style={{ cursor: "pointer" }}
                                onClick={() => window.open(path, "_blank")}
                              >
                                {path.split(".").pop() == "jpg" ||
                                path.split(".").pop() == "JPG" ||
                                path.split(".").pop() == "png" ? (
                                  <img src={path} alt="images" />
                                ) : (
                                  <div className="pdf-section">
                                    {path.split(".").pop() == "pdf" ? (
                                      <img src={PDF} alt="docs" />
                                    ) : (
                                      <div className="xls-section">
                                        {path.split(".").pop() == "xlsx" ||
                                        path.split(".").pop() == "xls" ? (
                                          <img src={XLS} alt="sheet" />
                                        ) : (
                                          <>
                                            {path.split(".").pop() == "docx" ||
                                            path.split(".").pop() == "txt" ||
                                            path.split(".").pop() == "doc" ||
                                            path.split(".").pop() == "odt" ||
                                            path.split(".").pop() == "dotx" ||
                                            path.split(".").pop() == "docm" ||
                                            path.split(".").pop() == "pptx" ? (
                                              <img src={DOCS} alt="docs" />
                                            ) : (
                                              <>
                                                {path.split(".").pop() ==
                                                  "mp4" ||
                                                path.split(".").pop() ==
                                                  "mkv" ||
                                                path.split(".").pop() ==
                                                  "wmv" ||
                                                path.split(".").pop() ==
                                                  "mov" ||
                                                path.split(".").pop() ==
                                                  "3gp" ? (
                                                  <img
                                                    src={VIDEO}
                                                    alt="image"
                                                  />
                                                ) : (
                                                  <>
                                                    {path.split(".").pop() ==
                                                      "rar" ||
                                                    path.split(".").pop() ==
                                                      "zip" ? (
                                                      <img
                                                        src={ZIP}
                                                        alt="image"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={path}
                                                        alt="image"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </figure>
                            </div>
                          ))}
                        </div>
                      )}
                      {/*----- end of receiver side media files area -----*/}
                    </div>
                  );
                })}
                {/* ----- end of mapping section of all group chats----- */}
                {/* ---- calling useref hook for scroll behaviour ----- */}
                <div ref={messagesEndRef} name="scrollRefDiv" />
              </div>
            )}
          </div>

          {toggleReply && (
            <div className="reply_popup_area" id="replypopup">
              <div className="reply_popupmsg">
                <h5>{sender}</h5>
                <p>{sendForwardedMessage}</p>
              </div>
              <div className="reply_cross">
                <Link
                  to="#"
                  onClick={() => {
                    replyHandler();
                    setToggleReply(false);
                  }}
                >
                  <i className="material-icons-round">close</i>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      {/* -------------- Group Middle Panel content starts here -------------- */}
      {/* Calling the Forward Modal here */}
      <ForwardModal messageId={forwardedMessageID} />
    </>
  );
};

export default GroupChatBoard;
