/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

import blankVideo from "assets/CourseDetailsAssests/images/course-vdo-right-blank.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const About = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [benefit, setBenefit] = useState("");
  const [skills, setSkills] = useState([]);
  const [about, setAbout] = useState("");
  const [requisit, setRequisit] = useState("");
  const [promotionalText, setPromotionalText] = useState("");
  const [stories, setStories] = useState([]);
  const [promoVideoURL, setpromoVideoURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // function for get course about
  const getCourseAbout = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_VIEW_COURSE_DETAILS +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.coursebenefits) {
            setBenefit(response.data.coursebenefits);
          } else {
            setBenefit("");
          }
          setSkills(response.data.skills);
          setAbout(response.data.about);
          if (response.data.courserequisite) {
            setRequisit(response.data.courserequisite);
          } else {
            setRequisit("");
          }

          if (response.data.promotionalcontent) {
            setPromotionalText(response.data.promotionalcontent);
          } else {
            setPromotionalText("");
          }
          setStories(response.data.stories);
          if (response.data.promotionalvideo == null) {
            setpromoVideoURL("");
          } else {
            setpromoVideoURL(response.data.promotionalvideo);
          }
        } else {
          setBenefit([]);
          setSkills([]);
          setAbout("");
          setRequisit([]);
          setPromotionalText("");
          setStories([]);
          if (response.data.promotionalvideo) {
            setpromoVideoURL(response.data.promotionalvideo);
          } else {
            setpromoVideoURL("");
          }
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCourseAbout(params.id);
  }, [params.id]);

  // return from here
  return (
    <>
      {/* --------------- Course (About) Body starts here --------------- */}
      <div className="tab-pane show active fade" id="course_about">
        {isLoading ? (
          <div className="training_outer">
            <div className="row">
              <div className="col-lg-8">
                <div className="exprt_overviewOuter">
                  <div className="course_detailspnl">
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                  </div>
                  <div className="course_detailspnl">
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                    </p>
                  </div>
                  <div className="course_detailspnl">
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-4 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-12 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-11 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-9 rounded-3 mb-10"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-12 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-11 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-9 rounded-3 mb-10"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="traning_pnlOuter">
                  <div className="course_rightPnl placeholder-glow">
                    <div className="course_vdopnl">
                      <figure>
                        <img src={blankVideo} alt="video" />
                      </figure>
                    </div>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="training_outer">
            {benefit == "" &&
            skills.length == 0 &&
            about == "" &&
            stories.length == 0 &&
            requisit == "" &&
            promoVideoURL == "" &&
            promotionalText == "" ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("Details are not available at this moment.")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                {/* ---------- Left side component starts here ---------- */}
                <div className="col-lg-8">
                  <div className="exprt_overviewOuter">
                    {/* --------- Skills section starts here --------- */}
                    {skills.length > 0 ? (
                      <div className="course_detailspnl">
                        <div className="contnt_hdng">
                          <h4>{t("Skills you will gain")}</h4>
                        </div>
                        <div className="experts_skill">
                          <ul>
                            {skills.map((skill, index) => {
                              return (
                                <li key={index}>
                                  <Link to="#">{skill.label}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                    {/* --------- Skills section ends here --------- */}

                    {/* --------- About Course section starts here --------- */}
                    {about != "" ? (
                      <div className="course_detailspnl">
                        <div className="contnt_hdng">
                          <h4>{t("About this course")}</h4>
                        </div>
                        <div className="results_para">
                          <p dangerouslySetInnerHTML={{ __html: about }}></p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* --------- About Course section ends here --------- */}

                    {/* --------- Features section starts here --------- */}
                    {benefit == "" ? null : (
                      <div className="course_detailspnl">
                        <div className="contnt_hdng">
                          <h4>{t("What you'll learn?")}</h4>
                        </div>
                        <div className="training_ticklisting">
                          <p dangerouslySetInnerHTML={{ __html: benefit }}></p>

                          {/* <p>
                            <i className="material-icons-outlined">
                              check_circle
                            </i>
                            <span>Some</span>
                          </p> */}
                        </div>
                      </div>
                    )}
                    {/* --------- Features section ends here --------- */}

                    {/* --------- Stories section starts here --------- */}

                    {/* <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("Stories")}</h4>
                      </div>
                      <div className="course_vdopnlOuter">
                        {stories.map((story, index) => {
                          return (
                            <div className="course_vdo_row" key={story.id}>
                              <div className="course_vdopnl">
                                <div className="embed-responsive embed-responsive-16by9 course_vdoBx">
                                  <video
                                    className="embed-responsive-item"
                                    style={{ borderRadius: "5px" }}
                                    src={story.video != null ? story.video : ""}
                                    id="samplepreview"
                                    controls
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                    fullscreen="true"
                                  ></video>
                                </div>
                              </div>

                              <div className="vdo_textpnl">
                                <div className="time_clock">
                                  <i className="material-icons-round">
                                    schedule{" "}
                                  </i>
                                  <span>3.40</span>
                                </div>

                                <div className="vdo_hdng">
                                  <h3>{story.title}</h3>
                                </div>

                                <div className="vdo_para">
                                  <p>{story.article}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div> */}

                    {/* --------- Stories section ends here --------- */}

                    {/* --------- Requirements section starts here --------- */}
                    {requisit == "" ? null : (
                      <div className="course_detailspnl">
                        <div className="contnt_hdng">
                          <h4>{t("Requirements / Pre-Requisites")}</h4>
                        </div>
                        <div className="training_ticklisting">
                          <p dangerouslySetInnerHTML={{ __html: requisit }}></p>
                          {/* <p>
                                <i className="material-icons-outlined">
                                  check_circle
                                </i>
                                <span>Label</span>
                              </p> */}
                        </div>
                      </div>
                    )}
                    {/* --------- Requirements section ends here --------- */}
                  </div>
                </div>
                {/* ---------- Left side component ends here ---------- */}

                {/* ---------- Right side component starts here ---------- */}
                <div className="col-lg-4">
                  <div className="traning_pnlOuter">
                    {promoVideoURL == "" && promotionalText == "" ? null : (
                      <div className="course_rightPnl">
                        {/* ------- Promotional Video Section starts here ------- */}

                        {promoVideoURL == "" ? null : (
                          <div className="course_vdopnl">
                            <div className="embed-responsive embed-responsive-16by9 course_vdoBx">
                              <video
                                className="embed-responsive-item"
                                style={{ borderRadius: "5px" }}
                                src={promoVideoURL}
                                id="samplepreview"
                                controls
                                allowFullScreen
                                width="100%"
                                height="100%"
                                fullscreen="true"
                              ></video>
                            </div>
                          </div>
                        )}

                        {/* ------- Promotional Video Section ends here ------- */}

                        {/* ------- Promotional Text Points Section starts here ------- */}
                        <div className="course_puterpnl">
                          {promotionalText == "" ? null : (
                            <div className="course_row">
                              <div className="course_icon">
                                <i className="material-icons-outlined">
                                  fact_check
                                </i>
                              </div>
                              <div className="course_text">
                                {/* <h3>Some</h3> */}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: promotionalText,
                                  }}
                                ></p>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* ------- Promotional Text Points Section ends here ------- */}
                      </div>
                    )}
                  </div>
                </div>
                {/* ---------- Right side component ends here ---------- */}
              </div>
            )}
          </div>
        )}
      </div>
      {/* --------------- Course (About) Body ends here --------------- */}
    </>
  );
};

export default About;
