/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/HomeAssets/Images/default-user.png";
import blankImage from "assets/BlogAssets/images/blog-2.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const HomeBlogLists = () => {
  const { t } = useTranslation();
  const [blogLists, setBlogLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // for react multi carousel resposive properties
  const blogResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // array for counting glow rows
  const glowCount = [1, 2, 3, 4];

  //array for summary lines
  const blogSummaryLines = [1, 2, 3, 4, 5];

  const dummyBlogData = [
    {
      author: null,
      category: [],
      created: null,
      createdtime: null,
      id: 1,
      image: null,
      slug: "",
      summary: "",
      title: "",
      hideButton: true,
    },
    {
      author: null,
      category: [],
      created: null,
      createdtime: null,
      id: 2,
      image: null,
      slug: "",
      summary: "",
      title: "",
      hideButton: true,
    },
    {
      author: null,
      category: [],
      created: null,
      createdtime: null,
      id: 3,
      image: null,
      slug: "",
      summary: "",
      title: "",
      hideButton: true,
    },
    {
      author: null,
      category: [],
      created: null,
      createdtime: null,
      id: 4,
      image: null,
      slug: "",
      summary: "",
      title: "",
      hideButton: true,
    },
  ];

  // function for get all blog list
  const getBlogLists = async () => {
    try {
      setIsLoading(true);

      let requestURL;

      requestURL =
        url.API_BASE_URL +
        url.API_BLOG_LISTS +
        `?modulefrom=frontend` +
        `&start=${0}&limit=10`;

      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        if (response.data.length <= 3) {
          setBlogLists([...response.data, ...dummyBlogData]);
        } else {
          setBlogLists(response.data.slice(0, 10));
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getBlogLists();
  }, []);

  return (
    <section className="events_area pd_30">
      <div className="container">
        {/* ---- title and see all button start ----- */}
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>{t("Best Information")}</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="/blogs" className="border_btn ylwbrdr_btn">
              {t("See All")}
            </Link>
          </div>
        </div>
        {/* ---- title and see all button end ----- */}

        {/* ----- blog list area start ----- */}
        {isLoading ? (
          <div className="slide_Outer">
            {/* ----- glow placeholder start ----- */}
            <div className="events_slider slider_innr">
              <Carousel ssr responsive={blogResponsive}>
                {glowCount.map((count) => {
                  return (
                    <div className="slide_Item mb-4" key={count}>
                      <div className="events_itemBx">
                        <div className="champions_pic">
                          <span
                            className="placeholder col-12"
                            style={{ height: "197px" }}
                          ></span>
                        </div>
                        <div className="champions_textpnl">
                          <div className="events_hdngauthor">
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                          </div>
                          <div className="leftchampion_text">
                            <span
                              className="placeholder rounded-2 col-6 mt-2"
                              style={{ height: "20px" }}
                            ></span>
                          </div>
                          <div className="experts_skill ">
                            <ul>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                          <div className="details_btn">
                            <span
                              className="placeholder rounded-3 col-12"
                              style={{ height: "50px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            {/* ----- glow placeholder end ----- */}
          </div>
        ) : (
          <div className="slide_Outer">
            {blogLists.length > 0 && (
              <div className="events_slider slider_innr">
                <Carousel ssr showDots={false} responsive={blogResponsive}>
                  {blogLists.slice(0, 3).map((blog, index) => {
                    return (
                      <div className="slide_Item mb-4" key={index}>
                        <div className="events_itemBx">
                          {/* ----- blog image area start ---- */}
                          {blog.hideButton ? (
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                          ) : (
                            <div className="champions_pic">
                              <img
                                src={
                                  blog.bannerimage != ""
                                    ? url.API_SERVER_URL + blog.bannerimage
                                    : blankImage
                                }
                                alt=""
                                className="w-100"
                              />
                            </div>
                          )}
                          {/* ----- blog image area end ---- */}

                          <div className="champions_textpnl d-flex flex-wrap">
                            {/* ----- blog title area start ---- */}
                            {blog.hideButton ? (
                              <div className="leftchampion_text w-100">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                            ) : (
                              <div className="leftchampion_text">
                                <h3>
                                  {blog.name !== ""
                                    ? blog.name.slice(0, 50)
                                    : "Undisclosed"}
                                </h3>
                              </div>
                            )}
                            {/* ----- blog title area end ---- */}

                            {/* ----- blog category area start ---- */}
                            {blog.hideButton ? (
                              <div className="experts_skill w-100">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div className="experts_skill w-100">
                                {blog.categorylist.length == 0 ? null : (
                                  <ul>
                                    {blog.categorylist.map(
                                      (category, index) => (
                                        <li key={index}>
                                          <Link to="#">
                                            <span>{category.name}</span>
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </div>
                            )}
                            {/* ----- blog category area end ---- */}

                            {/* ----- blog summary area start ---- */}
                            {blog.hideButton ? (
                              <div className="leftchampion_text w-100">
                                {blogSummaryLines.map((count) => {
                                  return (
                                    <span
                                      key={count}
                                      className="placeholder rounded-2 col-12 mt-2"
                                      style={{ height: "20px" }}
                                    ></span>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="activity_para w-100">
                                {blog.summary === ""
                                  ? null
                                  : blog.summary.slice(0, 150)}
                              </div>
                            )}
                            {/* ----- blog summary area end ---- */}

                            {/* ----- blog owner area start ---- */}
                            {blog.hideButton ? null : (
                              <div className="activity_usrPnl w-100">
                                {/* --------- Activity Moderator Image Section --------- */}
                                <div className="user_activty">
                                  <img
                                    src={
                                      blog.authorimage !== ""
                                        ? url.API_SERVER_URL + blog.authorimage
                                        : defaultUser
                                    }
                                    alt="moderatorPic"
                                    className=""
                                  />
                                </div>

                                {/* --------- Activity Moderator Name Section --------- */}
                                <div className="my-4">
                                  <div className="activty_usrname">
                                    <h4>{blog.authorname}</h4>
                                    <h5>{blog.blogdate}</h5>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* ----- blog owner area end ---- */}

                            {/* ----- blog details button area start ---- */}
                            {blog.hideButton ? (
                              <div className="details_btn w-100">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            ) : (
                              <div className="details_btn w-100">
                                <Link
                                  to={`/blog/${blog.slug}`}
                                  className="basic_btn ylw_btn"
                                >
                                  {t("See Details")}
                                </Link>
                              </div>
                            )}
                            {/* ----- blog details button area end ---- */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>
        )}
        {/* ----- blog list area end ----- */}
      </div>
    </section>
  );
};

export default HomeBlogLists;
