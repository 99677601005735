/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, putData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ------------------------------------------------------------------------*/

const AddTagsModal = (props) => {
  // getting logged in user's token here
  const token = localStorage.getItem("token");
  const params = useParams();
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [tags, setTags] = useState([]);
  // Initial useState to store the entered tags name
  const [newTag, setNewTag] = useState("");

  //initial useState to hold all the selected users
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTagWarining, setNewTagWarining] = useState(false);
  const [tagWarning, setTagWarning] = useState(false);
  const [isAddTagLoading, setIsAddTagLoading] = useState(false);
  const [isRemoveTagLoading, setIsRemoveTagLoading] = useState(false);

  // function for get chat details from API
  const getTagsData = async () => {
    let requestURL =
      url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

    console.log(requestURL);

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTags(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add new tag for tag contact/group
  const addNewTagHandler = async (event) => {
    event.preventDefault();

    if (newTag == "") {
      setNewTagWarining(true);
    } else {
      let tagData = {
        title: newTag,
      };
      setNewTagWarining(false);
      let requestURL =
        url.API_BASE_URL + url.API_CREATE_NEW_TAG + `?token=${token}`;

      try {
        const response = await postData(requestURL, tagData);

        if (response.status) {
          // call the get function again to update the dom
          getTagsData();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    // resetting the useState to clear the text field.
    resetTagFieldHandler();
  };

  // reset all tag fields
  const resetTagFieldHandler = () => {
    setNewTag("");
    setNewTagWarining(false);
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = [...selectedTags];

    const tagExists = prevTags.some(
      (prevTag) => prevTag.value.toString() === data.value.toString()
    );

    if (tagExists) {
      let selectedTagItem = document.getElementById(
        "tagselected-" + data.value
      );
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data._id);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "tagselected-" + data.value
      );
      selectedTagItem.classList.add("active");

      const dataExtended = {
        label: data.label,
        value: data.value,
        __isNew__: false,
      };
      // not exist in array, thus add
      prevTags.push(dataExtended);
    }

    setSelectedTags(prevTags);
  };

  // function for add tags to chat/contact/group
  const saveTagsHandler = async (actionValue = "") => {
    if (selectedTags.length !== 0) {
      try {
        let tagData = {
          selectedids: [params.id],
          selectedtags: selectedTags,
        };

        console.log(tagData);

        setIsAddTagLoading(true);

        setTagWarning(false);

        let requestURL =
          url.API_BASE_URL +
          url.COURSE_ADD_TAG +
          `?token=${token}` +
          `&action=${actionValue}`;

        console.log(requestURL);

        const response = await putData(requestURL, tagData);

        console.log(response);

        if (response.status) {
          props.afterModalClose();
        }

        let addTagModal = document.querySelector("#addtag_course_mdl");
        let modal = bootstrap.Modal.getInstance(addTagModal);
        modal.hide();
        closeModalHandler();
        setIsAddTagLoading(false);
      } catch (error) {}
    } else {
      setTagWarning(true);
    }
  };

  // function for reset all data after close modal
  const closeModalHandler = () => {
    resetTagFieldHandler();
    setSelectedTags([]);
    resetSelectTags();
    setTagWarning(false);
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("displayTags");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  useEffect(() => {
    // API call to get all the Tag data here
    getTagsData();
  }, []);

  return (
    <div className="chat_modalbx share_modal_bx tag_modalBx">
      <div
        className="modal fade show"
        id="addtag_course_mdl"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">{t("Add Tag")}</h5>
              <button
                type="button"
                className="close ylw_closeBtn bg-warning"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="group_srchfill add_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter to create new label")}
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                />
                <Link
                  to="#"
                  className="add_icon"
                  onClick={(e) => addNewTagHandler(e)}
                >
                  <span className="material-icons-round">add_circle </span>
                </Link>
              </div>
              {tags.length > 0 && (
                <div className="group_innrScroll">
                  <div className="tag_links">
                    <ul>
                      {tags.map((data, index) => (
                        <li key={index}>
                          <Link
                            to="#"
                            className="displayTags"
                            onClick={() => tagselectedHandler(data)}
                            id={"tagselected-" + data.value}
                          >
                            {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {/* -------------------- Button section starts here -------------------- */}
              <div className="group_btnOuter">
                {tagWarning && (
                  <p className="text-danger mb-2">
                    * {t("Select atleast one tag")}
                  </p>
                )}
                <ul>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="ornage_btn"
                      role="button"
                      onClick={() => {
                        saveTagsHandler("1");
                      }}
                    >
                      {isAddTagLoading ? (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="material-icons-round"> check_circle </i>
                      )}
                      <span>{t("Add")}</span>
                    </Link>
                  </li>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="red_btn"
                      role="button"
                      onClick={() => {
                        saveTagsHandler("2");
                      }}
                    >
                      {isAddTagLoading ? (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="material-icons-round"> delete </i>
                      )}
                      <span>{t("Delete")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* -------------------- Button section ends here -------------------- */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagsModal;
