// ==================== NODE API URLS ====================
export const API_BASE_URL = "https://api.v2.habitatm.es/api"; //Base url
// export const API_BASE_URL = "http://localhost:4008/api"; //Base url

// ==================== Backend Url or Admin Panel Url ====================
export const API_BACKEND_URL = "https://admin.v2.habitatm.es"; // Backend url
// export const API_BACKEND_URL = "http://localhost:4800"; // Backend url

export const API_SERVER_URL = "https://api.v2.habitatm.es/";
// export const API_SERVER_URL = "http://localhost:4008/";

/*=========== all Demo Data API URL start here ===========*/
export const DEMO_DATA = "/demo/demoget"; // for get demo data
export const DEMO_POST = "/demo/demopost"; // for testing post data
/*=========== all Demo Data API URL end here ===========*/

export const API_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_FILE_UPLOAD_MULTIPLE = "/upload/multiupload"; //for multiple file upload

/*---- general URL end here -----*/

//get all module access
export const API_GET_ALL_MODULE_ACCESS = "/moduleroleprevilege/getmoduleaccess";

// category url start

//get all Categories
export const API_GET_CATEGORIES = "/category/getcategories";

// category url end

//note url start
//add note
export const API_ADD_NEW_NOTE = "/note/createnote";

//get all note
export const API_GET_ALL_NOTE = "/note/getallnotes";

//get note details
export const API_GET_NOTE_DETAILS = "/note/getnotedetails";

//update note
export const API_UPDATE_NOTE = "/note/updatenote";

//delete note
export const API_DELETE_NOTE = "/note/deletenote";
//note url end

// API for all banner lists
export const API_BANNER_LISTS = "/slide/getallslides";

//Api for get blog category
export const API_BLOG_CATEGORY = "/blog/category/list";

//Api for all blog lists
export const API_BLOG_LISTS = "/blog/getallblogs";

//API for blog details
export const API_BLOG_DETAILS = "/blog/getblogdetailsbypost";

// API registration
export const API_REGISTARTION = "/auth/register";

// API forgot password
export const API_FORGOT_PASSWORD = "/auth/forgot/password";

// API login url
export const API_LoginUrl = "/auth/user";

// API validate user
export const API_VALIDATE_USER = "/auth/user/validate";

// url for get logged in user details
export const API_GET_USER_DETAILS = "/userinfo";

// API for contact Us slider
export const API_CONTACT_US = "/contactus/save";

// API for list of all champion skills
export const API_ALL_CHAMPION_SKILLS = "/skills/list";

//API for group categories
export const API_ALL_GROUP_CATEGORIES = "/group/categories";

//review url
//submit review
export const API_SUBMIT_REVIEW = "/review/givereview";

//get all reviews
export const API_GET_ALL_REVIEWS = "/review/getallreviews";

/*----- api for activity section --------*/
// API list all the posts
export const API_ACTIVITIES_LIST = "/activityforum/getallforum";

// API display post details
export const API_ACTIVITY_DETAILS = "/activityforum/forumdetails";

// API add top response (winner)
export const API_ACTIVITY_TOP_RESPONSE_WINNER = "/activity/topresponse/";

// API create a new post
export const API_ACTIVITY_CREATE_POST = "/activityforum/createforum";

// API like the post
export const API_ACTIVITY_LIKE_POST = "/activityforum/togglelikepost";

// API like the post comments and answers
export const API_ACTIVITY_LIKE_POST_COMMENT =
  "/activityforum/togglelikeoncomment";

// API answer the question in post
export const API_ACTIVITY_POST_ANSWER = "/activityforum/addcomment";

// API to reply to answer
export const API_ACTIVITY_REPLY_TO_ANSWER = "/activityforum/addcomment";

//update forum
export const API_UPDATE_FORUM = "/activityforum/updateforum";

// API to fetch all the contacts in Filter Modal
export const API_ACTIVITY_FILTER_CONTACTS = "/user/contacts";

// API to fetch all the categories in Filter Modal
export const API_ACTIVITY_FILTER_CATEGORIES = "/categories/activity";

// API to fetch all the groups in Filter Modal
export const API_ACTIVITY_FILTER_GROUPS = "/user/groups";

// API to fetch all the events in Filter Modal
export const API_ACTIVITY_FILTER_EVENTS = "/user/events";

// API Tags (to fetch all the tags)
export const API_ACTIVITY_FILTER_TAGS = "/tags/list";

//API FOR DELETE COMMENTS IN RIGHT PANEL
export const API_ACTIVITY_REMOVE_COMMENTS = "/activityforum/updatecomment";

//API FOR DELETE FORUM/ACTIVITY IN RIGHT PANEL
export const API_ACTIVITY_REMOVE_FORUM = "/activityforum/updateforum";

//addremovetagforum
export const API_ACTIVITY_ADD_REMOVE_TAG = "/activityforum/addremovetagforum";

// API for add tag
export const API_ACTIVITY_ADD_TAG = "/activity/addtags";

//API for remove tag
export const API_ACTIVITY_REMOVE_TAG = "/activity/removetags";

// URL to upload Image in Text Editor (AskQuestions.js) component.
export const URL_TEXT_EDITOR_IMAGE_UPLOAD =
  "https://dev.lobees.com/web/uploads/documents/";

export const URL_TEXT_EDITOR_IMAGE_UPLOAD_2 =
  "https://dev.lobees.com/web/uploads/reports/";

/* ------------------------------ APIs For Conversations(Chat) ------------------------------- */

// API update userinfo
export const API_UPDATE_USERINFO = "/user/profile/update";

// API update dropdown
export const API_CATEGORIES_DROPDOWN_OPTIONS = "/categories/activity";

/* ------------------------------ APIs For Course Upload ------------------------------- */

//get task details
export const API_GET_TASK_DETAILS = "/task/gettaskdetails";

//getexistinglessontask
export const API_GET_EXISTING_LESSON_TASK = "/course/getexistinglessontask";

//submitlessontask
export const API_SUBMIT_LESSON_TASK = "/course/submitlessontask";

//get all logs/answers
export const API_GET_COURSE_TASK_ANSWERS = "/course/getallsubmittedtasklogs";

//complete lesson
export const API_COMPLETE_COURSE_LESSON = "/course/completecourselesson";

// API to list all the courses
export const API_COURSE_LIST = "/course/getallcourserecords";

// API to view all details of a course
export const API_VIEW_COURSE = "/course/getcoursedetails";

//getcertificatedetails
export const API_GET_CERTIFICATE_INFORMATION =
  "/course/getcertificateinformation";

// API to get course list of course tab
export const API_COURSE_TRAINING_LIST = "/training/courses";

// API Step 1 course create
export const API_STEP_1_COURSE_CREATE = "/course/save/step1";

// API Step 1 Course View
export const API_STEP_1_COURSE_VIEW = "/course/view/step1";

// API Step 1 Delete Instructor
export const API_DELETE_FORM_REPEATER = "/course/removeitems";

// API Step 2 course create
export const API_STEP_2_COURSE_CREATE = "/course/save/step2";

// API Step 2 Course View
export const API_STEP_2_COURSE_VIEW = "/course/view/step2";

// API Step 3 course create
export const API_STEP_3_COURSE_CREATE = "/course/save/step3";

// API Step 3 Course View
export const API_STEP_3_COURSE_VIEW = "/course/view/step3";

// API Step 4 Course View Lessons
export const API_STEP_4_COURSE_VIEW = "/course/view/step4";

// API Step 4 Course Save Lesson Name
export const API_STEP_4_SAVE_LESSON_NAME = "/course/save/step4/lesson";

// API Step 4 Course Save Lesson Content
export const API_STEP_4_SAVE_LESSON_CONTENT = "/course/save/step4/content";

// API Step 4 Course Save Lesson Task Name
export const API_STEP_4_SAVE_LESSON_TASK_NAME = "/course/save/step4/task";

// API Step 4 Course View All Lesson Tasks
export const API_STEP_4_VIEW_ALL_LESSON_TASKS = "/course/lesson/task/view";

// API Step 5 course create
export const API_STEP_5_COURSE_CREATE = "/course/save/step5";

// API Step 5 Course View
export const API_STEP_5_COURSE_VIEW = "/course/view/step5";

// API Step 6 course create
export const API_STEP_6_COURSE_CREATE = "/course/save/step6";

// API Step 6 Course View
export const API_STEP_6_COURSE_VIEW = "/course/view/step6";

// API Step 8 course create
export const API_STEP_8_COURSE_CREATE = "/course/save/step8";

// API Step 8 Course View
export const API_STEP_8_COURSE_VIEW = "/course/view/step8";

// API to view all published courses
export const API_VIEW_PUBLISHED_COURSES = "/courses/publishedbyme";

//createlessonassignment
export const API_CREATE_LESSON_ASSIGNMENT = "/course/createlessonassignment";

// API to view all the details of a lesson
export const API_VIEW_LESSON_DETAILS = "/course/getcourselessondetails";

// API to submit a rating for a course
export const API_COURSE_RATING = "/feedback/send";

// API to fetch all the reviews for a course
export const API_COURSE_VIEW_REVIEWS = "/feedbacks/module";

// API to submit the student's/subscriber's question in a lesson
export const API_SUBMIT_SUBSCRIBER_QUESTION = "/course/lesson/askquestion";

//getallcourselessonfaqs
export const API_GET_ALL_COURSE_LESSON_FAQS = "/course/getallcourselessonfaqs";

// --------------------------------------------------------------------------------------------

// API contact url for all contacts (used in the StartNewChatModal.js)
export const API_ALL_CONTACTS = "/contact/getallcontacts";

// API contact url for all contacts (used in the many modules)
export const API_GET_ALL_CONTACTS_FOR_SELECT =
  "/contact/getusercontactlistoptions";

//API for fetch selected contact from url params
export const API_CONTACT_SELECTED = "/contact/selected";

// API conversation url (used in the LeftPanel.js)
export const API_CONTACTS_ADDEDTOCHAT = "/contacts/addedtochat";

// API Post Chat conversation - basic
export const API_CONTACT_CONVERSATION_SAVE = "/conversation/save";

// API Contact Conversation (to display all the conversations of a contact in ContactRightPanel.js)
export const API_CONTACT_CONVERSATION = "/conversation/contact";

// API Groups
export const API_GROUP_SAVE = "/group/save";

// API Tags (to fetch all the tags and display in AddTagsModal.js)
export const API_GET_ALL_TAGS = "/tag/getalltags";

// API Associated Groups (to fetch the all the groups that the member is associated with)
export const API_ASSOCIATED_GROUPS = "/groups";

// API send to Group chat (to send chat in seleceted group)
export const API_SEND_MESSAGE_TO_GROUP = "/groupconversation/save";

// API Group Conversation (to display all the conversations of a group in GroupRightPanel.js)
export const API_GROUP_CONVERSATION = "/conversation/group";

// API Post group conversation
export const API_GROUP_CONVERSATION_SAVE = "/groupconversation/save";

// API Message send
export const API_MAIL_SEND = "/quickmail/save";

// mail forward
export const API_FORWARD_MAIL = "/mail/forward";

// API Mail fetch
export const API_FETCH_ALL_CONTACT_EMAILS = "/mails/contact";

// API Group Chat Likes
export const API_GROUP_CHAT_LIKE = "/conversation/like";

// API to Forward conversation
export const API_FORWARD_MESSAGE = "/conversation/forward";

// API to create new tag
export const API_CREATE_NEW_TAG = "/tag/createtag";

// API to Save Chat Tag
export const API_SAVE_CHAT_TAGS = "/conversation/savetags";

// API to remove Chat Tag
export const API_REMOVE_CHAT_TAGS = "/conversation/tags/remove";

// API to add tag to contact
export const API_ADD_TAG_CONTACT = "/contact/addtags";

// API to remove tag from contact
export const API_REMOVE_TAG_CONTACT = "/contact/removetags";

//API to add tag to group
export const API_ADD_TAG_GROUP = "/group/addtags";

//API to remove tag from group
export const API_REMOVE_TAG_GROUP = "/group/removetags";

//API for add tag for mail
export const API_ADD_TAG_MAIL = "/message/addtags";

//API for remove tag from mail
export const API_REMOVE_TAG_MAIL = "/message/removetags";

// API for view tag in mail
export const API_VIEW_MAIL_TAG = "/mail/view";

// API to get & view email details
export const API_VIEW_EMAIL_DETAILS = "/mail/view";

// API for group leave
export const API_GROUP_LEAVE = "/group/leave";

// API for group delete
export const API_GROUP_DELETE = "/group/delete";

// API for champion
export const API_GROUP_CHAMPION = "/conversation/topresponse";

// API Step 4 Create Task Content
export const API_STEP_4_SAVE_LESSON_TASK_CONTENT =
  "/course/save/step4/taskcontent";

// API Step 4 Create Lesson Question
export const API_STEP_4_ADD_LESSON_QUESTION = "/course/save/step4/question";

// API Step 4 Course View All Lesson Questions
export const API_STEP_4_VIEW_ALL_LESSON_QUESTIONS =
  "/course/lesson/question/view";

// API Step 4 Create Lesson Answer
export const API_STEP_4_ADD_LESSON_ANSWER =
  "/course/save/step4/questioncontent";

// API lesson forum get all forum list
export const API_LESSON_FORUM_LIST = "/activities/listbymodule";

// API get all categories for global search
export const API_COURSE_CATEGORIES = "/course/categories";

// API add tag for course
export const COURSE_ADD_TAG = "/course/addtagtocourse";

// API remove tag for course
export const COURSE_REMOVE_TAG = "/course/removetags";

// API to mark the lesson complete
export const API_LESSON_COMPLETE = "/course/lesson/completed";

/* --------------------------------- Challenges APIS ------------------------------------ */
// API to view the list of all the challenges
export const API_VIEW_ALL_JOBS = "/activejobs";

// API to view the details of a particular job
export const API_VIEW_JOB_DETAILS = "/view/job/";

// API to view all events for course
export const API_VIEW_EVENT_DETAILS = "/course/getevents";

// API to view about course
export const API_VIEW_COURSE_DETAILS = "/course/getcoursedetails";

// API to view LESSON course
export const API_VIEW_LESSONTAB_DETAILS = "/course/lessoninfo";

// API to view TEAM course
export const API_VIEW_TEAM_DETAILS = "/course/getteam";

// API to view the Details Tab Data
export const API_CHALLENGES_DETAILS_DETAILS_TAB = "/job/about";

// API to view the Team Tab Data
export const API_CHALLENGES_DETAILS_TEAM_TAB = "/job/team";

// API to view the Applied Tab Data
export const API_CHALLENGES_DETAILS_APPLIED_TAB = "/job/applicants";

// API to view the Dates Tab Data
export const API_CHALLENGES_DETAILS_DATES_TAB = "/job/phases";

// API to view the Docs Tab Data
export const API_CHALLENGES_DETAILS_DOCS_TAB = "/job/docs";

// API to view the FAQ Tab Data
export const API_CHALLENGES_DETAILS_FAQ_TAB = "/job/faq";

// API to view process tab data
export const API_CHALLENGES_DETAILS_PROCESS = "/job/process";

//Api for apply now modal challenge details
export const API_CHALLENGES_APPLY_NOW = "/job/applynow";

//Api for add tag in challenge details
export const ADD_TAG_CHALLENGE = "/job/addtags";

//Api for remove tag in challenge details
export const REMOVE_TAG_CHALLENGE = "/job/removetags";

//Api for view application details
export const VIEW_APPLICATION_STATUS = "/job/responsedetails";

// API to join course
export const API_JOIN_COURSE = "/course/joincourse";

// API TO SET WINNER IN FORUM
export const API_SET_WINNER = "/activity/setwinner";

// API TO ACCEPT TASK IN LESSON
export const API_ACCEPT_TASK = "/course/subscribers/assignment/join";

// API TO SUBMIT ASSIGNMENT IN LESSON
export const API_SUBMIT_ASSIGNMENT = "/course/subscribers/assignment/submit";

//API TO GET ALL CHAMPIONS LISTS
export const API_CHAMPIONS_LISTS = "/champions";

//get all app user
export const API_GET_ALL_CHAMPIONS = "/account/getallappusers";

// API TO GET CHAMPION DETAILS
export const API_CHAMPION_ABOUT = "/account/getuserdetails";

// API TO GET CHAMPION BANNER
export const API_CHAMPION_BANNER = "/account/getuserdetails";

// API TO SEND CONTACT CHAMPION MODAL
export const API_CHAMPION_CONTACT = "/contact/sendinvite";

//API TO GET ALL RECIEVED INVITATION
export const API_INVITE_LISTS = "/contact/getallinvites";

//get all connect invites
export const API_GET_ALL_RECIEVED_INVITE = "/contact/getallrecievedinvites";

//get all send invite list
export const API_GET_ALL_SEND_INVITE = "/contact/getallsendinvites";

//accept/reject invite
export const API_ACCEPT_INVITE = "/contact/updatecontact";

//cancel/reject request
export const API_REJECT_CONTACT_REQUEST = "/contact/rejectrequest";

//unfriend contact
export const API_UNFRIEND_CONTACT = "/contact/unfriendcontact";

//resume contact
export const API_RESUME_CONTACT = "/contact/resumecontact";

//API TO ACCEPT/REJECT INVITATION
export const API_INVITE_ACTION = "/connect/respond";

//API FOR ADD TAG IN CHAMPION MODAL
export const ADD_TAG_CHAMPION = "/account/addtagtouser";

//API FOR REMOVE TAG IN CHAMPION MODAL
export const REMOVE_TAG_CHAMPION = "/user/removetags";

/* -------------------------------- Event List API --------------------------------- */
//Api to get all event category
export const API_EVENT_CATEGORY_LISTS = "/event/categories";
// API TO GET LIST OF ALL THE EVENTS
export const API_EVENT_LIST = "/event/getallevents";

// API to GET BANNER DETAILS OF THE EVENT
export const API_EVENT_BANNER = "/event/geteventdetails";

// API to GET ABOUT TAB DETAILS OF THE EVENT
export const API_EVENT_ABOUT = "/event/geteventdetails";

// API to GET ABOUT PARTICIPANTS DETAILS OF THE EVENT
export const API_EVENT_PARTICIPANTS = "/event/geteventparticipants";

//API FOR ADD TAG IN EVENT MODAL
export const ADD_TAG_EVENT = "/event/addremovetagevent";

//API FOR REMOVE TAG IN EVENT MODAL
export const REMOVE_TAG_EVENT = "/event/removetags";

//API FOR GALLERY LISTS
export const GALLERY_LISTS = "/event/geteventdetails";

// API FOR JOINING THE EVENT
export const API_JOIN_EVENT = "/event/joinevent";

/* -------------------------------- Group List API --------------------------------- */
export const API_GROUP_LIST = "/group/getallgroups"; // url for get list of group

//getgrouplistoptions
export const API_GROUP_LIST_OPTIONS = "/group/getgrouplistoptions";

/*-------------------- Group details url start here --------------------------------*/
export const API_GROUP_DETAILS_TOP = "/group/getgroupdetails"; //url for get group details

export const API_GROUP_ABOUT = "/group/getgroupdetails"; // url for get about group

export const API_GROUP_MEMBER_LIST = "/group/getgroupdetails"; //url for get group member lists

export const API_GROUP_GALLERY = "/group/getgroupdetails"; //url for get group gallery data

export const API_JOIN_GROUP = "/group/joingroup"; // for apply join group

export const API_GROUP_EVENT_LISTS = "/event/getallevents"; // fecth all event list

export const API_GROUP_ADD_TAGS = "/group/addremovetaggroup"; // add tag to a group

export const API_GROUP_REMOVE_TAGS = "/group/removetags"; // remove tag from group

/* -------------------------------- Feedback API --------------------------------- */
// API TO GET LIST OF ALL THE QUESTIONS
export const API_FEEDBACK_QUESTIONS = "/survey/feedback/response";

// API TO SAVE SURVEY RESPONSES
export const API_SURVEY_FEEDBACK_SAVE = "/survey/feedback/save";

// --------------------------- NODE API ---------------------------
//-----------------------------------------------------------------

/*======= login and registration url start =======*/
export const API_LOGIN_V2 = "/auth/login"; //login url

export const API_REGISTRATION_V2 = "/auth/register"; //registration url

export const API_GET_OTP_V2 = "/auth/getotp"; //for getting otp

export const API_VALIDATE_OTP_V2 = "/auth/validateotp"; //for validating otp

export const API_UPDATE_PASSSWORD_V2 = "/auth/updatepassword"; //for update passowrd

export const API_AUTH_VALIDATE_V2 = "/auth/validateuser"; //validation url

/* --------------------------------- FAQ APIs --------------------------------- */

export const API_DEMO_POST_V2 = "/demo/demopost"; // demo post

export const API_SINGLE_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_MULTIPLE_FILE_UPLOAD = "/upload/multiupload"; //for multiple file upload

export const API_FAQ_LIST = "/faq/getallfaqs"; // url for get list of faq

export const API_FAQ_DETAILS = "/faq/detailsfaq"; // url for get faq details

export const API_CREATE_FAQ = "/faq/createfaq"; // url for save question

export const API_UPDATE_FAQ = "/faq/updatefaq"; // url for save answer

export const API_DELETE_FAQ = "/faq/deletefaq"; // url for delete faq
