/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { getAllCategories } from "helpers/Common/CommonHelper";
/* ---------------------------------------------------------------------------------- */

const ActivityModal = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const { loadFilterData, setloadFilterData } = useContext(GlobalIndraInfo);

  // useState to get the JSON API data to populate the Multiselect DropDown.
  const [contactList, setContactList] = useState([]); // contact
  const [groupList, setGroupList] = useState([]); // grp
  const [eventList, setEventList] = useState([]); // event
  const [tagList, setTagList] = useState([]); // label
  const [categoryList, setCategoryList] = useState([]); // categories

  const [title, setTitle] = useState("");
  const [selectedContacts, setSelectedContacts] = useState();
  const [selectedWinner, setSelectedWinner] = useState();
  const [userSelectedTags, setUserSelectedTags] = useState();
  const [selectedGroups, setSelectedGroups] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const [tagData, setTagData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [winnerData, setWinnerData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "1px solid #7d7d7d",
      backgroundColor: "#F0F0F0",
      height: 50,
      borderRadius: "0px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // function for get all contact
  const gatAllContacts = async () => {
    const requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_CONTACTS_FOR_SELECT +
      `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setContactList(response.data);
      }
    } catch (error) {}
  };

  // function for get all tags
  const getTagsData = async () => {
    let requestURL =
      url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {}
  };

  // function for get all category
  const getCategories = async () => {
    try {
      const parentSlug = "CAT_ACTIVITY";

      const response = await getAllCategories(parentSlug);

      if (response) {
        setCategoryList(response);
      }
    } catch (error) {}
  };

  // function for get all groups
  const getAllGroups = async () => {
    try {
      const requestURL =
        url.API_BASE_URL + url.API_GROUP_LIST_OPTIONS + `?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        setGroupList(response.data);
      }
    } catch (error) {}
  };

  // function for get all events
  const getAllEvents = async () => {
    try {
      const requestURL =
        url.API_BASE_URL +
        url.API_EVENT_LIST +
        `?token=${token}&modulefrom=frontend`;

      const response = await getData(requestURL);
      if (response.status) {
        setEventList(response.data);
      }
    } catch (error) {}
  };

  // function for select contacts
  const contactHandler = (val) => {
    setSelectedContacts(val);
    let contactItem = val.map((item) => {
      return item.value;
    });
    setContactData(contactItem);
  };

  // function for select winner
  const winnerHandler = (val) => {
    setSelectedWinner(val);
    let winnerItem = val.map((item) => {
      return item.value;
    });
    setWinnerData(winnerItem);
  };

  // function for select groups
  const groupHandler = (val) => {
    setSelectedGroups(val);
    let groupItem = val.map((item) => {
      return item.value;
    });
    setGroupData(groupItem);
  };

  // function for select events
  const eventHandler = (val) => {
    setSelectedEvent(val);
    let eventItem = val.map((item) => {
      return item.value;
    });
    setEventData(eventItem);
  };

  // function for select tags
  const tagHandler = (val) => {
    setUserSelectedTags(val);
    let tagItem = val.map((item) => {
      return item.value;
    });
    setTagData(tagItem);
  };

  // function for set selected category
  const categoryHandler = (val) => {
    setSelectedCategory(val);
    let categoryItem = val.map((item) => {
      return item.value;
    });
    setCategoryData(categoryItem);
  };

  // function for sending search query to leftpanel
  const searchQueryHandler = (e) => {
    e.preventDefault();
    let filterModalData = {
      title: title,
      contact: contactData,
      winner: winnerData,
      group: groupData,
      event: eventData,
      tags: tagData,
      category: categoryData,
    };
    props.searchQuery(filterModalData);
    let activityFilterModal = document.querySelector("#filter_Mdl");
    let modal = bootstrap.Modal.getInstance(activityFilterModal);
    modal.hide();
    resetModal();
  };

  const resetModal = () => {
    setTitle("");
    setSelectedContacts(null);
    setSelectedWinner(null);
    setSelectedGroups(null);
    setSelectedEvent(null);
    setSelectedCategory(null);
    setUserSelectedTags(null);
    setTagData([]);
    setCategoryData([]);
    setGroupData([]);
    setContactData([]);
    setEventData([]);
    setWinnerData([]);
  };

  useEffect(() => {
    if (loadFilterData) {
      gatAllContacts();
      getTagsData();
      getCategories();
      getAllGroups();
      getAllEvents();
      setloadFilterData(false);
    }
  }, [loadFilterData]);

  useEffect(() => {
    gatAllContacts();
    getTagsData();
    getCategories();
    getAllGroups();
    getAllEvents();
  }, []);

  return (
    <>
      <div className="filter_modal">
        <div
          className="modal fade"
          id="filter_Mdl"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("Filter data")}</h5>
                <button
                  type="button"
                  className="close_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetModal}
                >
                  <span className="material-icons-outlined">close </span>
                </button>
              </div>
              <div className="modal-body">
                {/* ----- modal form start here ----- */}
                <form onSubmit={searchQueryHandler}>
                  {/* ----- modal form title ----- */}
                  <div className="srch_titleBx">
                    <label>{t("Search by Title")}</label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="form-control"
                      placeholder={t("Enter title")}
                    />
                  </div>
                  {/* ----- modal form title end ----- */}

                  <div className="filter_tagsOuetr">
                    {/* ----- modal form select contacts ----- */}
                    <div className="filter_tags_row">
                      <label>{t("Contact")}</label>
                      <div className="filter_tagsFill">
                        <Select
                          styles={customSelectStyle}
                          isMulti={true}
                          value={selectedContacts}
                          onChange={(val) => {
                            contactHandler(val);
                          }}
                          placeholder={t("Select Contacts")}
                          options={contactList}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>
                    {/* ----- modal form select contacts end ----- */}

                    {/* ----- modal form select contacts ----- */}
                    <div className="filter_tags_row">
                      <label>{t("Winner")}</label>
                      <div className="filter_tagsFill">
                        <Select
                          styles={customSelectStyle}
                          isMulti={true}
                          value={selectedWinner}
                          onChange={(val) => {
                            winnerHandler(val);
                          }}
                          options={contactList}
                          classNamePrefix="select2-selection"
                          placeholder={t("Select Winner")}
                        />
                      </div>
                    </div>
                    {/* ----- modal form select contacts end ----- */}

                    {/* ----- modal form select groups ----- */}
                    <div className="filter_tags_row">
                      <label>{t("Group")}</label>
                      <div className="filter_tagsFill">
                        <Select
                          styles={customSelectStyle}
                          isMulti={true}
                          value={selectedGroups}
                          onChange={(val) => {
                            groupHandler(val);
                          }}
                          options={groupList}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>
                    {/* ----- modal form select groups end ----- */}

                    {/* ----- modal form select events ----- */}
                    <div className="filter_tags_row">
                      <label>{t("Event")}</label>
                      <div className="filter_tagsFill">
                        <Select
                          styles={customSelectStyle}
                          isMulti={true}
                          value={selectedEvent}
                          onChange={(val) => {
                            eventHandler(val);
                          }}
                          options={eventList}
                          classNamePrefix="select2-selection"
                          placeholder={t("Select Event")}
                        />
                      </div>
                    </div>
                    {/* ----- modal form select events end ----- */}

                    {/* ----- modal form select tags ----- */}
                    <div className="filter_tags_row">
                      <label>{t("Labels")}</label>
                      <div className="filter_tagsFill">
                        <Select
                          styles={customSelectStyle}
                          isMulti={true}
                          value={userSelectedTags}
                          onChange={(val) => {
                            tagHandler(val);
                          }}
                          options={tagList}
                          classNamePrefix="select2-selection"
                          placeholder={t("Select Labels")}
                        />
                      </div>
                    </div>
                    {/* ----- modal form select tags end ----- */}

                    {/* ----- modal form select category ----- */}
                    <div className="filter_tags_row">
                      <label>{t("Category")}</label>
                      <div className="filter_tagsFill">
                        <Select
                          styles={customSelectStyle}
                          isMulti={true}
                          value={selectedCategory}
                          onChange={(val) => {
                            categoryHandler(val);
                          }}
                          options={categoryList}
                          classNamePrefix="select2-selection"
                          placeholder={t("Select Category")}
                        />
                      </div>
                    </div>
                    {/* ----- modal form select category end ----- */}
                  </div>
                  <div className="tags_submit">
                    <button type="submit" className="basic_btn ylw_btn">
                      {t("Submit")}
                    </button>
                  </div>
                </form>
                {/* ----- modal form end here ----- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityModal;
