/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// for translation
import { useTranslation, Trans } from "react-i18next";

const EmailPopupModal = (props) => {
  // getting token here
  const token = localStorage.getItem("token");

  // initial state for send mail to api
  const [mailSubject, setMailSubject] = useState("");
  const [mailMessage, setMailMessage] = useState("");

  // initial for validation
  const [subjectWarning, setSubjectWarning] = useState("");
  const [messageWarning, setMessageWarning] = useState("");

  let groupMembers = props.groupMembers;

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Type message here...",
  };
  /*----- end of CKEditor classic editor part----- */

  // function for composing mail
  const sendMailHandler = async () => {
    if (mailSubject.length < 2 || mailMessage.length < 5) {
      if (mailSubject.length < 2) {
        setSubjectWarning(`${t("subject is too short")}`);
      } else {
        setSubjectWarning("");
      }
      if (mailMessage.length < 5) {
        setMessageWarning(`${t("message is too short")}`);
      } else {
        setMessageWarning("");
      }
    } else {
      setSubjectWarning("");
      setMessageWarning("");

      let requestURL = url.API_BASE_URL + url.API_MAIL_SEND + `?token=${token}`;

      if (props.gid == undefined || props.gid <= 0) {
        try {
          let mailValues = {
            subject: mailSubject,
            message: mailMessage,
            module: "MOD_USER",
            component: props.cid,
            cid: props.cid,
            email: props.email,
            templateId: null,
          };

          const response = await postData(requestURL, mailValues);

          if (response.status) {
            props.confirmSend();
            document.body.classList.remove("emailpopup_open");
          }
        } catch (error) {}
      } else {
        try {
          let mailValues = {
            subject: mailSubject,
            message: mailMessage,
            module: "MOD_GROUP",
            component: props.gid,
            gid: props.gid,
            email: props.email,
            templateId: null,
          };

          const response = await postData(requestURL, mailValues);

          if (response.status) {
            document.body.classList.remove("emailpopup_open");
          }
        } catch (error) {}
      }
      // reset all input
      setMailSubject("");
      setMailMessage("");
    }
  };

  // API call to fetch conversations list
  const getEmailDetails = async (id) => {
    let requestURL =
      url.API_BASE_URL +
      url.API_VIEW_EMAIL_DETAILS +
      `?token=${token}` +
      `&mid=${id}`;

    try {
      const response = await getData(requestURL);
    } catch (error) {}
  };

  // useeffect
  useEffect(() => {
    if (props.mailInfo) {
      if (props.mailInfo.subject != undefined) {
        setMailSubject("Re: " + props.mailInfo.subject);
      }
      getEmailDetails(props.mailInfo.id);
    }
  }, [props.mailInfo]);

  return (
    <>
      {/* ---------------- Email Popup Modal starts here ---------------- */}
      <div className="email_poupPnl email_popup_view">
        {/* {JSON.stringify(mailData)} */}

        {/* ---------------- Top Panel starts here ---------------- */}
        <div className="email_topPnl">
          <div className="emailtop_row">
            <div className="emailuser_outer">
              <div className="email_cross">
                <Link
                  to="#"
                  onClick={() => {
                    // function for trigger email popup
                    document.body.classList.remove("emailpopup_open");
                    setMailSubject("");
                    setMailMessage("");
                    setMessageWarning("");
                    setSubjectWarning("");
                  }}
                >
                  <i className="material-icons-round"> close</i>
                </Link>
              </div>
              <div className="email_userInfo">
                <h3>{t("Sending to")}</h3>
                {props.email == null || props.email == undefined ? (
                  <>
                    {groupMembers != null || groupMembers != undefined ? (
                      <div className="groupmember_section">
                        {groupMembers.slice(0, 5).map((item, index) => (
                          <Link to="#" key={index}>
                            {item.name},
                          </Link>
                        ))}
                        {groupMembers.length > 5 ? (
                          <Link to="#">
                            and {groupMembers.length - 5} more...
                          </Link>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Link to="#">{props.email}</Link>
                )}
              </div>
            </div>
          </div>

          {/* ------ subject input ---- */}
          <div className="chat_input_fill subject_fill">
            <input
              type="text"
              className="form-control"
              placeholder={t("Subject")}
              value={mailSubject}
              onChange={(e) => setMailSubject(e.target.value)}
            />
            {subjectWarning == "" ? null : (
              <p className="text-warning">* {subjectWarning}</p>
            )}
          </div>
        </div>
        {/* ---------------- Top Panel ends here ---------------- */}

        {/* ---------------- Middle Panel starts here ---------------- */}
        <div className="email_editorupload_pnl">
          <div className="edior_textPnl">
            <div className="editor_msgpnl">
              {/* -------------------- Text Editor starts here ------------------- */}
              <CKEditor
                editor={ClassicEditor}
                config={editorConfig}
                data={mailMessage ? mailMessage : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "250px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editContent = editor.getData();
                  setMailMessage(editContent);
                }}
              />
              {/* -------------------- Text Editor ends here ------------------- */}
              {messageWarning == "" ? null : (
                <p className="text-warning">* {messageWarning}</p>
              )}
            </div>
          </div>
          <div className="email_upload_row"></div>
        </div>
        {/* ---------------- Middle Panel ends here ---------------- */}

        {/* ---------------- Lower Panel starts here ---------------- */}
        <div className="bttm_send_pnl">
          <div className="upload_sendOuter">
            <div className="email_uploadBtn">
              <button>
                <i className="material-icons-round">upload_file</i>
                <input type="file" className="upload_fileBtn" />
              </button>
            </div>
            <div className="dlete_sendBtnOuter">
              {/* <button className="chat_delete_btn">
                <i className="material-icons-round"> delete</i>
              </button> */}
              <button
                className="upload_sendBtn next_rply"
                onClick={sendMailHandler}
              >
                <i className="material-icons-round"> send</i>
              </button>
            </div>
          </div>
        </div>
        {/* ---------------- Lower Panel ends here ---------------- */}
      </div>
      {/* ---------------- Email Popup Modal ends here ---------------- */}
    </>
  );
};

export default EmailPopupModal;
