import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import "moment-timezone";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ---------------------------------------------------------------------------------- */

// import custom components here
import Header from "common/Header";
import Footer from "common/Footer";

const Meeting = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // getting logged in user's token here
  const token = localStorage.getItem("token");

  // useState to store static JSON data
  const [sampleJSON, setSampleJSON] = useState([
    {
      id: 4266,
      title: "Rishav Test Title",
      start: "2022-07-08T13:30:00",
      end: "2022-07-08T14:00:00",
      borderColor: "#4680ff",
      backgroundColor: "#4680ff",
      textColor: "#fff",
    },
    {
      id: 4267,
      title: "Rishav Test Title",
      start: "2022-07-08T13:30:00",
      end: "2022-07-08T14:00:00",
      borderColor: "#4680ff",
      backgroundColor: "#4680ff",
      textColor: "#fff",
    },
    {
      id: 4265,
      title: "Some Title",
      start: "2022-07-07T14:30:00",
      end: "2022-07-07T15:00:00",
      borderColor: "#4680ff",
      backgroundColor: "#4680ff",
      textColor: "#fff",
    },
    {
      id: 4264,
      title: "Some Title",
      start: "2022-07-07T14:30:00",
      end: "2022-07-07T15:00:00",
      borderColor: "#4680ff",
      backgroundColor: "#4680ff",
      textColor: "#fff",
    },
    {
      id: 4259,
      title: "Del team meet",
      start: "2022-04-08T19:00:00",
      end: "2022-04-08T20:00:00",
      borderColor: "#FC6180",
      backgroundColor: "#FC6180",
      textColor: "#fff",
    },
    {
      id: 4258,
      title: "Meeting available",
      start: "2022-04-08T15:00:00",
      end: "2022-04-08T18:00:00",
      borderColor: "#4680ff",
      backgroundColor: "#4680ff",
      textColor: "#fff",
    },
    {
      id: 3558,
      title: "Networking de negocios",
      start: "2020-12-16T10:00:00",
      end: "2020-12-16T11:15:00",
      borderColor: "#4680ff",
      backgroundColor: "#4680ff",
      textColor: "#fff",
    },
  ]);

  //initial useState to hold all events data
  const [calendarData, setCalendarData] = useState({
    eventTitle: "",
    dates: [],
    eventFormData: [],
  });

  const idNumber = {
    id: 0,
  };

  const [eventFormId, setEventFormId] = useState([idNumber]);

  /* --------------- Event Form repeater --------------- */
  const eventFormTemplate = {
    title: "",
    starttime: "",
    endtime: "",
    note: "",
    sharepublic: "",
    purpose: "",
  };

  const [eventForm, setEventForm] = useState([eventFormTemplate]);

  // function for add event form
  const onAddFormBlock = () => {
    setEventForm([...eventForm, eventFormTemplate]);
  };

  // function for input value
  const customEventHandler = (e, index) => {
    const updatedEvent = eventForm.map((statusItem, i) =>
      index == i
        ? Object.assign(statusItem, { [e.target.name]: e.target.value })
        : statusItem
    );

    setEventForm(updatedEvent);
    const eventValueData = updatedEvent.map((item, number) => {
      return item;
    });
    setCalendarData({ ...calendarData, eventFormData: eventValueData });
  };

  // function for delete event form
  const onDeleteEventForm = (index) => {
    if (index !== 0) {
      const filteredEvent = [...eventForm];
      filteredEvent.splice(index, 1);
      setEventForm(filteredEvent);
    }
  };

  /* --------------- Instructor block repeater till here --------------- */

  /* --------------- Function for sending event data to API --------------- */
  const submitFormHandler = async (event) => {
    event.preventDefault();

    let eventSendData = {
      dates: calendarData.dates,
      eventdata: calendarData.eventFormData,
    };

    let requestURL = url.API_BASE_URL + url.DEMO_POST + `?token=${token}`;

    try {
      const response = await postData(requestURL, eventSendData);
      if (response.status) {
        resetHandler();
      }
    } catch (error) {}
  };

  const resetHandler = () => {
    setCalendarData({
      ...calendarData,
      dates: [],
      eventFormData: [],
      eventTitle: "",
    });

    setCalendarState({
      ...calendarData,
      currentEvents: [],
    });
  };

  // Required for single select
  const sharingOptions = [
    { label: "Allow", value: "allow" },
    { label: "Disallow", value: "disallow" },
  ];

  // Required for purpose single select
  const purposeOptions = [
    { label: "Availability", value: "availability" },
    { label: "Meeting", value: "meeting" },
  ];

  /* -------------------- Single Select Requirements starts here -------------------- */

  // Initial useState to hold the calendar event
  const [calendarState, setCalendarState] = useState({
    weekendsVisible: true,
    currentEvents: [],
  });

  // Initial empty array to load the calendar
  // will be required when API will return the data
  const INITIAL_EVENTS = [
    // {
    //   id: 0,
    //   title: "All-day event",
    //   start: new Date().toISOString().replace(/T.*$/, ""),
    // },
    // {
    //   id: 1,
    //   title: "Timed event",
    //   start: new Date().toISOString().replace(/T.*$/, "") + "T12:00:00",
    // }
  ];

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    let generatedEventId = Math.floor(Math.random() * 100);

    // let title = `Event - ${generatedEventId}`;
    let title = "Selected";

    if (title) {
      calendarApi.addEvent({
        id: generatedEventId,
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });

      // setting the title here (temporary)
      setCalendarData({
        ...calendarData,
        eventTitle: title,
      });

      let dateJSON = {
        id: generatedEventId,
        startingDate: moment(selectInfo.startStr).format("DD/MM/YYYY"),
        endingDate: moment(selectInfo.endStr).format("DD/MM/YYYY"),
      };

      // pushing the dates to the useState here
      calendarData.dates.push(dateJSON);

      //setEventFormId([...eventFormId, obtainedID]);
    }
  };

  // Function to remove the state from the event from calendar
  const handleEventClick = (clickInfo) => {
    // removing the date from the useState here
    var removeIndex = calendarData.dates
      .map(function (item) {
        return item.id;
      })
      .indexOf(37);
    calendarData.dates.splice(removeIndex, clickInfo.event.id);

    clickInfo.event.remove();
    setCalendarData({ ...calendarData, eventTitle: "" });
    let removedId = [...eventFormId];
    removedId.splice(1);
    setEventFormId(removedId);
  };

  // Function to save the created event in useState
  const handleEvents = (events) => {};

  // Function to display the created event in the calendar
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="mx-3 my-3">
        <div className="row">
          {/* ---------------- left side Calendar components starts here ---------------- */}
          <div className="col-lg-9 col-md-9 py-2 border border-2 my-2 rounded-1">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={calendarState.weekendsVisible}
              initialEvents={sampleJSON} // alternatively, use the `events` setting to fetch from a feed
              select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />
          </div>
          {/* ---------------- left side Calendar components starts here ---------------- */}

          {/* ---------------- right side Form components (repeater) starts here ---------------- */}
          {calendarData.dates.length == 0 ||
          calendarData.dates == null ? null : (
            <div className="col-lg-3 col-md-3 border border-2 my-2 rounded-1">
              {eventForm.map((item, index) => {
                return (
                  <div key={index}>
                    {/* ---------- Single Select Input (Purpose) ---------- */}
                    <div className="jobinput_fill my-3">
                      <label className="form-label fs-6">Purpose:</label>
                      <select
                        name="purpose"
                        id="purpose"
                        className="form-select text-secondary"
                        value={item.purpose}
                        onChange={(e) => {
                          customEventHandler(e, index);
                        }}
                      >
                        {purposeOptions.map((item3, index3) => {
                          return (
                            <option
                              key={index3}
                              value={item3.value}
                              className="text-secondary"
                            >
                              {item3.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* ---------- Single Select Input (SharingPublic) ---------- */}
                    <div className="jobinput_fill my-3">
                      <label className="form-label fs-6">
                        {t("Share Public:")}
                      </label>

                      <select
                        name="sharepublic"
                        id="sharepublic"
                        className="form-select text-secondary"
                        value={item.sharepublic}
                        onChange={(e) => {
                          customEventHandler(e, index);
                        }}
                      >
                        {sharingOptions.map((item3, index3) => {
                          return (
                            <option
                              key={index3}
                              value={item3.value}
                              className="text-secondary"
                            >
                              {item3.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* ---------- Enter Title Input ---------- */}
                    <div className="input_fill jobinput_fill my-3">
                      <label className="form-label fs-6">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        name="title"
                        value={item.title}
                        onChange={(e) => customEventHandler(e, index)}
                      />
                    </div>

                    {/* ---------- Start Time Input ---------- */}
                    <div className="input_fill jobinput_fill my-3">
                      <label className="form-label fs-6">Start Time</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Time"
                        name="starttime"
                        value={item.starttime}
                        onChange={(e) => customEventHandler(e, index)}
                      />
                    </div>

                    {/* ---------- End Time Input ---------- */}
                    <div className="input_fill jobinput_fill my-3">
                      <label className="form-label fs-6">End Time</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="End Time"
                        name="endtime"
                        value={item.endtime}
                        onChange={(e) => customEventHandler(e, index)}
                      />
                    </div>

                    {/* ---------- Note Textarea Input ---------- */}
                    <div className="jobinput_fill my-3">
                      <label className="form-label fs-6">{t("Note")}</label>
                      <textarea
                        type="text"
                        rows={3}
                        className="form-control msg_bxArea p-3"
                        placeholder={t("Enter Note")}
                        name="note"
                        value={item.note}
                        onChange={(e) => customEventHandler(e, index)}
                      ></textarea>
                    </div>

                    {/* ---- delete button for repeater ----- */}
                    {index == 0 ? null : (
                      <button
                        className="basic_btn mb-2"
                        onClick={() => onDeleteEventForm(index)}
                      >
                        <span className="material-icons-outlined">delete</span>{" "}
                      </button>
                    )}
                  </div>
                );
              })}

              {/* ---- add button for add new form block ----- */}
              <div className="my-2">
                <button className="basic_btn mb-2" onClick={onAddFormBlock}>
                  <span className="material-icons-outlined">add</span>
                </button>
              </div>

              {/* ---------- End Time Input ---------- */}
              <div className="input_fill jobinput_fill my-3">
                <button
                  className="basic_btn mb-2"
                  onClick={(e) => submitFormHandler(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Meeting;
