/* eslint-disable */
import React, { useEffect } from "react";

// import custom component
import Header from "common/Header";
import Footer from "common/Footer";
import Banner from "components/HomeComponents/Banner";
import TabArea from "common/tabarea/TabArea";
import HomeCourseLists from "components/HomeComponents/HomeCourseLists";
// import SystemArea from "components/HomeComponents/SystemArea";
import HomeChampionLists from "components/HomeComponents/HomeChampionLists";
import HomeChallengeLists from "components/HomeComponents/HomeChallengeLists";
import HomeChampionRequire from "components/HomeComponents/HomeChampionRequire";
import HomeActivityLists from "components/HomeComponents/HomeActivityLists";
import HomeEventLists from "components/HomeComponents/HomeEventLists";
import SentModal from "common/Modals/SentModal";
import HomeBlogLists from "components/HomeComponents/HomeBlogLists";

const Home = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    document.title = "INDRA";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Banner />
      {token && <TabArea module={""} />}
      {/* --- this is the permanent disabled component ----- */}
      {/* {!token && <SystemArea />} */}
      {/* --- this is the permanent disabled component ----- */}
      {token && <HomeChampionLists />}
      {/* {token && <HomeChallengeLists />} */}
      {!token && <HomeChampionRequire />}
      {token && <HomeActivityLists />}
      {token && <HomeEventLists />}
      {token && <HomeCourseLists />}
      <HomeBlogLists />
      <Footer />

      {/* ----- modal and other components ----- */}
      <SentModal />
    </>
  );
};

export default Home;
