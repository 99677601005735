/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postData_v2, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */
// importing context API here
import { GlobalIndraInfo } from "App";
//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import ThanksReviewCourseModal from "../Modals/ThanksReviewCourseModal";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

const Review = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const starCount = [1, 2, 3, 4, 5];

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // get info from context
  const {
    subscriberStatus,
    joinIsLoading,
    joinNowConfirm,
    setAfterReviewReload,
    isCourseUploader,
  } = useContext(GlobalIndraInfo);

  // 👇️ get ID from url
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState("1");

  // Intitial useState to save all the review from the API
  const [reviewsList, setReviewsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [joinCourseLoading, setJoinCourseLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //select star rating
  const starRatingHandler = (value) => {
    setstarRating(value);
  };

  // Function to submit the review to the API.
  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    setCommentError(false);
    setRatingError(false);
    if (comments == "" || starRating == 0) {
      if (comments == "") {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
      if (starRating == 0) {
        setRatingError(true);
      } else {
        setRatingError(false);
      }
    } else {
      setIsSubmitting(true);

      let reviewData = {
        comment: comments,
        rating: starRating,
        courseid: params.id,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_SUBMIT_REVIEW +
        `?token=${token}&moduleslug=course`;

      try {
        const response = await postData_v2(requestURL, reviewData);

        setIsSubmitting(false);

        if (response.status) {
          resetHandler();
          let myModal = new bootstrap.Modal(
            document.getElementById("course_review_success_modal")
          );
          myModal.show();
        }
      } catch (error) {}
    }
  };

  // Function to get all the review data from API
  const getReviewsLists = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=course&moduleid=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setReviewsList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setstarRating("1");
    setComments("");
  };

  // function for calling API after closing thank you modal
  const afterThanksReviewClose = () => {
    setAfterReviewReload(true);
    getReviewsLists();
  };

  // use effect to display glow effect by clicking join button
  useEffect(() => {
    if (joinIsLoading) {
      setJoinCourseLoading(true);
    } else {
      setJoinCourseLoading(false);
    }
  }, [joinIsLoading]);

  // useEffect starts here
  useEffect(() => {
    if (params.id) {
      getReviewsLists();
    }
  }, [params.id]);

  return (
    <>
      <div className="tab-pane show active fade" id="review">
        {isLoading ? (
          <div className="course_detailspnl">
            <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "21px" }}
              ></span>
            </div>
            <div className="form_commnts_area commnts_newbx">
              <div className="course_rvw_row">
                <span
                  className="placeholder rounded-3 col-1 me-3"
                  style={{ height: "20px" }}
                ></span>
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "20px" }}
                ></span>
              </div>
              <div className="commnts_send_innr">
                <span
                  className="placeholder rounded-3 col-2 mb-3"
                  style={{ height: "21px" }}
                ></span>
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "117px" }}
                ></span>
              </div>
            </div>
            <div className="review_bxOuter">
              <div className="row">
                <div className="col-lg-6">
                  <div className="review_bxItem">
                    <div className="forum_cmntsRow">
                      <div className="team_userOuter forum_usrOuter">
                        <div className="team_usr_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "50px", height: "22px" }}
                          ></span>
                        </div>
                        <div className="team_usrTxt flex-fill">
                          <div className="d-flex justify-content-between mb-4">
                            <div className="col-6 p-0">
                              <span
                                className="placeholder d-block rounded-3 col-8 mb-2"
                                style={{ height: "26px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-3 col-6 mb-2"
                                style={{ height: "17px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-3 col-6"
                                style={{ height: "22px" }}
                              ></span>
                            </div>
                            <div className="col-6 p-0">
                              <span className="placeholder placeholder-sm rounded-3 col-6 ms-auto"></span>
                            </div>
                          </div>
                          <div className="forum_para mb-4">
                            <span className="placeholder placeholder-sm rounded-3 col-12 mb-3"></span>
                            <span className="placeholder placeholder-sm rounded-3 col-8 mb-3"></span>
                            <span className="placeholder placeholder-sm rounded-3 col-4"></span>
                          </div>
                          <div className="form_share">
                            <span
                              className="placeholder rounded-3 me-2"
                              style={{ width: "97px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3 me-2"
                              style={{ width: "123px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3"
                              style={{ width: "38px", height: "38px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="review_bxItem">
                    <div className="forum_cmntsRow">
                      <div className="team_userOuter forum_usrOuter">
                        <div className="team_usr_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "50px", height: "22px" }}
                          ></span>
                        </div>
                        <div className="team_usrTxt flex-fill">
                          <div className="d-flex justify-content-between mb-4">
                            <div className="col-6 p-0">
                              <span
                                className="placeholder d-block rounded-3 col-8 mb-2"
                                style={{ height: "26px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-3 col-6 mb-2"
                                style={{ height: "17px" }}
                              ></span>
                              <span
                                className="placeholder d-block rounded-3 col-6"
                                style={{ height: "22px" }}
                              ></span>
                            </div>
                            <div className="col-6 p-0">
                              <span className="placeholder placeholder-sm rounded-3 col-6 ms-auto"></span>
                            </div>
                          </div>
                          <div className="forum_para mb-4">
                            <span className="placeholder placeholder-sm rounded-3 col-12 mb-3"></span>
                            <span className="placeholder placeholder-sm rounded-3 col-8 mb-3"></span>
                            <span className="placeholder placeholder-sm rounded-3 col-4"></span>
                          </div>
                          <div className="form_share">
                            <span
                              className="placeholder rounded-3 me-2"
                              style={{ width: "97px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3 me-2"
                              style={{ width: "123px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3"
                              style={{ width: "38px", height: "38px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="course_detailspnl">
            {/* <div className="contnt_hdng tab_Hdng">
              <h4>{t("Review")}</h4>
            </div> */}

            {/* ------- Star rating & Review section starts here ------- */}
            {subscriberStatus ||
            userInfo.role.slug === "SUPER_ADMIN" ||
            userInfo.role.slug === "ADMIN" ||
            isCourseUploader ? (
              <div className="form_commnts_area commnts_newbx">
                {/* ------- Star rating section starts here ------- */}
                <div className="course_rvw_row">
                  <div className="rating_hdng">
                    <h5>{t("Give Ratings")}</h5>
                  </div>
                  <ul>
                    {starCount.map((star, index) => (
                      <li
                        key={index}
                        className={
                          star.toString() <= starRating.toString()
                            ? "active_star"
                            : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => starRatingHandler(star.toString())}
                        >
                          <span className="material-icons">star</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                {ratingError && (
                  <p className="text-danger mb-3">* Please give rating</p>
                )}
                {/* ------- Star rating section ends here ------- */}

                {/* ------- Comments section starts here ------- */}
                <div className="commnts_send_innr">
                  <div className="rating_hdng">
                    <h5>{t("Give Comments")}</h5>
                  </div>
                  <div className="commnts_sendArea">
                    <textarea
                      className="form-control"
                      value={comments != "" ? comments : ""}
                      onChange={(e) => setComments(e.target.value)}
                      placeholder={t("Write your comments here")}
                    ></textarea>
                    <div className="furmcmnt_btn">
                      {/* <button className="cmnt_btn">
                    <span className="material-icons-outlined">image</span>
                  </button> */}
                      <button
                        className="cmnt_btn"
                        disabled={isSubmitting ? true : false}
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "pointer",
                        }}
                        onClick={(e) => reviewSubmitHandler(e)}
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                  {commentError && (
                    <p className="text-danger mt-2">* Please add comment</p>
                  )}
                </div>
                {/* ------- Comments section ends here ------- */}
              </div>
            ) : null}
            {/* ------- Star rating & Review section ends here ------- */}

            {/* ------- All reviews list section starts here ------- */}
            <div className="review_bxOuter">
              {reviewsList.length > 0 ? (
                <div className="row">
                  {reviewsList.map((review, index) => {
                    return (
                      <div className="col-lg-6" key={index}>
                        <div className="review_bxItem">
                          <div className="forum_cmntsRow">
                            <div className="team_userOuter forum_usrOuter">
                              {/* -------- User image section starts here -------- */}
                              <div className="team_usr_pic">
                                <img
                                  src={
                                    review.image != ""
                                      ? url.API_SERVER_URL + review.image
                                      : defaultUser
                                  }
                                  alt="UserProfilePic"
                                />
                              </div>
                              {/* -------- User image section ends here -------- */}

                              {/* -------- User details & comments section starts here -------- */}
                              <div className="team_usrTxt">
                                <div className="time_text">
                                  <h5>{review.createddate}</h5>
                                </div>
                                <h3>{review.username}</h3>
                                <h6>{review.useremail}</h6>
                                <div className="comnt_rating cstomer_tvw">
                                  <ul>
                                    {_.times(review.rating, (i) => (
                                      <li className="active_star" key={i}>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            star
                                          </i>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="forum_para">
                                  <p>{review.comment}</p>
                                </div>

                                {/* ------- Like & comment button section starts here ------- */}
                                {/* <div className="form_share">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>Likes</em>
                                  <span>2</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    chat{" "}
                                  </i>
                                  <em>Comment</em>
                                  <span>3</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    share{" "}
                                  </i>
                                </Link>
                              </div> */}
                                {/* ------- Like & comment button section endss here ------- */}
                              </div>
                              {/* -------- User details & comments section ends here -------- */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={blankLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>{t("Sorry....!")}</h2>
                        <p>
                          {t(
                            "Reviews are not available at this moment for this Course."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* ------- All reviews list section ends here ------- */}
          </div>
        )}
      </div>
      <ThanksReviewCourseModal
        afterThanksReviewClose={afterThanksReviewClose}
      />
    </>
  );
};

export default Review;
