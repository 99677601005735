/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// import images here
import coursePic1 from "assets/EventsAssets/images/course-pic1.jpg";

const HomeEventLists = () => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // array for counting glow rows
  const glowCount = [1, 2, 3];

  // initial useState for API data
  const [eventList, setEventList] = useState([]);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  const dummyOneData = [
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 1,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 2,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 3,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 4,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
  ];

  // function for all events lists
  const getEventsLists = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_EVENT_LIST +
      `?token=${token}&modulefrom=frontend&start=0&limit=10`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.length <= 3) {
            let eventResultData = response.data;
            let newEventArr = eventResultData.concat(dummyOneData);
            setEventList(newEventArr);
          } else {
            setEventList(response.data.slice(0, 10));
          }
        } else {
          setEventList([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // useEffect for page number
  useEffect(() => {
    getEventsLists();
  }, []);

  return (
    <section className="events_area pd_30">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>{t("Best Events")}</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="/events" className="border_btn ylwbrdr_btn">
              {t("See All")}
            </Link>
          </div>
        </div>
        {isLoading ? (
          <div className="slide_Outer">
            <div className="events_slider slider_innr">
              <Carousel ssr responsive={responsive}>
                {glowCount.map((count) => {
                  return (
                    <div className="slide_Item mb-4" key={count}>
                      <div className="events_itemBx">
                        <div className="champions_pic">
                          <span
                            className="placeholder col-12"
                            style={{ height: "197px" }}
                          ></span>
                        </div>
                        <div className="champions_textpnl">
                          <div className="events_dateBx">
                            <span className="placeholder col-12 h-100 mt-1"></span>
                          </div>
                          <div className="events_hdngauthor">
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                          </div>
                          <div className="leftchampion_text">
                            <span
                              className="placeholder rounded-2 col-6 mt-2"
                              style={{ height: "20px" }}
                            ></span>
                          </div>
                          <div className="experts_skill">
                            <ul>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                          <div className="details_btn">
                            <span
                              className="placeholder rounded-3 col-12"
                              style={{ height: "50px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        ) : (
          <div className="slide_Outer">
            {eventList.length > 0 && (
              <div className="events_slider slider_innr">
                <Carousel ssr showDots responsive={responsive}>
                  {eventList.map((event, index) => {
                    return (
                      <div className="slide_Item mb-4" key={index}>
                        <div className="events_itemBx">
                          {event.hideButton ? (
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                          ) : (
                            <div className="champions_pic">
                              <img
                                src={
                                  event.eventlogo === ""
                                    ? coursePic1
                                    : url.API_SERVER_URL + event.eventlogo
                                }
                                alt=""
                              />
                            </div>
                          )}

                          <div className="champions_textpnl">
                            {event.hideButton ? (
                              <div className="events_dateBx">
                                <span className="placeholder col-12 h-100 mt-1"></span>
                              </div>
                            ) : (
                              <div className="events_dateBx">
                                <h3>{event.startday}</h3>
                                <h5>
                                  {event.startmonth}{" "}
                                  <span>{event.startyear}</span>
                                </h5>
                              </div>
                            )}

                            {event.hideButton ? (
                              <div className="events_hdngauthor">
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              </div>
                            ) : (
                              <div className="events_hdngauthor">
                                <h4>
                                  {event.address !== ""
                                    ? event.address
                                    : "undisclosed"}
                                </h4>
                                <div className="evnts_enroll">
                                  <i className="material-icons-outlined">
                                    people
                                  </i>
                                  <span>
                                    {event.eventmembercount}
                                    {t("Enrolled")}
                                  </span>
                                </div>
                              </div>
                            )}

                            {event.hideButton ? (
                              <div className="leftchampion_text">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                            ) : (
                              <div className="leftchampion_text">
                                <h3>
                                  {event.title !== ""
                                    ? event.title
                                    : "Undisclosed"}
                                </h3>
                              </div>
                            )}

                            {event.hideButton ? (
                              <div className="experts_skill">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div className="experts_skill">
                                <ul>
                                  {event.eventcategorylist.map(
                                    (category, index) => (
                                      <li key={index}>
                                        <Link to="#">
                                          <span>{category.name}</span>
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}

                            {event.hideButton ? (
                              <div className="details_btn">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            ) : (
                              <div className="details_btn">
                                <Link
                                  to={`/events/details/${event._id}`}
                                  className="basic_btn ylw_btn"
                                >
                                  {t("See Details")}
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeEventLists;
