/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import images
import defaultUser from "assets/ConversationAssests/images/default-user.png";

// importing custom components here
import ChatInfo from "components/ConversationComponents/Others/ChatInfo";
import ChatBox from "components/ConversationComponents/Others/ChatBox";
import GroupRightProfilePanel from "components/ConversationComponents/Others/GroupRightProfilePanel";
import GroupChatBoard from "components/ConversationComponents/Chat/GroupChatBoard";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ---------------------------------------------------------------------------------- */

const GroupRightPanel = (props) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  //user data initial
  const [groupChatData, setGroupChatData] = useState(null);

  //use state for glow placeholder
  const [isHeaderLoading, setIsHeaderLoading] = useState(false);

  // initial state for last chat id
  const [lastChatId, setlastChatId] = useState(null);

  // initial state for contact chat section
  const [chatDetails, setChatDetails] = useState([]);

  // initial state for cron id
  const [cronId, setCronId] = useState(null);

  // initial state for chat parent id
  const [chatParentMessageInfo, setChatParentMessageInfo] = useState({});

  // for chat search query
  const [searchChatQuery, setSearchChatQuery] = useState("");
  const [searchWarning, setSearchWarning] = useState(false);

  const [hideReplyBox, setHideReplyBox] = useState(false); // for hide chat replybox

  // for refresh chat
  const [chatIsLoading, setChatIsLoading] = useState(false);

  /*------ temprary function for get chat details from sender side ------*/
  const messageDetails = (chatmessage, sender, parentMessage, media) => {
    let mediaUrl;

    if (media == [] || media == undefined) {
      mediaUrl = [];
    } else {
      mediaUrl = media;
    }

    if (sender == undefined || parentMessage == undefined) {
      setChatDetails((chatDetails) => [
        ...chatDetails,
        {
          created: new Date().getTime(),
          image: userInfo.image,
          mail: userInfo.email,
          media: mediaUrl,
          parent: null,
          message: chatmessage,
          seen: 0,
          sender: userInfo.name,
          time: "",
          type: "chat",
          uid: userInfo.id,
        },
      ]);
    } else {
      setChatDetails((chatDetails) => [
        ...chatDetails,
        {
          created: new Date().getTime(),
          image: userInfo.image,
          mail: userInfo.email,
          media: mediaUrl,
          parent: {
            sender: sender,
            message: parentMessage,
          },
          message: chatmessage,
          seen: 0,
          sender: userInfo.name,
          time: "",
          type: "chat",
          uid: userInfo.id,
        },
      ]);
    }
  };
  /*------ end of temprary function for get chat details from sender side ------*/

  /*------ function for get chat info --------*/
  const fetchGroupChatSelected = async () => {
    setIsHeaderLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_CONTACT_SELECTED +
      `?token=${token}` +
      `&gid=${props.chatInfo.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setGroupChatData(response.data);
        } else {
          setGroupChatData(null);
        }
        setIsHeaderLoading(false);
      }
    } catch (error) {}
  };

  /*------ function for get group chat details from API ------*/
  const getChatDetails = async () => {
    setChatIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_GROUP_CONVERSATION +
      `?token=${token}` +
      `&gid=${props.chatInfo.id}`;

    if (searchChatQuery != "") {
      requestURL = requestURL + `&query=${searchChatQuery}`;

      try {
        const response = await getData(requestURL);

        if (response.status) {
          setChatDetails(response.data);
        }
      } catch (error) {}
      setChatIsLoading(false);
    } else {
      try {
        const response = await getData(requestURL);

        if (response.status) {
          setChatDetails(response.data);

          /* check condition whether lastChat id 
        matched or not and set lastchat id from API */
          if (
            response.lastchatid != lastChatId ||
            response.lastchatid != null
          ) {
            setlastChatId(response.lastchatid);
          }
        }
      } catch (error) {}
      setChatIsLoading(false);
    }
  };
  /*------ end of function for get group chat details from API ------*/

  /*----- cron function runs when new chat will come -----*/
  const cronJob = async () => {
    let requestURL =
      url.API_BASE_URL +
      url.API_GROUP_CONVERSATION +
      `?token=${token}` +
      `&gid=${props.chatInfo.id}` +
      `&lastchatid=${lastChatId}` +
      `&onlymembers=1`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.length != 0 && response.lastchatid != lastChatId) {
          setlastChatId(response.lastchatid);

          response.data.map(function (chatData) {
            setChatDetails((chatDetails) => [...chatDetails, chatData]);
          });
        }
      }
    } catch (error) {}
  };
  /*------ end of cron function runs when new chat will come -------*/

  // function for get props data via groupchat board
  const groupReplyChat = (data) => {
    setHideReplyBox(false);
    setChatParentMessageInfo(data);
  };

  //function for reset search chat query
  const resetChatQuery = () => {
    setSearchWarning(false);
    setSearchChatQuery("");
  };

  // function for search chat by query
  const searchChatByQuery = () => {
    if (searchChatQuery == "") {
      setSearchWarning(true);
    } else {
      setSearchWarning(false);
      getChatDetails();
      toggleBlockById("chatsearch_pnl");
      resetChatQuery();
    }
  };

  // function for refresh chat
  const refreshChatMail = () => {
    getChatDetails();
  };

  // function for hiding reply box
  const onConfirmReply = () => {
    setHideReplyBox(true);
  };

  // function for passing tag via props
  const getTagId = (data) => {
    props.getTags(data);
  };

  // function for add tag modal via props
  const sendTag = () => {
    props.tagFromGroup(props.chatInfo.id);
  };

  // function for refresh chat from
  const refreshGroupChat = () => {
    getChatDetails();
    fetchGroupChatSelected();
  };

  // use effect for group right panel reload
  useEffect(() => {
    if (props.loadRightPanel) {
      refreshGroupChat();
      props.stopLoadRightPanel();
    }
  }, [props.loadRightPanel]);

  /*----- useEffect runs on props and last chatId dependency ------*/
  useEffect(() => {
    // set previous chat data
    fetchGroupChatSelected();
    getChatDetails();
  }, [props.chatInfo]);

  useEffect(() => {
    if (localStorage.getItem("contactcronid")) {
      clearInterval(localStorage.getItem("contactcronid"));
      localStorage.removeItem("contactcronid");
    }
    /*---- clear previous cron ----*/

    if (localStorage.getItem("groupcronid")) {
      clearInterval(cronId);
      setCronId(null);
    }

    /*----- start cron job -----*/
    let intervalId = setInterval(() => {
      cronJob();
    }, 5000);

    if (intervalId) {
      localStorage.setItem("groupcronid", intervalId);
      setCronId(intervalId);
    }
  }, [props.chatInfo, lastChatId]);

  return (
    <>
      {props.chatInfo ? (
        <>
          {/* -------------- Group Top Panel content starts here -------------- */}
          <div className="chat_topPnl">
            {/* -------- top menu bar containing the user profile image and other links -------- */}
            {isHeaderLoading ? (
              <div className="chatusr_info">
                <div className="grp_userHead placeholder-glow">
                  <div className="user_placeHoledr">
                    <div className="default_usr">
                      <img src={defaultUser} alt="" />
                    </div>
                    <div className="default_usr_name">
                      <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="chatusr_info">
                {groupChatData ? (
                  <>
                    {/* ---- unknown back button section --- */}
                    <div className="list_back">
                      <Link
                        to="#"
                        onClick={() => {
                          document.body.classList.remove("chatmsg_open");
                        }}
                      >
                        <i className="material-icons-round">arrow_back</i>
                      </Link>
                    </div>
                    {/* ---- end of unknown back button section --- */}

                    {/* ---- start of group details section --- */}
                    <Link
                      to="#"
                      className="chat_usrOuter profile_linkBtn"
                      onClick={() => {
                        document.body.classList.toggle("profile_menuPnl_open");
                      }}
                    >
                      {groupChatData.members.length == 0 ? (
                        <div className="chatting_user">
                          <i className="material-icons-round">people</i>
                        </div>
                      ) : (
                        <div className="chatting_user">
                          {groupChatData.members
                            .slice(0, 3)
                            .map((item, index) => {
                              return (
                                <figure className="group_usr" key={index}>
                                  {item.image == null ? (
                                    <i className="material-icons-round">
                                      person
                                    </i>
                                  ) : (
                                    <img src={item.image} alt="image" />
                                  )}
                                </figure>
                              );
                            })}

                          {groupChatData.members.length <= 3 ? null : (
                            <span className="group_usr plus_usr">
                              {groupChatData.memberscount - 3}
                              <em>+</em>
                            </span>
                          )}
                        </div>
                      )}

                      <div className="chat_usrtext">
                        <h3>{groupChatData.label.slice(0, 25)}</h3>
                        <h5>
                          {groupChatData.memberscount} {t("Members")}
                        </h5>
                      </div>
                    </Link>
                    {/* ---- end of group details section --- */}

                    {/* ----------------- External Chat Info component ----------------- */}
                    <ChatInfo
                      resetChatQuery={resetChatQuery}
                      refreshChatMail={refreshChatMail}
                      sendTag={sendTag}
                    />
                  </>
                ) : (
                  <div className="grp_userHead placeholder-glow">
                    <div className="user_placeHoledr">
                      <div className="default_usr">
                        <img src={defaultUser} alt="" />
                      </div>
                      <div className="default_usr_name">
                        <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                        <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* ------------ search bar for current chat */}
            <div className="serch_pnl" id="chatsearch_pnl">
              <div className="srch_innrFill">
                <input
                  type="text"
                  value={searchChatQuery}
                  className="form-control"
                  placeholder="Search for conversation"
                  onChange={(e) => setSearchChatQuery(e.target.value)}
                />
                <button className="srch_submitBtn" onClick={searchChatByQuery}>
                  <i className="material-icons-round">send</i>
                </button>
              </div>
              {searchWarning && (
                <p className="text-warning mx-2">* Enter search query</p>
              )}
            </div>
          </div>
          {/* -------------- Group Top Panel content starts here -------------- */}
          {/* ---- end of tabbar for conditionaly rendering components --- */}

          {/* -------------- Group Middle Panel content starts here -------------- */}
          <GroupChatBoard
            chatInfo={props.chatInfo}
            chatDetails={chatDetails}
            groupReplyChat={groupReplyChat}
            getTag={getTagId}
            hideReplyBox={hideReplyBox}
            chatIsLoading={chatIsLoading}
            refreshGroupChat={refreshGroupChat}
          />
          {/* -------------- Group Middle Panel content starts here -------------- */}

          <ChatBox
            groupMembers={props.chatInfo.members}
            groupId={props.chatInfo.id}
            messageDetails={messageDetails}
            chatParentMessageInfo={chatParentMessageInfo}
            onConfirmReply={onConfirmReply}
          />

          {/* --------- User profile panel that pops up from the right hand side --------- */}
          <GroupRightProfilePanel groupInfo={groupChatData} />
        </>
      ) : null}
    </>
  );
};

export default GroupRightPanel;
