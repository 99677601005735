/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */
// importing context API here
import { GlobalIndraInfo } from "App";
//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import ThanksReviewChallengeModal from "../Modals/ThanksReviewChallengeModal";

const Review = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const glowCount = [1, 2]; //for glow placeholder

  // get info from context
  const {
    subscriberStatus,
    joinIsLoading,
    joinNowConfirm,
    isModeratorApproved,
    isJobPoster,
    setReloadChallengeDetails,
  } = useContext(GlobalIndraInfo);

  // 👇️ get ID from url
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState(1);

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);

  // function to display the star ratings
  const highlightStars = (e, value) => {
    e.preventDefault();

    if (value == 1) {
      setActiveFirstStar(true);
      setActiveSecondStar(false);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 2) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 3) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 4) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(false);
    }

    if (value == 5) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };
  /* ------------ Initial useStates for activating the starts ends here ------------ */

  // Intitial useState to save all the review from the API
  const [reviewsList, setReviewsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to submit the review to the API.
  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    setCommentError(false);
    setRatingError(false);
    if (comments == "" || starRating == 0) {
      if (comments == "") {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
      if (starRating == 0) {
        setRatingError(true);
      } else {
        setRatingError(false);
      }
    } else {
      setIsSubmitting(true);
      let requestURL =
        url.API_BASE_URL + url.API_COURSE_RATING + `?token=${token}`;

      let reviewData = {
        mod: "MOD_OFFER",
        com: params.id,
        comments: comments,
        ratings: starRating,
      };

      try {
        const response = await postFormdata(requestURL, reviewData);

        if (response.status) {
          setIsSubmitting(false);
          resetHandler();
          let myModal = new bootstrap.Modal(
            document.getElementById("challenge_review_success_modal")
          );
          myModal.show();
        }
      } catch (error) {}
    }
  };

  // function for calling API after closing thank you modal
  const afterThanksReviewClose = () => {
    getAllReviews(params.id);
    setReloadChallengeDetails(true);
  };

  // Function to get all the review data from API
  const getAllReviews = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_COURSE_VIEW_REVIEWS +
      `?token=${token}` +
      `&mod=MOD_OFFER` +
      `&com=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setReviewsList(response.data);
        } else {
          setReviewsList([]);
        }
      } else {
        setReviewsList([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  // Function to select the star ratings from the stars
  const selectedStar = (e, star) => {
    e.preventDefault();
    setstarRating(star);
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments("");
  };

  useEffect(() => {
    getAllReviews(params.id);
  }, [params.id]);

  return (
    <>
      <div className="tab-pane show active fade" id="review">
        {isLoading ? (
          <div className="course_detailspnl">
            {/* <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "21px" }}
              ></span>
            </div> */}
            <div className="form_commnts_area commnts_newbx">
              <div className="course_rvw_row">
                <span
                  className="placeholder rounded-3 col-1 me-3"
                  style={{ height: "20px" }}
                ></span>
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "20px" }}
                ></span>
              </div>
              <div className="commnts_send_innr">
                <span
                  className="placeholder rounded-3 col-2 mb-3"
                  style={{ height: "21px" }}
                ></span>
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "117px" }}
                ></span>
              </div>
            </div>
            <div className="review_bxOuter">
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-6" key={count}>
                      <div className="review_bxItem">
                        <div className="forum_cmntsRow">
                          <div className="team_userOuter forum_usrOuter">
                            <div className="team_usr_pic">
                              <span
                                className="placeholder rounded-circle"
                                style={{ width: "50px", height: "50px" }}
                              ></span>
                            </div>
                            <div className="team_usrTxt flex-fill">
                              <div className="d-flex justify-content-between mb-4">
                                <div className="col-6 p-0">
                                  <span
                                    className="placeholder d-block rounded-3 col-8 mb-2"
                                    style={{ height: "25px" }}
                                  ></span>
                                  <span
                                    className="placeholder d-block rounded-3 col-6 mb-2"
                                    style={{ height: "15px" }}
                                  ></span>
                                  <span
                                    className="placeholder d-block rounded-3 col-6"
                                    style={{ height: "20px" }}
                                  ></span>
                                </div>
                                <div className="col-6 p-0">
                                  <span className="placeholder placeholder-sm rounded-3 col-6 ms-auto"></span>
                                </div>
                              </div>
                              <div className="forum_para mb-4">
                                <span className="placeholder placeholder-sm rounded-3 col-12 mb-3"></span>
                                <span className="placeholder placeholder-sm rounded-3 col-8 mb-3"></span>
                                <span className="placeholder placeholder-sm rounded-3 col-4"></span>
                              </div>
                              {/* <div className="form_share">
                            <span
                              className="placeholder rounded-3 me-2"
                              style={{ width: "97px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3 me-2"
                              style={{ width: "123px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3"
                              style={{ width: "38px", height: "38px" }}
                            ></span>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="course_detailspnl">
            {/* <div className="contnt_hdng tab_Hdng">
              <h4>Review</h4>
            </div> */}

            {/* ------- Star rating & Review section starts here ------- */}

            {isModeratorApproved || isJobPoster ? (
              <div className="form_commnts_area commnts_newbx">
                {/* ------- Star rating section starts here ------- */}
                <div className="course_rvw_row">
                  <div className="rating_hdng">
                    <h5>{t("Give Ratings")}</h5>
                  </div>
                  <ul>
                    {/* ----------- 1 Star ----------- */}
                    <li
                      className={activeFirstStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 1);
                        highlightStars(e, 1);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 2 Star ----------- */}
                    <li
                      className={activeSecondStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 2);
                        highlightStars(e, 2);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 3 Star ----------- */}
                    <li
                      className={activeThirdStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 3);
                        highlightStars(e, 3);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 4 Star ----------- */}
                    <li
                      className={activeFourthStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 4);
                        highlightStars(e, 4);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 5 Star ----------- */}
                    <li
                      className={activeFiveStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 5);
                        highlightStars(e, 5);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                {ratingError && (
                  <p className="text-danger mb-3">
                    * {t("Please give rating")}
                  </p>
                )}
                {/* ------- Star rating section ends here ------- */}

                {/* ------- Comments section starts here ------- */}
                <div className="commnts_send_innr">
                  <div className="rating_hdng">
                    <h5>{t("Give Comments")}</h5>
                  </div>
                  <div className="commnts_sendArea">
                    <textarea
                      className="form-control"
                      value={comments != "" ? comments : ""}
                      onChange={(e) => setComments(e.target.value)}
                      placeholder={t("Write your comments here")}
                    ></textarea>
                    <div className="furmcmnt_btn">
                      {/* <button className="cmnt_btn">
                    <span className="material-icons-outlined">image</span>
                  </button> */}
                      <button
                        className="cmnt_btn"
                        disabled={isSubmitting ? true : false}
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "pointer",
                        }}
                        onClick={(e) => reviewSubmitHandler(e)}
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                  {commentError && (
                    <p className="text-danger mt-2">
                      * {t("Please add comment")}
                    </p>
                  )}
                </div>
                {/* ------- Comments section ends here ------- */}
              </div>
            ) : null}

            {/* ------- Star rating & Review section ends here ------- */}

            {/* ------- All reviews list section starts here ------- */}
            <div className="review_bxOuter">
              {reviewsList.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={blankLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>{t("Sorry....!")}</h2>
                        <p>{t("Reviews are not available for this job.")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {reviewsList.map((review, index) => {
                    return (
                      <div className="col-lg-6" key={review.id}>
                        <div className="review_bxItem">
                          <div className="forum_cmntsRow">
                            <div className="team_userOuter forum_usrOuter">
                              {/* -------- User image section starts here -------- */}
                              <div className="team_usr_pic">
                                <img
                                  src={
                                    review.userimage != ""
                                      ? review.userimage
                                      : ""
                                  }
                                  alt="UserProfilePic"
                                />
                              </div>
                              {/* -------- User image section ends here -------- */}

                              {/* -------- User details & comments section starts here -------- */}
                              <div className="team_usrTxt">
                                <div className="time_text">
                                  <h5>{review.created}</h5>
                                </div>
                                <h3>{review.user}</h3>
                                <h6>{review.useremail}</h6>
                                <div className="comnt_rating cstomer_tvw">
                                  <ul>
                                    {_.times(review.globalrating, (i) => (
                                      <li className="active_star" key={i}>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            star
                                          </i>
                                        </Link>
                                      </li>
                                    ))}
                                    {/* {_.times(5 - review.globalrating, (i) => (
                                    <li key={i}>
                                      <Link to="#">
                                        <i className="material-icons-outlined">
                                          star
                                        </i>
                                      </Link>
                                    </li>
                                  ))} */}
                                  </ul>
                                </div>
                                <div className="forum_para">
                                  <p>{review.comment}</p>
                                </div>

                                {/* ------- Like & comment button section starts here ------- */}
                                {/* <div className="form_share">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>Likes</em>
                                  <span>2</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    chat{" "}
                                  </i>
                                  <em>Comment</em>
                                  <span>3</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    share{" "}
                                  </i>
                                </Link>
                              </div> */}
                                {/* ------- Like & comment button section endss here ------- */}
                              </div>
                              {/* -------- User details & comments section ends here -------- */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {/* ------- All reviews list section ends here ------- */}
          </div>
        )}
      </div>
      <ThanksReviewChallengeModal
        afterThanksReviewClose={afterThanksReviewClose}
      />
    </>
  );
};

export default Review;
