/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// importing context API here
import { GlobalIndraInfo } from "App";

// importing custom components here
import AddTagsModal from "components/ChallengesDetailsComponents/Modals/AddTagsModal";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// importing custom components here
import ApplyNowModal from "./Modals/ApplyNowModal";

// import custom images here
import Google from "assets/ChallegesDetailsAsstes/google.png";
import ApplySuccessModal from "./Modals/ApplySuccessModal";
import { t } from "i18next";

const ChallengesDetailsTopSection = () => {
  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    setChallengeName,
    setReloadChallengeDetails,
    reloadChallengeDetails,
    setIsModeratorApproved,
    setIsJobPoster,
    afterReviewJob,
    setAfterReviewJob,
  } = useContext(GlobalIndraInfo);

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);
  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL + url.API_VIEW_JOB_DETAILS + id + `?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.length === 0) {
            setJobDetailsData(null);
          } else {
            setJobDetailsData(response.data);
            setChallengeName(response.data.name);
            if (response.data.moderatorstatus) {
              setIsModeratorApproved(true);
            } else {
              setIsModeratorApproved(false);
            }
            if (response.data.owner.id === userInfo.id) {
              setIsJobPoster(true);
            } else {
              setIsJobPoster(false);
            }
            if (response.data.members && response.data.members.length > 0) {
              let memberFilter = response.data.members.find((member) => {
                return member.id === userInfo.id;
              });

              if (memberFilter) {
                if (memberFilter.id == userInfo.id) {
                  setIsTeamMember(true);
                  setIsJobPoster(true);
                } else {
                  setIsTeamMember(false);
                  setIsJobPoster(false);
                }
              }
            }
          }
        } else {
          setJobDetailsData(null);
        }
        setIsLoading(false);
      } else {
        setJobDetailsData(null);
      }
    } catch (error) {}
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getJobDetailsData(params.id);
  };

  useEffect(() => {
    if (reloadChallengeDetails) {
      getJobDetailsData(params.id);
      setReloadChallengeDetails(false);
    }
  }, [reloadChallengeDetails]);

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="course_details_sec placeholder-glow">
          <div className="container">
            <div className="course_detailsBx">
              {/* --------------------------- Left Section --------------------------- */}
              <div className="topdetails_left">
                <div className="global_details_outer">
                  <div className="glbl_detailstext">
                    <div className="challenge_iconOuter mb-3">
                      <span
                        className="placeholder rounded-circle me-2"
                        style={{ width: "20px", height: "20px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "45px" }}
                      ></span>
                    </div>
                    <div className="details_usrName_hdng mb-3">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "330px", height: "26px" }}
                      ></span>
                    </div>
                    <span
                      className="placeholder rounded-3 mb-3"
                      style={{ width: "170px", height: "38px" }}
                    ></span>
                    <div className="d-flex">
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "142px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "75px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "95px" }}
                      ></span>
                    </div>
                    <div className="hire_btn">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "132px", height: "50px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* --------------------------- Right Section --------------------------- */}
              <div className="topdetails_right d-flex flex-column align-items-end justify-content-between">
                <div className="details_shareOuter">
                  <div className="d-flex align-items-center">
                    <span
                      className="placeholder placeholder-sm rounded-3 me-3"
                      style={{ width: "60px" }}
                    ></span>
                    <span
                      className="placeholder placeholder-sm rounded-3 me-3"
                      style={{ width: "110px" }}
                    ></span>
                    <span
                      className="placeholder placeholder-sm rounded-3 me-3"
                      style={{ width: "100px" }}
                    ></span>
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="course_details_sec">
          {jobDetailsData == null ? (
            <div className="container">
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="global_details_outer">
                    <div className="glbl_detailstext">
                      <div className="challenge_iconOuter mb-3">
                        <span
                          className="placeholder rounded-circle me-2"
                          style={{ width: "20px", height: "20px" }}
                        ></span>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "45px" }}
                        ></span>
                      </div>
                      <div className="details_usrName_hdng mb-3">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "330px", height: "26px" }}
                        ></span>
                      </div>
                      <span
                        className="placeholder rounded-3 mb-3"
                        style={{ width: "170px", height: "38px" }}
                      ></span>
                      <div className="d-flex">
                        <span
                          className="placeholder placeholder-sm rounded-3 me-3"
                          style={{ width: "142px" }}
                        ></span>
                        <span
                          className="placeholder placeholder-sm rounded-3 me-3"
                          style={{ width: "75px" }}
                        ></span>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "95px" }}
                        ></span>
                      </div>
                      <div className="hire_btn">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "132px", height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right d-flex flex-column align-items-end justify-content-between">
                  <div className="details_shareOuter">
                    <div className="d-flex align-items-center">
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "60px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "110px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "100px" }}
                      ></span>
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="global_details_outer">
                    <div className="glbl_detailstext ">
                      {/* ---------- Comapny name & logo section ---------- */}
                      <div className="challenge_iconOuter">
                        {/* <i className="challenge_icon">
                          <img src={Google} alt="" />
                        </i>{" "} */}
                        <i className="material-icons-outlined text-white">
                          business
                        </i>{" "}
                        <span className="challenge_icon_text mx-1">
                          {jobDetailsData.company != "" ||
                          jobDetailsData.company != null
                            ? jobDetailsData.company
                            : ""}
                        </span>
                      </div>

                      {/* ---------- Job Type section ---------- */}
                      <div className="details_usrName_hdng">
                        <h3>
                          {jobDetailsData.name != null ||
                          jobDetailsData.name != ""
                            ? jobDetailsData.name
                            : ""}
                        </h3>
                      </div>

                      {/* ---------- Price section ---------- */}
                      <div className="details_value">
                        <h4>
                          €{" "}
                          {jobDetailsData.price != null
                            ? jobDetailsData.price
                            : "private"}
                        </h4>
                      </div>

                      {/* ---------- Star rating, Location, Enrolled section ---------- */}
                      <div className="details_tags expert_details">
                        {/* ------- Ratings & Reviews ------- */}
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined">star</i>
                          <span>{jobDetailsData.rating}</span>
                          <h6>
                            ({jobDetailsData.reviews} <em>{t("reviews")}</em>)
                          </h6>
                        </div>

                        {/* ------- Location ------- */}
                        <div className="course_text">
                          <i className="material-icons-outlined">place </i>
                          <h4>
                            {jobDetailsData.location != null ||
                            jobDetailsData.location != ""
                              ? jobDetailsData.location
                              : "undisclosed"}
                          </h4>
                        </div>

                        {/* ------- Experience ------- */}
                        <div className="course_text">
                          <i className="material-icons-outlined">people</i>
                          {jobDetailsData.responsecount != null ||
                          jobDetailsData.responsecount != "" ? (
                            <h4>
                              {jobDetailsData.responsecount}
                              <span className="mx-1">{t("Applied")}</span>
                            </h4>
                          ) : (
                            <h4>undisclosed</h4>
                          )}
                        </div>
                      </div>

                      {/* ---------- Tags section ---------- */}
                      <div className="experts_holdertext">
                        {jobDetailsData.skills != null ? (
                          <div className="experts_skill">
                            {jobDetailsData.skills.length > 0 ? (
                              <ul>
                                {jobDetailsData.skills
                                  .slice(0, 5)
                                  .map((skill, index) => {
                                    return (
                                      <li key={index}>
                                        <Link to="#">
                                          <span>{skill.label}</span>
                                        </Link>
                                      </li>
                                    );
                                  })}
                                {jobDetailsData.skills.length > 5 ? (
                                  <li className="challenge_more_text">
                                    + {jobDetailsData.skills.length - 5}{" "}
                                    {t("more")}
                                  </li>
                                ) : null}
                              </ul>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right d-flex flex-column align-items-end justify-content-between">
                  <div className="details_shareOuter challenge_share_section">
                    {/* ------- Job Type & Response Count ------- */}
                    {jobDetailsData.owner.id === userInfo.id ? null : (
                      <div className="details_prgrss">
                        <h4>
                          {jobDetailsData.jobtype != null
                            ? jobDetailsData.jobtype
                            : " undisclosed"}
                        </h4>
                        <div className="tags_prgrsspnl">
                          {jobDetailsData.experience ? (
                            <h5>
                              {jobDetailsData.experience}+ {t("Experience")}
                            </h5>
                          ) : (
                            <h5>Undisclosed</h5>
                          )}
                          {/* <div className="circle_progess_outer">
                          <span
                            className="circle_progress_bar"
                            style={{ width: "40%" }}
                          ></span>
                        </div> */}
                        </div>
                      </div>
                    )}

                    {/* ------- Share Button ------- */}
                    <div className="exprt_share social_share">
                      <ul>
                        {/* ------------ Tags Modal link from here ------------ */}
                        <li>
                          {/* <Link to="#" onClick={(e) => triggerAddTagsModal(e)}> */}
                          <Link
                            to="#"
                            className="basic_btn activty_filter"
                            data-bs-toggle="modal"
                            data-bs-target="#addtag_job_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>
                        {/* ------------ Tags Modal link till here ------------ */}

                        {/* <li>
                          <Link to="#">
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}
                    {jobDetailsData.tags.length > 0 ? (
                      <ul className="tags_list">
                        {jobDetailsData.tags.slice(0, 3).map((tags, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{tags.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {jobDetailsData.tags.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {jobDetailsData.tags.length > 0 ? (
                          <ul className="dropdown-menu">
                            {jobDetailsData.tags
                              .slice(3, jobDetailsData.tags.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.label}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}
                  {jobDetailsData.applydate && (
                    <p className="text-end text-white join-event-request mt-1">
                      {t("Applied On")}:{jobDetailsData.applydate}
                    </p>
                  )}

                  {jobDetailsData.owner.id == userInfo.id && (
                    <p className="text-end text-white join-event-request mt-1">
                      {t("Role")}: {t("Moderator")}
                    </p>
                  )}

                  {jobDetailsData.owner.id != userInfo.id && isTeamMember ? (
                    <p className="text-end text-white join-event-request mt-1">
                      {t("Role")}: {t("Member")}
                    </p>
                  ) : null}

                  {/* ---------- 'Apply Now' button section ---------- */}
                  {jobDetailsData.owner.id == userInfo.id ||
                  isTeamMember ? null : (
                    <div className="hire_btn">
                      {jobDetailsData.isapplied ? (
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#job_apply_modal"
                        >
                          {t("View Application")}
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#job_apply_modal"
                        >
                          {t("Apply Now")}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
      )}

      {/* importing the Add Tags Modal here */}
      <AddTagsModal afterModalClose={afterModalClose} />

      {/* Apply now modal is added here */}
      {jobDetailsData != null ? (
        <ApplyNowModal
          companyname={jobDetailsData.company}
          jobname={jobDetailsData.name}
          joblocation={jobDetailsData.location}
          jobdate={jobDetailsData.created}
          jobtype={jobDetailsData.jobtype}
          isApplied={jobDetailsData.isapplied}
        />
      ) : null}
      <ApplySuccessModal />
    </>
  );
};

export default ChallengesDetailsTopSection;
