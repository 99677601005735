/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

// import images assests here
import userDefault from "assets/CourseDetailsAssests/images/default-user.png";

// importing custom components here
import AddTagsModal from "components/CourseDetailsComponents/Modals/AddTagsModal";
import ShareModal from "components/CourseDetailsComponents/Modals/ShareModal";
import JoinCourseModal from "components/CourseDetailsComponents/Modals/JoinCourseModal";
/* -------------------------- required imports for API Call -------------------------- */

// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

const CourseDetailsTopSection = () => {
  let { t } = useTranslation();

  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // importing useContext functions here
  const {
    joinNow,
    joinNowConfirm,
    joinNowLoading,
    setSubscriberStatus,
    lessonComplete,
    completeLesson,
    setReceivedCourseName,
    setIsCourseUploader,
    setLessonTabReload,
    afterReviewReload,
    setAfterReviewReload,
  } = useContext(GlobalIndraInfo);

  // get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [courseData, setCourseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [joinCourseLoading, setJoinCourseLoading] = useState(false);
  const [modalAdminMessage, setModalAdminMessage] = useState("");

  const getCourseData = async () => {
    let requestURL =
      url.API_BASE_URL + url.API_VIEW_COURSE + `/${params.id}?token=${token}`;

    console.log(requestURL);

    setIsLoading(true);

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setCourseData(response.data);
          setReceivedCourseName(response.data.title);
          if (response.data.moderatorid) {
            if (
              response.data.moderatorid.toString() ===
                userInfo._id.toString() ||
              response.data.userismember
            ) {
              setIsCourseUploader(true);
            } else {
              setIsCourseUploader(false);
            }
          }

          if (response.data.isapproved && response.data.isjoined) {
            setSubscriberStatus(true);
          } else {
            setSubscriberStatus(false);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getCourseData();
  };

  // function for join course
  const joinCourseHandler = async () => {
    try {
      const todayValue = new Date().toISOString().split("T")[0];

      setJoinCourseLoading(true);

      joinNowLoading(true);

      let courseJoinData = {
        user: userInfo._id,
        course: params.id,
        company: null,
        role: null,
        useremail: userInfo.email,
        memberstatus: "1",
        requestdate: todayValue,
      };

      console.log("Join course Data------>", courseJoinData);

      let requestURL =
        url.API_BASE_URL + url.API_JOIN_COURSE + `?token=${token}`;

      const response = await postData(requestURL, courseJoinData);

      console.log(response);

      setJoinCourseLoading(false);
      joinNowLoading(false);

      if (response.status) {
        if (response.data.thanksmessage !== "") {
          setModalAdminMessage(response.data.thanksmessage);
        }
        let myModal = new bootstrap.Modal(
          document.getElementById("join_success_modal")
        );
        myModal.show();
      }

      setLessonTabReload(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  // use effect for join course from lesson tab
  useEffect(() => {
    if (joinNow) {
      joinCourseHandler();
      joinNowConfirm(false);
    }
  }, [joinNow]);

  useEffect(() => {
    if (lessonComplete) {
      getCourseData();
      completeLesson(false);
    }
  }, [lessonComplete]);

  useEffect(() => {
    if (afterReviewReload) {
      getCourseData();
      setAfterReviewReload(false);
    }
  }, [afterReviewReload]);

  // useEffect from here
  useEffect(() => {
    if (params.id) {
      getCourseData();
    }
  }, [params.id]);

  // return from here
  return (
    <>
      {isLoading ? (
        <section className="course_details_sec">
          <div className="container">
            <div className="course_detailsBx placeholder-glow">
              <div className="topdetails_left">
                <div className="expertholder_outer">
                  <div className="experts_holdertext w_100">
                    <span className="placeholder placeholder-sm col-4 rounded-3 mb-20 white_glow"></span>
                    <span className="placeholder placeholder-sm col-6 rounded-3 mb-20 white_glow"></span>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-3 rounded-3 white_glow"></span>
                    </p>

                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-12 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-4 rounded-3 white_glow"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="topdetails_right">
                <p className="data_btnOuter mb-20">
                  <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                  <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                  <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="course_details_sec">
          {courseData == null ? (
            <div className="container">
              <div className="course_detailsBx placeholder-glow">
                <div className="topdetails_left">
                  <div className="expertholder_outer">
                    <div className="experts_holdertext w_100">
                      <span className="placeholder placeholder-sm col-4 rounded-3 mb-20 white_glow"></span>
                      <span className="placeholder placeholder-sm col-6 rounded-3 mb-20 white_glow"></span>
                      <p className="data_btnOuter mb-20">
                        <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                        <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                        <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                      </p>
                      <p className="data_btnOuter mb-20">
                        <span className="placeholder placeholder-sm col-1 rounded-3 ml-0 white_glow"></span>
                        <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                      </p>
                      <p className="data_btnOuter mb-20">
                        <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                        <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                        <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                      </p>
                      <p className="data_btnOuter mb-20">
                        <span className="placeholder placeholder-sm col-1 rounded-3 ml-0 white_glow"></span>
                        <span className="placeholder placeholder-sm col-3 rounded-3 white_glow"></span>
                      </p>

                      <p className="data_btnOuter mb-20">
                        <span className="placeholder placeholder-sm col-12 rounded-3 ml-0 white_glow"></span>
                        <span className="placeholder placeholder-sm col-10 rounded-3 white_glow"></span>
                      </p>
                      <p className="data_btnOuter mb-20">
                        <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                        <span className="placeholder placeholder-sm col-4 rounded-3 white_glow"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="topdetails_right">
                  <p className="data_btnOuter mb-20">
                    <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                    <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                    <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="expertholder_outer">
                    <div className="experts_holdertext">
                      <h5>
                        {t("Offered By:")} {courseData.companyname ?? ""}
                      </h5>
                      <h3>{courseData.title}</h3>
                      <div className="details_tags expert_details">
                        {/* -------- Lesson Star rating -------- */}
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined"> star </i>
                          <span>{courseData.totalratings}</span>
                          <h6>
                            ({courseData.totalreviews} <em>{t("reviews")}</em>)
                          </h6>
                        </div>

                        {/* -------- Lesson Lesson Count -------- */}
                        <div className="course_text">
                          <h4>
                            {courseData.lessoncount ?? ""}

                            <em className="ms-1">{t("lessons")}</em>
                          </h4>
                        </div>

                        {/* -------- COurse Language -------- */}
                        <div className="course_duration">
                          <h5>
                            <i className="material-icons">language</i>{" "}
                            <span>
                              {courseData.courselanguage?.toUpperCase()}
                            </span>
                          </h5>
                        </div>
                      </div>

                      {/* --------- Course Categories -------------------- */}

                      {courseData.categories &&
                      courseData.categories?.length > 0 ? (
                        <div className="experts_skill mt-2">
                          <ul>
                            {courseData.categories?.map((category, index) => {
                              return (
                                <li key={category.id}>
                                  <Link to="#">{category.name}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}

                      {/* -------- Lesson Instructor Details -------- */}
                      {courseData.memberlist &&
                      courseData.memberlist?.length > 0 ? (
                        <div className="traning_user">
                          <label>{t("Mentors")} </label>
                          {courseData.memberlist?.map((member, index) => {
                            return (
                              <div
                                className="userexprt_pnltxt me-2 ms-2"
                                key={index}
                              >
                                <div className="expert_usr">
                                  <img
                                    src={
                                      member.image !== ""
                                        ? url.API_SERVER_URL + member.image
                                        : userDefault
                                    }
                                    alt="member"
                                  />
                                </div>
                                <div className="exprt_text">
                                  <h5>{member.name}</h5>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {/* -------- Lesson Subtitle -------- */}
                      <div className="traning_intermetiate_outer">
                        <div className="training_para">
                          <p>{courseData.subtitle ?? ""}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right">
                  <div className="asign_top">
                    {/* -------- Lesson Company Name -------- */}
                    {/* <div className="asign_outer">
                      <div className="asign_item ">
                        {courseData.tags.length > 0 ? (
                          <ul className="text-white">
                            {courseData.tags.map((tags, index) => {
                              return <li key={index}>{tags.label}</li>;
                            })}
                          </ul>
                        ) : null}
                      </div>
                    </div> */}

                    {/* --------- Add Tags Modal & Share Modal Links --------- */}
                    <div className="exprt_share traning_share">
                      <ul>
                        {/* --------- Link to activate Tags Modal --------- */}
                        <li>
                          {/* <Link to="#" onClick={(e) => triggerAddTagsModal(e)}> */}
                          <Link
                            to="#"
                            className="basic_btn activty_filter"
                            data-bs-toggle="modal"
                            data-bs-target="#addtag_course_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>

                        {/* --------- Link to activate Share Modal --------- */}
                        {/* <li>
                          <Link
                            to="#"
                            className="basic_btn activty_filter"
                            data-bs-toggle="modal"
                            data-bs-target="#share_modal"
                          >
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}
                    {courseData.taglist?.length > 0 ? (
                      <ul className="tags_list">
                        {courseData.taglist?.slice(0, 3).map((tag, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{tag.title}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {courseData.taglist?.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {courseData.taglist?.length > 0 ? (
                          <ul className="dropdown-menu">
                            {courseData.tags
                              ?.slice(3, courseData.taglist?.length)
                              .map((tag, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tag.title}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}

                  {/*---- join date ----*/}

                  {courseData.usercourserole !== "" ? (
                    <div className="traning_intermetiate_outer">
                      <div className="training_para">
                        <p className="text-end text-white join-event-request mt-1">
                          {t("Role")}: {courseData.usercourserole ?? ""}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="traning_intermetiate_outer">
                      {courseData.isjoined ? (
                        <div className="training_para">
                          {courseData.isapproved ? (
                            <p>
                              {t("Joining Date")}:{" "}
                              {courseData.subscribedate
                                ? courseData.subscribedate
                                : ""}
                              {/* <span className="ms-2">
                                {t("Score")}: {courseData.coursescore ?? ""}
                              </span> */}
                            </p>
                          ) : (
                            <div className="training_para">
                              <p>
                                {t("Request Date")}: {courseData.subscribedate}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  )}

                  {/* -------- Lesson Progress Bar -------- */}
                  {courseData.usercourserole !== "" ? null : (
                    <div className="traning_intermetiate_outer">
                      {courseData.isjoined ? (
                        <div className="training_para">
                          {courseData.isapproved ? (
                            <div className="course_progress_details">
                              <div className="progess_outer">
                                <span
                                  className="progress_bar"
                                  style={{
                                    width: `${courseData.coursecompletion}%`,
                                  }}
                                ></span>
                              </div>
                              <div className="percentage">
                                <span>{courseData.coursecompletion}%</span>
                                {t("Completed")}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div
                          className="hire_btn"
                          style={{
                            cursor: joinCourseLoading ? "not-allowed" : "",
                          }}
                        >
                          <Link
                            to="#"
                            className="basic_btn ylw_btn"
                            onClick={joinCourseHandler}
                            style={{
                              pointerEvents: joinCourseLoading ? "none" : "",
                            }}
                          >
                            {t("Join Now")}
                            {joinCourseLoading && (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* importing the Add Tags Modal here */}
          <AddTagsModal afterModalClose={afterModalClose} />

          {/* importing the Share Modal here */}
          <ShareModal />

          <JoinCourseModal
            modalAdminMessage={modalAdminMessage}
            afterModalClose={afterModalClose}
          />
        </section>
      )}
    </>
  );
};

export default CourseDetailsTopSection;
