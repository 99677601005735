/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import images here
import certificate from "assets/CourseDetailsAssests/images/certificate_frame.png";
import blankVideo from "assets/CourseDetailsAssests/images/course-vdo-right-blank.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import certStamp from "assets/CourseDetailsAssests/images/certificate_stamp.png";
import signedCopy from "assets/CourseDetailsAssests/images/signed-copy.png";

// importing context API here
import { GlobalIndraInfo } from "App";

const TrainingCertificate = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const createPostPermission = userInfo.privilege.PRV_ACTIVITY_CREATE;

  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // get info from context
  const { subscriberStatus, isCourseUploader } = useContext(GlobalIndraInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [joinedDate, setJoinedDate] = useState("");
  const [certDate, setCertDate] = useState("");
  const [score, setScore] = useState("");
  const [courseName, setCourseName] = useState("");
  const [certiFication, setCertiFication] = useState(false);
  const [totalScore, setTotalScore] = useState(null);
  const [academyCertification, setAcademyCertification] = useState(false);
  const [certificateName, setCertificateName] = useState("");
  const [isModerator, setIsModerator] = useState(false);
  const [isTraining, setIsTraining] = useState(false);

  // function for get course about
  const getCourseAbout = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_VIEW_COURSE_DETAILS +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          /*----- assign certification status ------*/
          if (response.data.coursecertification) {
            setCertiFication(true);
          } else {
            setCertiFication(false);
          }

          /*----- assign academy certification status ------*/
          if (response.data.academycertification) {
            setAcademyCertification(true);
          } else {
            setAcademyCertification(false);
          }

          /*----- assign certification name ------*/
          if (
            response.data.certificate != "" ||
            response.data.certificate != null
          ) {
            setCertificateName(response.data.certificate);
          } else {
            setCertificateName("");
          }

          /*----- assign certification date ------*/
          if (
            response.data.coursecertificationdate &&
            response.data.coursecertificationdate != ""
          ) {
            setCertDate(response.data.coursecertificationdate);
          } else {
            setCertDate("");
          }

          /*----- assign certification joining date ------*/
          if (
            response.data.subscriptiondate &&
            response.data.subscriptiondate != ""
          ) {
            setJoinedDate(response.data.subscriptiondate);
          } else {
            setJoinedDate("");
          }

          /*----- assign course score ------*/
          if (response.data.totalscore) {
            setTotalScore(response.data.totalscore);
          } else {
            setTotalScore(null);
          }

          /*----- assign total score ------*/
          if (response.data.coursescore && response.data.coursescore != "") {
            setScore(response.data.coursescore);
          } else {
            setScore("");
          }

          /*----- assign course name ------*/
          if (response.data.label && response.data.label != "") {
            setCourseName(response.data.label);
          } else {
            setCourseName("");
          }

          /*----- assign training type ------*/
          if (response.data.istraining) {
            setIsTraining(true);
          } else {
            setIsTraining(false);
          }

          /*----- assign moderator status ------*/
          if (
            response.data.moderatorid &&
            response.data.moderatorid === userInfo.id
          ) {
            setIsModerator(true);
          } else {
            setIsModerator(false);
          }
        } else {
          setCertiFication(false);
          setAcademyCertification(false);
          setCertificateName("");
          setCourseName("");
          setScore("");
          setJoinedDate("");
          setCertDate("");
          setIsTraining(false);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCourseAbout(params.id);
  }, [params.id]);

  return (
    <>
      {/* --------------- Doc Body starts here --------------- */}
      {isLoading ? null : (
        <div className="tab-pane show active fade" id="doc">
          {/* ------------- if the user is a moderator ------------- */}
          {isModerator || userInfo.isadmin ? (
            <div className="scores_pnl lesson_pnl">
              {/* -------------- Statistics starts here -------------- */}
              <div className="scorebx_outer">
                <div className="scorebx_item">
                  {joinedDate == "" ? null : (
                    <div className="scorebx_innr">
                      <h5>{t("Date of Joining")}</h5>
                      <h4>{joinedDate}</h4>
                    </div>
                  )}
                </div>
                <div className="scorebx_item">
                  {certDate == "" ? null : (
                    <div className="scorebx_innr">
                      <h5>{t("Date of Certification")}</h5>
                      <h4>{certDate}</h4>
                    </div>
                  )}
                </div>
                <div className="scorebx_item">
                  {score == "" ? null : (
                    <div className="scorebx_innr">
                      <h5>{t("Score")}</h5>
                      <h4>
                        {score} {t("out of")} {totalScore}
                      </h4>
                    </div>
                  )}
                </div>
              </div>

              {/* -------------- Certificate message starts here -------------- */}
              <div className="certificate_bx">
                <div
                  className="donate_certificate"
                  style={{
                    backgroundImage: certificate,
                  }}
                >
                  <div className="certificate_hdng">
                    <h2>{t("certificate of completion")}</h2>
                    {/* <span className="cerficate_line">
                  <img src="images/line.png" alt="" />
                </span> */}
                  </div>
                  <div className="certificate_nameHdng">
                    <p>{t("We hereby express our sincere appreciation to")}</p>
                    <h3>{userInfo.name}</h3>
                  </div>
                  <div className="certificate_para">
                    <p>
                      {t("This certifies that")} {userInfo.name} {t("has")}{" "}
                      <>
                        {isTraining == true ? (
                          <>{t("completed the Training")}</>
                        ) : (
                          <>{t("completed the course")}</>
                        )}
                      </>{" "}
                      <span className="fw-bold">
                        {courseName == "" ? null : courseName}
                      </span>{" "}
                      {t("successfully!")}{" "}
                      {t("and has obtained the certificate")}{" "}
                      <span className="fw-bold">
                        {certificateName == "" ? null : certificateName}
                      </span>
                    </p>
                  </div>
                  <div className="crtificate_signOuter">
                    <div className="certificate_date">
                      {certDate == "" ? null : (
                        <h5>
                          {t("Date")} <span>{certDate}</span>
                        </h5>
                      )}
                    </div>
                    <div className="certificate_stamp">
                      <img src={certStamp} alt="" />
                    </div>
                    <div className="certificate_sign">
                      <img src={signedCopy} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------- Download button links starts here -------------- */}
              <div className="download_btn">
                {/* <Link to="#" className="basic_btn">
              Download PDF
            </Link> */}
              </div>
            </div>
          ) : (
            <>
              {/* ------------- else if the user is a subscriber ------------- */}
              {subscriberStatus ? (
                <div className="scores_pnl lesson_pnl">
                  {certiFication == true && academyCertification == true ? (
                    <>
                      {/* -------------- Statistics starts here -------------- */}
                      <div className="scorebx_outer">
                        <div className="scorebx_item">
                          {joinedDate == "" ? null : (
                            <div className="scorebx_innr">
                              <h5>{t("Date of Joining")}</h5>
                              <h4>{joinedDate}</h4>
                            </div>
                          )}
                        </div>
                        <div className="scorebx_item">
                          {certDate == "" ? null : (
                            <div className="scorebx_innr">
                              <h5>{t("Date of Certification")}</h5>
                              <h4>{certDate}</h4>
                            </div>
                          )}
                        </div>
                        <div className="scorebx_item">
                          {score == "" ? null : (
                            <div className="scorebx_innr">
                              <h5>{t("Score")}</h5>
                              <h4>
                                {score} {t("out of")} {totalScore}
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* -------------- Certificate message starts here -------------- */}
                      <div className="certificate_bx">
                        <div
                          className="donate_certificate"
                          style={{
                            backgroundImage: certificate,
                          }}
                        >
                          <div className="certificate_hdng">
                            <h2>{t("certificate of completion")}</h2>
                            {/* <span className="cerficate_line">
                                  <img src="images/line.png" alt="" />
                                </span> 
                            */}
                          </div>
                          <div className="certificate_nameHdng">
                            <p>
                              {t(
                                "We hereby express our sincere appreciation to"
                              )}
                            </p>
                            <h3>{userInfo.name}</h3>
                          </div>
                          <div className="certificate_para">
                            <p>
                              {t("This certifies that")} {userInfo.name}{" "}
                              {t("has")}{" "}
                              <>
                                {isTraining == true ? (
                                  <>{t("completed the Training")}</>
                                ) : (
                                  <>{t("completed the course")}</>
                                )}
                              </>{" "}
                              <span className="fw-bold">
                                {courseName == "" ? null : courseName}
                              </span>{" "}
                              {t("successfully!")}{" "}
                              {t("and has obtained the certificate")}{" "}
                              <span className="fw-bold">
                                {certificateName == "" ? null : certificateName}
                              </span>
                            </p>
                          </div>
                          <div className="crtificate_signOuter">
                            <div className="certificate_date">
                              {certDate == "" ? null : (
                                <h5>
                                  {t("Date")} <span>{certDate}</span>
                                </h5>
                              )}
                            </div>
                            <div className="certificate_stamp">
                              <img src={certStamp} alt="" />
                            </div>
                            <div className="certificate_sign">
                              <img src={signedCopy} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* -------------- Download button links starts here -------------- */}
                      <div className="download_btn">
                        <Link to="#" className="basic_btn">
                          {t("Download PDF")}
                        </Link>
                      </div>
                    </>
                  ) : (
                    // Certification has not been obtained yet
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="empty_lessonsArea">
                          <div className="empty_lessonpic">
                            <img src={blankLesson} alt="blanklesson" />
                          </div>
                          <div className="empty_lessons_text">
                            <h2>{t("Sorry....!")}</h2>
                            <p>{t("Certification not obtained yet")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="scores_pnl lesson_pnl">
                  {/* ------------- else the user is a niether ------------- */}
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="empty_lessonsArea">
                        <div className="empty_lessonpic">
                          <img src={blankLesson} alt="blanklesson" />
                        </div>
                        <div className="empty_lessons_text">
                          <h2>{t("Sorry....!")}</h2>
                          <p>{t("You don't have privilege")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* --------------- Doc Body ends here --------------- */}
    </>
  );
};

export default TrainingCertificate;
