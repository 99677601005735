/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";
import SimilarGroups from "../common/SimilarGroups";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// import images here
import coursePic1 from "assets/EventsAssets/images/course-pic1.jpg";
import coursePic2 from "assets/EventsAssets/images/course-pic2.jpg";
import coursePic3 from "assets/EventsAssets/images/course-pic3.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const Events = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const { isApproved } = useContext(GlobalIndraInfo);

  // array for counting glow rows
  const glowCount = [1, 2, 3];

  // initial useState for API data
  const [eventsData, setEventsData] = useState([]);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  // function for all events lists
  const getEventsLists = async () => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GROUP_EVENT_LISTS +
        `?token=${token}&filtergroups=${params.id}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setEventsData(response.data);
      }
    } catch (error) {}
  };

  // useEffect for page number
  useEffect(() => {
    getEventsLists();
  }, [params.id]);

  return (
    <div className="tab-pane fade show active" id="events">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-12">
            {isLoading ? (
              <div className="course_detailspnl">
                <div className="row">
                  {glowCount.map((count) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={count}>
                        <div className="events_itemBx">
                          <div className="champions_pic">
                            <span
                              className="placeholder col-12"
                              style={{ height: "197px" }}
                            ></span>
                          </div>
                          <div className="champions_textpnl">
                            <div className="events_dateBx">
                              <span className="placeholder col-12 h-100 mt-1"></span>
                            </div>
                            <div className="events_hdngauthor">
                              <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              <span className="placeholder placeholder-md rounded-2 col-3"></span>
                            </div>
                            <div className="leftchampion_text">
                              <span
                                className="placeholder rounded-2 col-6 mt-2"
                                style={{ height: "20px" }}
                              ></span>
                            </div>
                            <div className="experts_skill">
                              <ul>
                                <li>
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "80px", height: "30px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "80px", height: "30px" }}
                                  ></span>
                                </li>
                                <li>
                                  <span
                                    className="placeholder rounded-2"
                                    style={{ width: "80px", height: "30px" }}
                                  ></span>
                                </li>
                              </ul>
                            </div>
                            <div className="details_btn">
                              <span
                                className="placeholder rounded-3 col-12"
                                style={{ height: "50px" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="course_detailspnl">
                {/* <div className="contnt_hdng tab_Hdng">
                <h4>Events</h4>
              </div> */}
                <div className="evntbx_outer">
                  {eventsData.length == 0 ? (
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="empty_lessonsArea">
                          <div className="empty_lessonpic">
                            <img src={blankLesson} alt="blanklesson" />
                          </div>
                          <div className="empty_lessons_text">
                            <h2>{t("Sorry....!")}</h2>
                            <p>
                              {t(
                                "Events are not available for this group at this moment."
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      {isApproved ||
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ? (
                        <>
                          {eventsData.map((event, index) => (
                            <div className="col-lg-4 col-sm-6" key={index}>
                              <div className="events_itemBx">
                                <div className="champions_pic">
                                  <img
                                    src={
                                      event.eventlogo === ""
                                        ? coursePic1
                                        : url.API_SERVER_URL + event.eventlogo
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="champions_textpnl">
                                  <div className="events_dateBx">
                                    <h3>{event.startday}</h3>
                                    <h5>
                                      {event.startmonth}{" "}
                                      <span>{event.startyear}</span>
                                    </h5>
                                  </div>
                                  <div className="events_hdngauthor">
                                    <h4>
                                      {event.address !== ""
                                        ? event.address
                                        : t("undisclosed")}
                                    </h4>
                                    <div className="evnts_enroll">
                                      <i className="material-icons-outlined">
                                        {" "}
                                        people{" "}
                                      </i>
                                      <span>
                                        {event.eventmembercount}
                                        {t("Enrolled")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="leftchampion_text">
                                    <h3>
                                      {event.title !== ""
                                        ? event.title
                                        : t("undisclosed")}
                                    </h3>
                                  </div>
                                  <div className="experts_skill">
                                    <ul>
                                      {event.eventcategorylist.map(
                                        (tag, index) => (
                                          <li key={index}>
                                            <Link to="#">
                                              <span>{tag.name}</span>
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>

                                  <div className="details_btn">
                                    <Link
                                      to={`/events/details/${event._id}`}
                                      className="basic_btn ylw_btn"
                                    >
                                      {event.moderatorid.toString() ===
                                        userInfo._id.toString() ||
                                      userInfo.role.slug === "SUPER_ADMIN" ||
                                      userInfo.role.slug === "ADMIN" ? (
                                        <i className="material-icons me-3">
                                          done_all
                                        </i>
                                      ) : (
                                        <span>
                                          {event.isjoined ? (
                                            <span>
                                              {event.isapproved ? (
                                                <i className="material-icons me-3">
                                                  done_all
                                                </i>
                                              ) : (
                                                <i className="material-icons me-3">
                                                  done
                                                </i>
                                              )}
                                            </span>
                                          ) : null}
                                        </span>
                                      )}{" "}
                                      {t("See Details")}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="empty_lessonsArea">
                          <div className="empty_lessonpic">
                            <img src={blankLesson} alt="blanklesson" />
                          </div>
                          <div className="empty_lessons_text">
                            <h2>{t("Sorry....!")}</h2>
                            <p>{t("You don't have privilege")}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
