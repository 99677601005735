import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// import custom components
import Header from "common/Header";
import ActivityFooter from "components/ActivityComponents/common/ActivityFooter";
import Footer from "common/Footer";
import ActivityFilter from "components/ActivityComponents/ActivityFilter/ActivityFilter";
import ActivityLeftpanel from "./ActivityLeftpanel";
import ActivityRightpanel from "./ActivityRightpanel";
import ActivityModal from "components/ActivityComponents/ActivityModal/ActivityModal";

const Activity = (props) => {
  // const location = useLocation();

  // const winnerIdState = location.state;

  const [activityPostId, setActivityPostId] = useState(null);
  const [showListPost, setShowListPost] = useState(false);
  const [searchValue, setSearchValue] = useState(""); // sending it to leftpanel
  const [title, setTitle] = useState("");
  const [contact, setContact] = useState([]);
  const [winner, setWinner] = useState([]);
  const [group, setGroup] = useState([]);
  const [event, setEvent] = useState([]);
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState([]);
  const [loadRightPanel, setLoadRightPanel] = useState(false);
  // const [forumWinner, setForumWinner] = useState(null);

  /*----- function for get post id from left panel 
  and pass it to the right panel -----*/
  const rightPanelHandler = (item) => {
    if (item) {
      setActivityPostId(item);
    } else {
      setActivityPostId(null);
    }
  };

  // send list post status
  const loadList = () => {
    if (showListPost == false) {
      setShowListPost(true);
    } else {
      setShowListPost(false);
    }
  };

  // function for stop sending load request to API after loading left panel
  const hideListPost = () => {
    if (showListPost == true) {
      setShowListPost(false);
    }
    setSearchValue("");
    resetFilterQuery();
  };

  /* get search query data from activityFilter component and send it to left panel */
  const searchPostHandler = (val) => {
    if (val == "") {
      loadList();
      setSearchValue("");
    } else {
      setSearchValue(val);
      loadList();
    }
  };

  // get filter modal query data from filter modal component
  const filterModalQuery = (item = {}) => {
    setTitle(item.title ?? "");
    setContact(item.contact ?? []);
    setWinner(item.winner ?? []);
    setGroup(item.group ?? []);
    setEvent(item.event ?? []);
    setTags(item.tags ?? []);
    setCategory(item.category ?? []);
    loadList();
  };

  const resetFilterQuery = () => {
    setTitle("");
    setContact([]);
    setWinner([]);
    setGroup([]);
    setEvent([]);
    setTags([]);
    setCategory([]);
  };

  // function reload activity again
  const refreshActivity = () => {
    resetFilterQuery();
    loadList();
    setLoadRightPanel(true);
  };

  const disableRightPanelRefresh = () => {
    setLoadRightPanel(false);
  };

  // const resetForumWinner = () => {
  //   setForumWinner(null);
  // };

  const loadLeftPanel = () => {
    loadList();
  };

  // useEffect(() => {
  //   if (winnerIdState !== undefined) {
  //     setForumWinner(winnerIdState.id);
  //   }
  // }, [winnerIdState]);

  useEffect(() => {
    document.title = "Activity";
  }, []);

  return (
    <>
      {/*---- activity header ----*/}
      <Header />
      <section className="activity_area">
        <div className="container">
          {/*---- activity filter ----*/}
          <ActivityFilter
            loadList={loadList}
            searchPostHandler={searchPostHandler}
            refreshActivity={refreshActivity}
          />
          <div className="activity_outer">
            <div className="row">
              <div className="col-lg-5">
                {/*---- activity left panel ----*/}
                <ActivityLeftpanel
                  refreshActivity={refreshActivity}
                  rightPanelHandler={rightPanelHandler}
                  showListPost={showListPost}
                  searchValue={searchValue}
                  hideListPost={hideListPost}
                  title={title}
                  contact={contact}
                  winner={winner}
                  group={group}
                  event={event}
                  tags={tags}
                  category={category}
                  // forumWinner={forumWinner}
                  // resetForumWinner={resetForumWinner}
                />
              </div>
              <div className="col-lg-7">
                {/*---- activity right panel ----*/}
                <ActivityRightpanel
                  activityPostId={activityPostId}
                  loadRightPanel={loadRightPanel}
                  disableRightPanelRefresh={disableRightPanelRefresh}
                  loadLeftPanel={loadLeftPanel}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---- activity footer ---- */}
      <Footer />
      {/* <ActivityFooter /> */}

      {/* ---- activity filter modal---- */}
      <ActivityModal searchQuery={filterModalQuery} />
    </>
  );
};

export default Activity;
