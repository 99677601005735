/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { getData, getData_v2 } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// importing images here
import errorFrame from "assets/ErrorAssets/images/error-frame.png";
import errorGraphics from "assets/ErrorAssets/images/error-graphics.png";
import TomHanks from "assets/BlogAssets/images/tom-hanks.png";
import BlogDetails from "assets/BlogAssets/images/blog-details.jpg";
import defaultUser from "assets/HomeAssets/Images/default-user.png";

const BlogContent = () => {
  const params = useParams();

  const { setBlogName } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  // array for counting glow rows
  const glowCount = [1, 2, 3];

  const [blogDetailsData, setBlogDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getBlogDetails = async (blogTitle) => {
    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL + url.API_BLOG_DETAILS + `/${blogTitle}`;

      const response = await getData_v2(requestURL);

      setIsLoading(false);

      if (response.status) {
        if (response.data) {
          setBlogDetailsData(response.data);
          setBlogName(response.data.name);
        } else {
          setBlogDetailsData(null);
        }
      } else {
        setBlogDetailsData(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // useEffect from here
  useEffect(() => {
    getBlogDetails(params.postname);
    window.scrollTo(0, 0);
  }, [params.postname]);

  return (
    <>
      {isLoading ? null : (
        <div>
          {blogDetailsData == null || blogDetailsData.length == 0 ? (
            <div className="blog_section_main">
              <section
                className="error_banner"
                style={{ backgroundImage: `url(${errorFrame})` }}
              >
                <div className="container">
                  <div className="error_outer">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="error_text">
                          <h1>
                            <span>{t("oops!")}</span> {t("blog post not found")}
                          </h1>
                          <p>
                            {t(
                              "Looks like you followed a bad title. If you think this is a problem with us, please let us know."
                            )}
                          </p>
                          <div className="error_btn">
                            <Link to="/blogs" className="basic_btn ylw_btn">
                              {t("Go to Blog")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="error_graphics">
                          <img src={errorGraphics} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <div className="blog_section_main">
              {/* -------------- Blog Title, date author section starts here -------------- */}
              <div className="post-head d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <div className="post-title me-3">
                  <h1 className="fw-bold mb-3">{blogDetailsData.name}</h1>
                  <ul className="post-meta d-flex align-items-center">
                    <li>
                      <Link to="#" className="author d-flex align-items-center">
                        <img
                          src={
                            blogDetailsData.authorimage !== ""
                              ? url.API_SERVER_URL + blogDetailsData.authorimage
                              : defaultUser
                          }
                          alt="Tom Hanks"
                          className="img-fluid me-2"
                        />
                        <span>{blogDetailsData.authorname}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="#" className="date d-flex align-items-center">
                        <i className="material-icons me-2">date_range</i>
                        <span>{blogDetailsData.blogdate}</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <Link
              to="#"
              id="postShare"
              className="d-flex align-items-center justify-content-center"
            >
              <i className="material-icons d-block">share</i>
            </Link> */}
              </div>
              {/* -------------- Blog Title, date author section starts here -------------- */}

              {/* -------------- Blog body section starts here -------------- */}
              <div className="blog-details-container mt-5">
                {blogDetailsData.coverimageurl === "" ? null : (
                  <div className="img-container mb-4">
                    <img
                      src={url.API_SERVER_URL + blogDetailsData.coverimageurl}
                      alt="Figma Design Event 2022"
                      className="img-fluid"
                    />
                  </div>
                )}
                <div className="blog-desc mb-4">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blogDetailsData.description,
                    }}
                  ></p>
                </div>
                <div className="tags d-flex flex-wrap align-items-center gap-2">
                  <i className="material-icons">label_outline</i>
                  {blogDetailsData.categorylist.map((catItem, index) => {
                    return <span key={index}>{catItem.label}</span>;
                  })}
                </div>
              </div>
              {/* -------------- Blog body section starts here -------------- */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BlogContent;
