/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import helper functions
import * as url from "helpers/url_helper";

// import Gateway
import { getData_v2 } from "utils/Gateway";

const FaqSearchResult = ({ searchQuery, resetQuery, setResetQuery }) => {
  // user information
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // states for faq search result
  const [isLoading, setIsLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [page, setPage] = useState(0);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // get request to API to fetch faq data
  const getFaqData = async () => {
    // fetch data
    setIsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_FAQ_LIST +
        `?token=${token}&modulefrom=frontend&start=${page * 4}&limit=4`;

      if (searchQuery.length > 2 && resetQuery === false) {
        requestUrl += `&filterquery=${searchQuery}`;
      }

      console.log("requestUrl", requestUrl);

      const response = await getData_v2(requestUrl);

      setIsLoading(false);

      console.log("FAQ data response", response);

      if (response.status) {
        setFaqData(response.data);

        // set last page no from API
        setLastPageNo(response.totalpages);

        /*--- Creating dynamic array for pagination ----*/
        let pageArray = [];

        for (let i = 1; i <= response.totalpages; i++) {
          pageArray.push(i);
        }
        setTotalPages(pageArray);
        /*--- Creating dynamic array for pagination end ----*/
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // useeffect to fetch data on change of searchQuery or resetQuery
  useEffect(() => {
    // fetch data
    if (searchQuery.length > 2 || resetQuery) {
      console.log("searchQuery", searchQuery);
      console.log("resetQuery", resetQuery);

      getFaqData();

      setResetQuery(false); // after reload, set this false
    }
  }, [searchQuery, resetQuery, page]);

  // by default load all faq data
  useEffect(() => {
    // fetch data
    getFaqData();
  }, [page]);

  return (
    <section className="faq-wrapper py-5">
      <div className="container">
        <div className="accordion-filter mb-4">
          <p>Found {faqData.length} result </p>
        </div>
        <div className="faqs-group d-flex flex-column gap-5">
          {isLoading || faqData.length === 0 ? (
            <div className="faqs">
              <h3>Loading...</h3>
              <ul className="d-flex flex-column gap-2">
                <li className="glow-placeholder"></li>
                <li className="glow-placeholder"></li>
                <li className="glow-placeholder"></li>
              </ul>
            </div>
          ) : (
            <div className="faqs">
              <h3>Globals</h3>
              <ul className="d-flex flex-column gap-2">
                {faqData.length > 0 &&
                  faqData.map((faq, index) => (
                    <li key={index}>
                      <Link
                        to={`/faq/details/${faq._id}`}
                        className="d-flex align-items-center justify-content-between gap-3"
                      >
                        <span className="d-block">{faq.question}</span>
                        <span className="d-flex align-items-center justify-content-center material-icons-outlined">
                          arrow_forward
                        </span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>

        {/* pagination */}
        <div className="pagination_list">
          <ul>
            <li>
              <Link
                to="#"
                onClick={() => {
                  if (page !== 0) {
                    nextPage(page - 1);
                    blogRefToggle();
                  }
                }}
              >
                <i className="material-icons-outlined">arrow_back</i>
              </Link>
            </li>
            {totalPages.map((pageNo) => {
              return (
                <li
                  className={page + 1 === pageNo ? "active" : ""}
                  key={pageNo}
                >
                  <Link
                    to="#"
                    onClick={() => {
                      nextPage(pageNo - 1);
                      blogRefToggle();
                    }}
                  >
                    {pageNo}
                  </Link>
                </li>
              );
            })}

            <li>
              <Link
                to="#"
                onClick={() => {
                  if (page !== lastPageNo - 1) {
                    nextPage(page + 1);
                    blogRefToggle();
                  }
                }}
              >
                <i className="material-icons-outlined">arrow_forward</i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FaqSearchResult;
