/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// import images here
import contactDetails from "assets/ContactAssets/svg/contact-details-gaphic.svg";

const ContactFormLeftPanel = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [firstNameWarning, setfirstNameWarning] = useState(false);
  const [lastNameWarning, setlastNameWarning] = useState(false);
  const [emailWarning, setemailWarning] = useState(false);
  const [phoneWarning, setphoneWarning] = useState(false);
  const [messageWarning, setmessageWarning] = useState(false);
  const [emailIsNotValid, setemailIsNotValid] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [checkWarning, setCheckWarning] = useState(false);

  const contactHandler = async (e) => {
    e.preventDefault();

    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      phone == "" ||
      message == "" ||
      isChecked == false
    ) {
      // first name validation
      if (firstName == "") {
        setfirstNameWarning(true);
      } else {
        setfirstNameWarning(false);
      }

      // last name validation
      if (lastName == "") {
        setlastNameWarning(true);
      } else {
        setlastNameWarning(false);
      }

      //email validation
      if (email == "") {
        setemailWarning(true);
      } else {
        setemailWarning(false);
      }

      //phone validation
      if (phone == "") {
        setphoneWarning(true);
      } else {
        setphoneWarning(false);
      }

      // confirm phone validation
      if (message == "") {
        setmessageWarning(true);
      } else {
        setmessageWarning(false);
      }

      // agree validation
      if (isChecked == false) {
        setCheckWarning(true);
      } else {
        setCheckWarning(false);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      setfirstNameWarning(false);
      setlastNameWarning(false);
      setphoneWarning(false);
      setemailWarning(false);
      setmessageWarning(false);
      setCheckWarning(false);

      if (email.match(mailformat)) {
        setemailIsNotValid(false);

        // exectuing code for submit here
        try {
          // setSending(true);
          let requestUrl = url.API_BASE_URL + url.API_CONTACT_US;

          let contactData = {
            cfirstname: firstName,
            clastname: lastName,
            cemail: email,
            cphone: phone,
            cmessage: message,
          };

          let result = await postData(requestUrl, contactData);

          if (result) {
            document.body.classList.remove("contact_open");
            let myModal = new bootstrap.Modal(
              document.getElementById("sent_mdl")
            );
            myModal.show();
            resetData();
          }
          // setSending(false);
        } catch (error) {}
      } else {
        setemailIsNotValid(true);
      }
    }
  };

  const resetData = () => {
    setemail("");
    setFirstName("");
    setLastName("");
    setphone("");
    setmessage("");
    setfirstNameWarning(false);
    setlastNameWarning(false);
    setemailWarning(false);
    setphoneWarning(false);
    setmessageWarning(false);
    setCheckWarning(false);
  };

  return (
    <div className="col-md-6 py-5">
      {/* ----------- Top Component starts here ----------- */}
      <div className="contact-details p-4 p-sm-5 mb-5">
        <div className="row align-items-center">
          <div className="col-sm-6 mb-4 mb-sm-0">
            <h3 className="fw-bold mb-3">{t("Contact Details")}</h3>
            <ul>
              <li>
                <Link
                  to="tel:+34914805002"
                  className="d-flex align-items-center"
                >
                  <i className="material-icons d-block me-2">phone</i> T. (+34)
                  914 805 002
                </Link>
              </li>
              <li>
                <Link to="#" className="d-flex align-items-center">
                  <i className="material-icons d-block me-2">fax</i> F. (+34)
                  914 805 080
                </Link>
              </li>
              <li>
                <Link
                  to="mailto:indra@indracompany.com"
                  className="d-flex align-items-center"
                >
                  <i className="material-icons d-block me-2">email</i>
                  indra@indracompany.com
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <img
              src={contactDetails}
              alt="Contact Details"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      {/* ----------- Top Component ends here ----------- */}

      {/* ----------- Bottom Component starts here ----------- */}
      <div className="contact-form-container">
        <h3 className="fw-bold mb-4">{t("Contact From")}</h3>
        <form onSubmit={contactHandler}>
          {/* -------------- Name textbox starts here -------------- */}
          <div className="form-row row mb-4">
            {/* -------------- First Name textbox starts here -------------- */}
            <div className="col-sm-6 mb-4 mb-sm-0">
              <label htmlFor="firstName" className="d-block fw-bold mb-2">
                {t("First Name")}
              </label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control rounded-0 shadow-none"
                placeholder={t("Enter First Name")}
              />
              {firstNameWarning && (
                <span className="text-danger">
                  *{t("Please enter first name")}
                </span>
              )}
            </div>
            {/* -------------- First Name textbox ends here -------------- */}

            {/* -------------- Last Name textbox starts here -------------- */}
            <div className="col-sm-6">
              <label htmlFor="lastName" className="d-block fw-bold mb-2">
                {t("Last Name")}
              </label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="form-control rounded-0 shadow-none"
                placeholder={t("Enter Last Name")}
              />
              {lastNameWarning && (
                <span className="text-danger">
                  *{t("Please enter last name")}
                </span>
              )}
            </div>
            {/* -------------- Last Name textbox ends here -------------- */}
          </div>
          {/* -------------- Name textbox ends here -------------- */}

          {/* -------------- Email textbox starts here -------------- */}
          <div className="form-row mb-4 position-relative">
            <label htmlFor="email" className="d-block fw-bold mb-2">
              {t("Email")}
            </label>
            <div className="field-group position-relative">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                className="form-control rounded-0 shadow-none"
                placeholder={t("Enter Email")}
              />
              <i className="d-inline-block material-icons position-absolute top-50 end-0 translate-middle-y me-3">
                mail_outline
              </i>
            </div>
            {emailWarning && (
              <span className="text-danger">*{t("Please enter email")}</span>
            )}
          </div>
          {/* -------------- Email textbox ends here -------------- */}

          {/* -------------- Phone textbox starts here -------------- */}
          <div className="form-row mb-4 position-relative">
            <label htmlFor="phone" className="d-block fw-bold mb-2">
              {t("Phone Number")}
            </label>
            <div className="field-group position-relative">
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                className="form-control rounded-0 shadow-none"
                placeholder={t("Enter Phone")}
              />
              <i className="d-inline-block material-icons position-absolute top-50 end-0 translate-middle-y me-3">
                call
              </i>
            </div>
            {phoneWarning && (
              <span className="text-danger">
                *{t("Please enter phone number")}
              </span>
            )}
          </div>
          {/* -------------- Phone textbox ends here -------------- */}

          {/* -------------- Message textbox starts here -------------- */}
          <div className="form-row mb-4">
            <label htmlFor="message" className="d-block fw-bold mb-2">
              {t("Message")}
            </label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="5"
              className="form-control rounded-0 shadow-none"
              placeholder={t("Enter Message")}
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            ></textarea>
            {messageWarning && (
              <span className="text-danger">*{t("Please enter message")}</span>
            )}
          </div>
          {/* -------------- Message textbox ends here -------------- */}

          <div className="trms_check">
            <label className="radio-check">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setisChecked(e.target.checked)}
              />
              <span></span>
              <p>
                {t("I have read and agree to the")}
                <Link to="/legal-terms-condition">
                  {t("Legal Notice")}
                </Link>{" "}
                &amp;
                <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
              </p>
            </label>
            {checkWarning && (
              <p className="mt-2 text-danger mb-0">
                * {t("Please agree with terms and condition")}
              </p>
            )}
          </div>

          <div className="capcha">
            <img src="#" alt="" />
          </div>

          {/* -------------- Button starts here -------------- */}
          <div className="action">
            <button className="d-block w-100 basic_btn ylw_btn rounded-0 shadow-none">
              {t("Send")}
            </button>
          </div>
          {/* -------------- Button ends here -------------- */}
        </form>
      </div>
      {/* ----------- Bottom Component ends here ----------- */}
    </div>
  );
};

export default ContactFormLeftPanel;
