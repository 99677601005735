/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import methods from gatway for API call
import { postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const ContactForm = () => {
  const { t } = useTranslation();

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const [nameWarning, setnameWarning] = useState(false);
  const [emailWarning, setemailWarning] = useState(false);
  const [phoneWarning, setphoneWarning] = useState(false);
  const [messageWarning, setmessageWarning] = useState(false);
  const [emailIsNotValid, setemailIsNotValid] = useState(false);
  const [checkWarning, setCheckWarning] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const contactHandler = async (e) => {
    e.preventDefault();

    if (
      name == "" ||
      email == "" ||
      phone == "" ||
      message == "" ||
      isChecked == false
    ) {
      // first name validation
      if (name == "") {
        setnameWarning(true);
      } else {
        setnameWarning(false);
      }

      //email validation
      if (email == "") {
        setemailWarning(true);
      } else {
        setemailWarning(false);
      }

      //phone validation
      if (phone == "") {
        setphoneWarning(true);
      } else {
        setphoneWarning(false);
      }

      // confirm phone validation
      if (message == "") {
        setmessageWarning(true);
      } else {
        setmessageWarning(false);
      }

      // agree validation
      if (isChecked == false) {
        setCheckWarning(true);
      } else {
        setCheckWarning(false);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      setnameWarning(false);
      setphoneWarning(false);
      setemailWarning(false);
      setmessageWarning(false);
      setCheckWarning(false);

      if (email.match(mailformat)) {
        setIsSending(true);
        setemailIsNotValid(false);

        // exectuing code for submit here
        try {
          let requestUrl = url.API_BASE_URL + url.API_CONTACT_US;

          let contactData = {
            cname: name,
            cemail: email,
            cphone: phone,
            cmessage: message,
            cterms: isChecked,
          };

          let result = await postData(requestUrl, contactData);

          if (result.status) {
            document.body.classList.remove("contact_open");
            let myModal = new bootstrap.Modal(
              document.getElementById("sent_mdl")
            );
            myModal.show();
            resetData();
          } else {
          }
          setIsSending(false);
        } catch (error) {}
      } else {
        setemailIsNotValid(true);
      }
    }
  };

  const resetData = () => {
    setemail("");
    setname("");
    setphone("");
    setmessage("");
    setisChecked(false);
    setnameWarning(false);
    setemailWarning(false);
    setphoneWarning(false);
    setmessageWarning(false);
    setCheckWarning(false);
  };

  return (
    <>
      <div className="contact_menupnl">
        <div className="conatct_cross">
          <Link
            to="#"
            className="close_contact"
            onClick={() => {
              document.body.classList.remove("contact_open");
              resetData();
            }}
          >
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="contact_innr">
          <div className="contact_hdng congrats_text">
            <h3>{t("Contact Us")}</h3>
            <h5>{t("Fill up for quick contact below")}</h5>
          </div>
          <form onSubmit={contactHandler}>
            <div className="contact_fillOuter">
              {/* ----- name section start ----- */}
              <div className="input_fill contact_fill">
                <label>{t("name")} </label>
                {nameWarning && (
                  <span className="text-danger mx-2">
                    *{t("Please enter name")}
                  </span>
                )}
                <input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setnameWarning(false);
                    setname(e.target.value);
                  }}
                  className="form-control"
                  placeholder={t("Enter Name")}
                />
              </div>
              {/* ----- name section end ----- */}

              {/* ----- email section start ----- */}
              <div className="input_fill contact_fill">
                <label>{t("email")}</label>
                {emailWarning && (
                  <span className="text-danger mx-2">
                    *{t("Please enter email")}
                  </span>
                )}
                <input
                  type="email"
                  className="form-control email_fill"
                  placeholder={t("Enter Email")}
                  value={email}
                  onChange={(e) => {
                    setemailWarning(false);
                    setemail(e.target.value);
                  }}
                />
              </div>
              {/* ----- email section end ----- */}

              {/* ----- phone section start ----- */}
              <div className="input_fill contact_fill">
                <label>{t("Phone Number")}</label>{" "}
                {phoneWarning && (
                  <span className="text-danger mx-2">
                    *{t("Please enter phone number")}
                  </span>
                )}
                <input
                  type="number"
                  className="form-control phn_fill"
                  placeholder={t("Enter Number")}
                  value={phone}
                  onChange={(e) => {
                    setphoneWarning(false);
                    setphone(e.target.value);
                  }}
                />
              </div>
              {/* ----- phone section end ----- */}

              {/* ----- message section start ----- */}
              <div className="input_fill descrioption_fill contact_fill">
                <label>{t("Message")}</label>
                {messageWarning && (
                  <span className="text-danger mx-2">
                    *{t("Please enter message")}
                  </span>
                )}
                <textarea
                  className="form-control msg_fill"
                  placeholder={t("Enter message")}
                  value={message}
                  onChange={(e) => {
                    setmessageWarning(false);
                    setmessage(e.target.value);
                  }}
                ></textarea>
              </div>
              {/* ----- message section end ----- */}

              {/* ----- term and condition section start ----- */}
              <div className="trms_check">
                <label className="radio-check">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => {
                      setCheckWarning(false);
                      setisChecked(e.target.checked);
                    }}
                  />
                  <span></span>
                  <p>
                    {t("I have read and agree to the")}
                    <Link
                      to="/legal-terms-condition"
                      onClick={() => {
                        document.body.classList.remove("contact_open");
                        resetData();
                      }}
                    >
                      {t("Legal Notice")}
                    </Link>{" "}
                    &amp;
                    <Link
                      to="/privacy-policy"
                      onClick={() => {
                        document.body.classList.remove("contact_open");
                        resetData();
                      }}
                    >
                      {t("Privacy Policy")}
                    </Link>
                  </p>
                </label>
                {checkWarning && (
                  <p className="mt-2 text-danger mb-0">
                    * {t("Please agree with terms and condition")}
                  </p>
                )}
              </div>
              {/* ----- term and condition section end ----- */}
              <div className="capcha">{/* <img src="#" alt="" /> */}</div>

              {/* ----- button section start ----- */}
              <div className="input_fill submit_fill contact_fill">
                <button
                  className="basic_btn ylw_btn"
                  disabled={isSending ? true : false}
                  style={{ cursor: isSending ? "not-allowed" : "pointer" }}
                >
                  {t("Send")}
                  {isSending && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
