import React, { useState, useEffect } from "react";
import AskQuestionModal from "components/FaqComponents/AskQuestion/AskQuestion";
import { Link } from "react-router-dom";

const FaqSearch = ({ setSearchQuery, setResetQuery }) => {
  // function to validate search query
  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.target[0].value;
    if (searchQuery.length > 2) {
      setSearchQuery(searchQuery);
    }
  };

  const [searchFaq, setSearchFaq] = useState("");

  return (
    <>
      <section className="faq-header pt-4 border-bottom">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-8 col-xl-7">
              <form
                action=""
                className="position-relative mb-4"
                onSubmit={handleSearch}
              >
                <input
                  type="text"
                  className="form-control rounded-0 shadow-none"
                  placeholder="Search"
                  value={searchFaq}
                  onChange={(e) => setSearchFaq(e.target.value)}
                />
                <button
                  type="submit"
                  className="position-absolute top-50 translate-middle-y"
                >
                  <span className="material-icons-outlined">search</span>
                </button>
              </form>
            </div>
            <div className="col-lg-4 col-xl-5 d-flex justify-content-end mb-4 mb-lg-0">
              <button
                className="basic_btn ylw_btn w-auto me-3"
                type="button"
                onClick={() => {
                  setResetQuery(true);
                  setSearchFaq("");
                  setSearchQuery("");
                }}
              >
                <span className="material-icons-outlined">refresh</span>
              </button>

              <button
                className="basic_btn ylw_btn w-auto"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#askQuestion"
              >
                Ask Question
              </button>
            </div>
          </div>
        </div>
      </section>

      <AskQuestionModal />
    </>
  );
};

export default FaqSearch;
