/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import { GlobalIndraInfo } from "App";
// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { getData } from "utils/Gateway";

//import images
import defaultUser from "assets/HomeAssets/Images/default-user.png";

const UserMenu = () => {
  const [userName, setUserName] = useState("");
  const [userProfilePic, setUserProfilePic] = useState("");

  const history = useHistory();

  let language = localStorage.getItem("language");

  const { getCourseId, setCallSlider } = useContext(GlobalIndraInfo);

  const token = localStorage.getItem("token");
  const startingUrl = localStorage.getItem("startingURL");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [searchString, setSearchString] = useState("");

  // Function for changing language
  const handleSelectedLanguage = (val) => {
    i18n.changeLanguage(val);
    // localStorage.clear();
    localStorage.setItem("language", val);
    setCallSlider(true);
  };

  // function for log out and clear localstorage
  const logoutHandler = () => {
    try {
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      throw error;
    }
  };

  // function for search query in header to reirect search page
  const redirectSearch = (e) => {
    e.preventDefault();
    history.push(`/search?query=${searchString}`);
  };

  // function for get logged user details after refresh
  const getUserInfo = async () => {
    let requestURL =
      url.API_BASE_URL +
      url.API_CHAMPION_ABOUT +
      `/${userInfo._id}?token=${token}`;

    let response = await getData(requestURL);

    if (response.status) {
      if (response.data) {
        localStorage.setItem("language", response.data.language);
        i18n.changeLanguage(response.data.language);

        setUserName(`${response.data.name} ${response.data.surname}`);

        if (response.data.userimagepath) {
          setUserProfilePic(response.data.userimagepath);
        }
      }
    }
  };

  useEffect(() => {
    if (userInfo !== null) {
      getUserInfo();
    }
  }, []);

  return (
    <div className="hdr_top">
      <div className="container">
        <div className="top_outer">
          {/* ----- menu link ----- */}
          {/* <div className="top_menu">
            <ul>
              <li>
                <Link to="#">{t("Investor relations")}</Link>
              </li>
              <li>
                <Link to="#">{t("Press Room")}</Link>
              </li>
              <li>
                <Link to="#">{t("Where we are")}</Link>
              </li>
            </ul>
          </div> */}
          {/* ----- menu link end ----- */}

          {/* ----- language section ----- */}
          {!token ? (
            <div className="lang_slect_bx">
              <select
                className="select"
                name="languageSelector"
                onChange={(e) => {
                  handleSelectedLanguage(e.target.value);
                }}
                value={i18n.language}
              >
                <option value="en">EN</option>
                <option value="es">ES</option>
              </select>
            </div>
          ) : (
            <div className="lang_slect_bx">
              {userInfo == null ? null : (
                <select
                  className="select"
                  name="languageSelector"
                  onChange={(e) => {
                    handleSelectedLanguage(e.target.value);
                  }}
                  value={i18n.language}
                >
                  <option value="en">EN</option>
                  <option value="es">ES</option>
                </select>
              )}
            </div>
          )}
          {/* ----- language section end ----- */}

          {/* ---- search section ----- */}
          {/* {token && (
            <div className="hdr_srch">
              <input
                type="text"
                className="form-control"
                placeholder={t("Search")}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <button
                className="srchsbmit_btn"
                onClick={(e) => redirectSearch(e)}
              >
                <span className="material-icons-round">search</span>
              </button>
            </div>
          )} */}
          {/* ---- search section end ----- */}
          <div className="login_menuicon-Outer">
            {token == null ? (
              <div className="loginuser_area">
                <div className="login_menu">
                  <Link to="/login">
                    <i className="material-icons-outlined">account_circle</i>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="loginuser_area">
                <ul>
                  <li className="dropdown">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className="usr_pnlBtn"
                    >
                      <div className="usr_text">
                        {t("Hello")}
                        {userName == "" ? null : (
                          <span className="text-white"> {userName}</span>
                        )}
                      </div>
                      <div className="usr_pic">
                        <img
                          src={
                            userProfilePic === ""
                              ? defaultUser
                              : url.API_SERVER_URL + userProfilePic
                          }
                          alt="user"
                        />
                      </div>
                    </Link>
                    <div className="menurightPnl dropdown-menu">
                      <div className="account_topUsr">
                        <div className="account_user_outer">
                          <div className="accont_usrPic">
                            <img
                              src={
                                userProfilePic === ""
                                  ? defaultUser
                                  : url.API_SERVER_URL + userProfilePic
                              }
                              alt="user"
                            />
                          </div>
                          <div className="accont_owner_name">
                            {userName == "" ? null : <h3>{userName}</h3>}
                          </div>
                        </div>
                        <div className="logout_btn">
                          <Link to="#" role="button" onClick={logoutHandler}>
                            <span className="material-icons-outlined text-dark">
                              logout
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="afterlogin_menu">
                        <ul>
                          <li>
                            <a
                              // to={{
                              //   pathname:
                              //     url.API_BACKEND_URL +
                              //     "?view=dashboard&token=" +
                              //     token,
                              // }}
                              // target="_blank"
                              href={`${url.API_BACKEND_URL}/auth/validate?email=${userInfo.email}&token=${token}&redirectto=DASHBOARD`}
                            >
                              <i className="material-icons-outlined">
                                space_dashboard
                              </i>
                              <span>{t("Dashboard")}</span>
                            </a>
                          </li>
                          <li>
                            <a
                              // to={{
                              //   pathname:
                              //     url.API_BACKEND_URL +
                              //     "?view=profile&token=" +
                              //     token,
                              // }}
                              // target="_blank"
                              href={`${url.API_BACKEND_URL}/auth/validate?email=${userInfo.email}&token=${token}&redirectto=MY_PROFILE`}
                            >
                              <i className="material-icons-outlined">
                                account_circle
                              </i>
                              <span>{t("My profile")}</span>
                            </a>
                          </li>
                          <li>
                            <a
                              // to={{
                              //   pathname:
                              //     url.API_BACKEND_URL +
                              //     "?view=notification&token=" +
                              //     token,
                              // }}
                              // target="_blank"
                              href={
                                url.API_BACKEND_URL +
                                "?view=notification&token=" +
                                token +
                                `&language=${language}`
                              }
                            >
                              <i className="material-icons-outlined">
                                mark_chat_unread
                              </i>
                              <span>{t("Notification")}</span>
                            </a>
                          </li>
                          {/* <li>
                            <Link
                              to="/mycourses"
                              onClick={() => {
                                getCourseId(null);
                              }}
                            >
                              <i className="material-icons-outlined">
                                history_edu
                              </i>
                              <span>{t("My Courses")}</span>
                            </Link>
                          </li> */}

                          <li>
                            <Link to="/myinvitations">
                              <i className="material-icons-outlined">
                                connect_without_contact
                              </i>
                              <span>{t("My Connect Invites")}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
