/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import trainingCard from "assets/CourseDetailsAssests/images/traning-card.jpg";

const TrainingCourses = () => {
  const { t } = useTranslation();
  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  /*---- initial for pagination and pages ----*/
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  const courseListRef = useRef(null);

  /* get function and values from use context */
  const {
    searchQueryValues,
    searchTitle,
    searchSkills,
    searchCategory,
    resetCourseQuery,
    refreshCourseList,
    resetCourseTitleOnly,
    resetCourseSkillsOnly,
    resetCourseCategoryOnly,
    deleteSingleCourseSkill,
    deleteSingleCourseCategory,
    selectedCourseFilterData,
    resetCourseFilterOnly,
    trainingCourseTabReload,
    setTrainingCourseTabReload,
  } = useContext(GlobalIndraInfo);

  // for routing
  const history = useHistory();

  // initial useState to save demo JSON data
  const [courseListData, setCourseListData] = useState([]);

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  //function for toggle eventRef
  const courseRefToggle = () => {
    courseListRef.current?.scrollIntoView();
  };

  // function to call the API and get all the courses
  const getPublishedCourseListData = async () => {
    setIsLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_COURSE_TRAINING_LIST +
      `?token=${token}` +
      `&cid=${params.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setCourseListData(response.data);
        } else {
          setCourseListData([]);
        }

        // set last page no from API
        // setLastPageNo(response.totalpages);

        /*--- Creating dynamic array for pagination ----*/
        // let pageArray = [];

        // for (let i = 1; i <= response.totalpages; i++) {
        //   pageArray.push(i);
        // }
        // setTotalPages(pageArray);
        /*--- Creating dynamic array for pagination end ----*/

        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function to go to the details page
  const goToCourseDetails = (e, id) => {
    e.preventDefault();
    history.push(`/course/details/${id}`);
  };

  useEffect(() => {
    if (trainingCourseTabReload) {
      setPage(1);
      getPublishedCourseListData();
      setTrainingCourseTabReload(false);
    }
  }, [trainingCourseTabReload]);

  useEffect(() => {
    setPage(1);
    getPublishedCourseListData();
  }, []);

  return (
    <>
      {/* -------------- Courses Content starts here -------------- */}
      <div className="tab-pane show active fade" id="events">
        <div className="course_detailspnl">
          {/* <div className="contnt_hdng">
            <h4>Courses</h4>
          </div> */}

          {isLoading ? (
            <div className="champions_outer">
              <div className="row">
                {/* --------- Glow placeholder component starts here --------- */}
                {[1, 2, 3].map((i) => (
                  <div className="col-lg-4 col-sm-6" key={i}>
                    <div className="course_item">
                      <div className="champions_pic">
                        <img src={blankImage} alt="" />
                      </div>
                      <div className="plan p-3 border rounded-3 placeholder-glow">
                        <div className="user_placeHoledr">
                          <div className="default_usr_name">
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          </div>
                        </div>
                        <div className="data_btnOuter">
                          <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                        </div>
                        <p className="data_btnOuter placeholder-glow">
                          <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                        </p>
                        <div className="data_btnOuter">
                          <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* --------- Glow placeholder component ends here --------- */}
              </div>
            </div>
          ) : (
            <div className="champions_outer">
              {courseListData.length === 0 ? (
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("Course are not available at this moment.")}</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {courseListData.map((course, index) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={index}>
                        <div className="course_item">
                          {/* ------- Course image section ------ */}
                          <div className="champions_pic">
                            <img
                              src={course.image ? course.image : blankImage}
                              alt="courseBanner"
                            />
                          </div>
                          <div className="champions_textpnl">
                            {/* ------- Course author & rating section ------ */}
                            <div className="course_hdngauthor">
                              <h4>{course.moderator}</h4>
                              <div className="chappions_rating">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                  <span>{course.rating}</span>
                                  <em>({course.reviewcount})</em>
                                </Link>
                              </div>
                            </div>

                            {/* ------- Course name section ------ */}
                            <div className="leftchampion_text">
                              <h3>{course.label}</h3>
                            </div>

                            {/* ------- Course Categories list section ------ */}
                            <div className="experts_skill">
                              <ul>
                                {course.categories.map((item) => (
                                  <li key={item.id}>
                                    <Link to="#">
                                      <span>{item.label}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            {/* ----- course training start here ----- */}
                            {course.childcourses.length === 0 ? null : (
                              <div className="card_traing_outer">
                                {course.childcourses
                                  .slice(0, 3)
                                  .map((courseName, index) => {
                                    return (
                                      <div
                                        className="card_training_row"
                                        key={index}
                                      >
                                        <div className="card_traning_left">
                                          <span className="material-icons-outlined">
                                            check_circle{" "}
                                          </span>
                                          <p>{courseName.label}</p>
                                        </div>
                                        {/* <div className="card_traning_right">
                                        <h4>78Hr</h4>
                                      </div> */}
                                      </div>
                                    );
                                  })}
                              </div>
                            )}

                            {course.childcourses.length === 0 ? null : (
                              <div className="add_course">
                                {course.childcourses.length <= 3 ? null : (
                                  <Link to="#">
                                    +{course.childcourses.length - 3}{" "}
                                    {t("Course")}
                                  </Link>
                                )}
                              </div>
                            )}
                            {/* ----- course training end here ----- */}

                            {/* ------- Course details section ------ */}
                            <div className="details_btn">
                              <Link
                                to="#"
                                className="basic_btn ylw_btn"
                                onClick={(e) =>
                                  goToCourseDetails(
                                    e,
                                    course.id,
                                    course.istraining
                                  )
                                }
                              >
                                {userInfo.id == course.moderatorid ? (
                                  <i className="material-icons me-3">
                                    done_all
                                  </i>
                                ) : (
                                  <span>
                                    {course.isjoined ? (
                                      <span>
                                        {course.isapproved ? (
                                          <i className="material-icons me-3">
                                            done_all
                                          </i>
                                        ) : (
                                          <i className="material-icons me-3">
                                            done
                                          </i>
                                        )}
                                      </span>
                                    ) : null}{" "}
                                  </span>
                                )}

                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* -------------- Courses Content ends here -------------- */}
    </>
  );
};

export default TrainingCourses;
