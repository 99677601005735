/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

// importing custom components here
import QuestionModal from "components/CourseDetailsComponents/Modals/QuestionModal";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const LessonQuestions = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { id: courseId } = useParams();

  // importing useContext functions here
  const { isCourseUploader } = useContext(GlobalIndraInfo);

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Initial useState to save the content recceived from the props.
  const [questionsData, setQuestionsData] = useState([]);

  // Initial useState to save the student/subscriber question.
  const [userQuestion, setUserQuestion] = useState("");

  // Initial useState to display the warning
  const [showWarning, setShowWarning] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  //function for get questions
  const getAllQuestions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_LESSON_FAQS +
        `?token=${token}&filtercourse=${courseId}&filterlesson=${props.lessonId}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        const responsedData = response.data
          .filter((item) => item.details !== "")
          .sort((a, b) => a.rank - b.rank);

        setQuestionsData(responsedData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Function to submit the student's/subscriber's question to the API.
  const questionSubmitHandler = async (e) => {
    if (userQuestion == "") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      setIsSubmitting(true);

      try {
        let noteData = {
          notetype: "2",
          title: userQuestion,
          details: "",
          rank: 0,
          isprivate: false,
          course: courseId,
          lessonid: props.lessonId,
        };

        console.log(noteData);

        let requestURL =
          url.API_BASE_URL + url.API_ADD_NEW_NOTE + `?token=${token}`;

        console.log(requestURL);

        const response = await postData(requestURL, noteData);

        setIsSubmitting(false);

        if (response.status) {
          let myModal = new bootstrap.Modal(
            document.getElementById("question_success_modal")
          );
          myModal.show();
          setUserQuestion("");
        } else {
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (props.lessonId && courseId) {
      getAllQuestions();
    }
  }, [courseId, props.lessonId]);

  // return from here
  return (
    <div className="tab-pane fade active show" id="questions">
      {questionsData.length === 0 ? (
        <div className="question_pnl lesson_pnl">
          <div className="question_submit_area">
            <div className="input_fill jobinput_fill description_fill">
              <label>{t("Your Questions")}</label>
              {showWarning == true ? (
                <p className="text-danger mt-2">
                  * {t("Please enter the question")}
                </p>
              ) : null}

              <textarea
                className="form-control"
                placeholder={t("Enter you question")}
                value={userQuestion != null ? userQuestion : ""}
                onChange={(e) => {
                  setUserQuestion(e.target.value);
                  setShowWarning(false);
                }}
              ></textarea>
            </div>
            <div className="question_submit">
              <button
                className="basic_btn"
                // data-bs-toggle="modal"
                // data-bs-target="#question_success_modal"
                disabled={isSubmitting ? true : false}
                style={{
                  cursor: isSubmitting ? "not-allowed" : "pointer",
                }}
                onClick={(e) => questionSubmitHandler(e)}
              >
                {t("Submit Question")}
                {isSubmitting && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="question_pnl lesson_pnl">
          <div className="details_faqPnl">
            {/* --------- Accordion area starts here --------- */}
            <div className="accordian_area">
              <div className="accordion accordion-flush" id="faq-accordian">
                {questionsData.map((question, index) => {
                  return (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapse" + index}
                        >
                          <span>{question.title}</span>
                          <i className="material-icons-round">expand_more</i>
                        </button>
                      </h2>
                      <div
                        id={"collapse" + index}
                        className="accordion-collapse collapse"
                        data-bs-parent="#faq-accordian"
                      >
                        <div className="accordion-body">
                          <div className="faq_para">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: question.details,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* --------- Accordion area ends here --------- */}
          </div>

          {/* --------- Question submit area starts here --------- */}
          {/* {userInfo.id == props.questionData.moderatorid ? null : (
            <div className="question_submit_area">
              <div className="input_fill jobinput_fill description_fill">
                <label>{t("Your Questions")}</label>
                {showWarning == true ? (
                  <p className="text-danger mt-2">
                    * {t("Please enter the question")}
                  </p>
                ) : null}

                <textarea
                  className="form-control"
                  placeholder={t("Enter you question")}
                  value={userQuestion != null ? userQuestion : ""}
                  onChange={(e) => {
                    setUserQuestion(e.target.value);
                    setShowWarning(false);
                  }}
                ></textarea>
              </div>
              <div className="question_submit">
                <button
                  className="basic_btn"
                  // data-bs-toggle="modal"
                  // data-bs-target="#question_success_modal"
                  onClick={(e) => questionSubmitHandler(e)}
                >
                  {t("Submit Question")}
                </button>
              </div>
            </div>
          )} */}

          <div className="question_submit_area">
            <div className="input_fill jobinput_fill description_fill">
              <label>{t("Your Questions")}</label>
              {showWarning == true ? (
                <p className="text-danger mt-2">
                  * {t("Please enter the question")}
                </p>
              ) : null}

              <textarea
                className="form-control"
                placeholder={t("Enter you question")}
                value={userQuestion != null ? userQuestion : ""}
                onChange={(e) => {
                  setUserQuestion(e.target.value);
                  setShowWarning(false);
                }}
              ></textarea>
            </div>
            <div className="question_submit">
              <button
                className="basic_btn"
                // data-bs-toggle="modal"
                // data-bs-target="#question_success_modal"
                disabled={isSubmitting ? true : false}
                style={{
                  cursor: isSubmitting ? "not-allowed" : "pointer",
                }}
                onClick={(e) => questionSubmitHandler(e)}
              >
                {t("Submit Question")}
                {isSubmitting && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
          {/* --------- Question submit area ends here --------- */}
        </div>
      )}
      {/* // Importing Modal here */}
      <QuestionModal />
    </div>
  );
};

export default LessonQuestions;
