/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// importing context API here
import { GlobalIndraInfo } from "App";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/HomeAssets/Images/default-user.png";

const Applied = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const glowCount = [1, 2, 3, 4]; //for glow placeholder

  // 👇️ get ID from url
  const params = useParams();

  const { realoadAppliedTab, setRealoadAppliedTab } =
    useContext(GlobalIndraInfo);

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);
  const [appliedCadidates, setAppliedCadidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BASE_URL +
      url.API_CHALLENGES_DETAILS_APPLIED_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setJobDetailsData(response.data);
          if (response.data.responses) {
            setAppliedCadidates(response.data.responses);
          } else {
            setAppliedCadidates([]);
          }
        } else {
          setAppliedCadidates([]);
        }
      } else {
        setAppliedCadidates([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  //reload this tab after apply
  useEffect(() => {
    if (realoadAppliedTab) {
      getJobDetailsData(params.id);
      setRealoadAppliedTab(false);
    }
  }, [realoadAppliedTab]);

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <div className="course_detailspnl">
          <div className="member_box_outer">
            {/* <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-1"
                style={{ height: "22px" }}
              ></span>
            </div> */}
            <div className="member_box_outer">
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-3 col-sm-6" key={count}>
                      <div className="expertbx_item members_teamItem">
                        <div className="membrs_teamOuter">
                          <div className="team_membrs_pic">
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40px", height: "40px" }}
                            ></span>
                          </div>
                          <div className="membrs_team_details flex-fill">
                            <span
                              className="placeholder d-block rounded-3 col-8 mb-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-8 mb-3"></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-8"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="course_detailspnl">
          {appliedCadidates.length === 0 ? (
            <div className="member_box_outer">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("Candidates are not available at this moment.")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="member_box_outer">
              {/* <div className="contnt_hdng tab_Hdng">
                <h4>Applied</h4>
              </div> */}
              <div className="member_box_outer">
                <div className="row">
                  {appliedCadidates.map((candidate, index) => {
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-6" key={index}>
                        <div className="expertbx_item members_teamItem">
                          <div className="membrs_teamOuter">
                            <div className="team_membrs_pic">
                              <img
                                src={
                                  candidate.image
                                    ? candidate.image
                                    : defaultUser
                                }
                                alt="user"
                              />
                            </div>
                            <div className="membrs_team_details">
                              <div className="team_name">
                                <h3>
                                  {candidate.name}
                                  <span> </span>
                                  {candidate.surname}
                                </h3>
                                <h5>{candidate.position}</h5>
                              </div>
                              <div className="team_rating star_color ">
                                <h3>
                                  {" "}
                                  <i className="material-icons-outlined">
                                    star
                                  </i>{" "}
                                  <span>{candidate.rating}</span>
                                </h3>

                                <h6>
                                  {candidate.reviews}
                                  <span>({t("reviews")})</span>
                                </h6>
                              </div>
                              <div className="team_address_value">
                                <h3>
                                  {candidate.location}{" "}
                                  {candidate.price && (
                                    <span>€{candidate.price}</span>
                                  )}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Applied;
