/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { getAllCategories } from "helpers/Common/CommonHelper";

const TabArea = (props) => {
  let { t } = useTranslation();
  const {
    searchQueryValues,
    resetCourseQuery,
    searchJobsQueryValues,
    resetJobsQuery,
    searchChampions,
    refreshChampionLists,
    searchEventsList,
    refreshEventsLists,
    refreshGroupsLists,
    searchGroupsList,
    setCoursePageReset,
    setChampPageReset,
    setEventPageReset,
    setGroupPageReset,
    setJobPageReset,
  } = useContext(GlobalIndraInfo);

  // for routing
  const history = useHistory();

  const token = localStorage.getItem("token");

  /*----- initial state for toggling tabs ------*/
  const [courseTab, setCourseTab] = useState(false);
  const [eventTab, setEventTab] = useState(false);
  const [challengesTab, setChallengesTab] = useState(false);
  const [championsTab, setChampionsTab] = useState(false);
  const [groupTab, setGroupTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  /* ----------- function to display the tabs ----------- */
  const showCourseTab = (e) => {
    e.preventDefault();
    setCourseTab(true);
    setEventTab(false);
    setChallengesTab(false);
    setChampionsTab(false);
    setGroupTab(false);
  };

  const showEventTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(true);
    setChallengesTab(false);
    setChampionsTab(false);
    setGroupTab(false);
  };

  const showChallengesTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(false);
    setChallengesTab(true);
    setChampionsTab(false);
    setGroupTab(false);
  };

  const showChampionsTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(false);
    setChallengesTab(false);
    setChampionsTab(true);
    setGroupTab(false);
  };

  const showGroupTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(false);
    setChallengesTab(false);
    setChampionsTab(false);
    setGroupTab(true);
  };
  /* ----------- function to display the tabs ----------- */

  /* ----------- React select requirements starts here ----------- */
  const [courseSkillsSelectedStatus, setSelectedStatus] = useState(null);
  const [courseSkills, setCourseSkills] = useState([]);
  const [courseCategoriesValue, setCourseCategoriesValue] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [courseTitle, setCourseTitle] = useState("");
  const [skills, setSkills] = useState([]);
  const [category, setCategory] = useState([]);

  /* ----------- Challenges Tab requirements starts here ----------- */
  const [jobTitle, setJobTitle] = useState(""); // Initial useState to save the job title
  const [jobCity, setJobCity] = useState(""); // Initial useState to save the city
  const [selectedJobCategory, setSelectedJobCategory] = useState([]); // Initial useState to save the selection from the React Select Element
  const [jobCategoryList, setJobCategoryList] = useState([]); // Initial useState to get all the options from API
  const [selectedChallengesCategory, setSelectedChallengesCategory] =
    useState(null); // Initial useState to display the selected items
  const [selecetedChallengeFilter, setSelecetedChallengeFilter] =
    useState(null);
  const [jobFilter, setJobFilter] = useState(null);

  const challengeFilterOption = [
    {
      id: 10,
      label: t("All Challenge"),
      name: "AllChallenge",
      value: "all",
    },
    {
      id: 11,
      label: t("My Challenge"),
      name: "MyChallenge",
      value: "mychallenge",
    },
    {
      id: 12,
      label: t("Remote"),
      name: "Remote",
      value: "remote",
    },
  ];

  // function select event filter
  const challengeFilterSelection = (val) => {
    setSelecetedChallengeFilter();
    setJobFilter(val);
  };

  // function for select category
  const challengesCategorySelectionHandler = (val) => {
    let challengeCategoryVal = val.map((item) => {
      return item;
    });
    setSelectedChallengesCategory();
    if (challengeCategoryVal.length == 0) {
      setSelectedJobCategory([]);
    } else {
      setSelectedJobCategory(challengeCategoryVal);
    }
  };

  // function for searching jobs in challenges
  const searchJobsHandler = (e) => {
    e.preventDefault();

    history.push("/challenges");

    let data = {
      jobTitle,
      jobCity,
      selectedJobsCategory: selectedJobCategory,
      jobFilter,
    };

    searchJobsQueryValues(data);
    setJobPageReset(true);
    setJobTitle("");
    setJobCity("");
    setSelectedChallengesCategory(null);
    setSelectedJobCategory([]);
    setSelecetedChallengeFilter(null);
    setJobFilter(null);
  };

  // function for resetting the filter in challenges
  const resetJobsHandler = (e) => {
    e.preventDefault();

    // do something here
    setJobTitle("");
    setJobCity("");
    setSelectedChallengesCategory(null);
    setSelectedJobCategory([]);
    setJobFilter(null);
    resetJobsQuery(true);
  };

  /* ----------- Challenges Tab requirements ends here ----------- */

  /* ----------- Events Tab requirements starts here ----------- */
  // Initial useState to save the event title
  const [eventTitle, setEventTitle] = useState("");

  // Initial useState to save the event city
  const [eventCity, setEventCity] = useState("");

  // Initial useState to save the selection from the React Select Element
  const [selectedEventsItemsCategory, setSelectedEventsItemsCategory] =
    useState([]);

  // Initial useState to get all the options from API
  const [categoriesEventsValue, setEventsCategoriesValue] = useState([]);

  // Initial useState to display the selected items
  const [selectedEventsCategory, setSelectedEventsCategory] = useState(null);

  const [selectedEventFilter, setSelectedEventFilter] = useState(null);
  const [selectedFilterEventItem, setSelectedFilterEventItem] = useState(null);

  const eventFilterOption = [
    {
      id: 11,
      label: "Current Events",
      name: "CurrentEvents",
      value: "current",
    },
    {
      id: 10,
      label: "Upcoming Events",
      name: "UpcomingEvents",
      value: "upcoming",
    },
    { id: 12, label: "My Events", name: "MyEvents", value: "myevents" },
  ];

  // function for select event category
  const eventsCategorySelectionHandler = (val) => {
    setSelectedEventsCategory(val); // only for display purpose
    setSelectedEventsItemsCategory(val);
  };

  // function select event filter
  const eventFilterSelection = (val) => {
    if (val) {
      setSelectedEventFilter(val);
      setSelectedFilterEventItem(val);
    } else {
      setSelectedEventFilter(null);
      setSelectedFilterEventItem(null); // reset the filter
    }
  };

  const searchEventsHandler = (e) => {
    e.preventDefault();

    history.push("/events");

    let data = {
      eventTitle,
      eventCity,
      selectedEventsItemsCategory,
      selectedFilterEventItem,
    };

    searchEventsList(data);
    setEventPageReset(true);
    setEventTitle("");
    setEventCity("");
    setSelectedEventsItemsCategory([]);
    setSelectedEventsCategory(null);
    setSelectedEventFilter(null);
    setSelectedFilterEventItem(null);
  };

  const resetEventsHandler = (e) => {
    e.preventDefault();
    refreshEventsLists(true);
    setEventTitle("");
    setEventCity("");
    setSelectedEventsItemsCategory([]);
    setSelectedEventsCategory(null);
  };
  /* ----------- Events Tab requirements ends here ----------- */

  /* ----------- Champions Tab requirements starts here ----------- */
  const [champName, setChampName] = useState("");
  const [champCategoryLists, setChampCategoryList] = useState([]);
  const [championCity, setChampionCity] = useState("");
  const [selectedChampCategory, setSelectedChampCategory] = useState([]);
  const [champCategory, setChampCategory] = useState([]);

  // function for select champ category
  const champCategoryHandler = (val) => {
    let categoryVal = val.map((item) => {
      let categoryObject = {
        label: item.label,
        id: item.value,
      };
      return categoryObject;
    });

    if (categoryVal.length != 0) {
      setSelectedChampCategory();
      setChampCategory(categoryVal); //set multiselect values in array
    }
  };

  // function for search course
  const searchChampHandler = (e) => {
    e.preventDefault();
    if (props.module != "champions") {
      history.push("/champions");
    }

    let data = {
      champName,
      championCity,
      champCategory,
      // selectedChampCategory,
    };
    searchChampions(data);
    setChampPageReset(true);
    setChampName("");
    setSelectedChampCategory([]);
    setChampionCity("");
    setChampCategory([]);
  };

  //function for refresh
  const refreshChampHandler = (e) => {
    e.preventDefault();
    setChampName("");
    setSelectedChampCategory(null);
    setChampionCity("");
    refreshChampionLists(true);
  };

  /* ----------- Champions Tab requirements ends here ----------- */

  /* ----------- Group Tab requirements starts here ----------- */
  // Initial useState to save the event title
  const [groupTitle, setGroupTitle] = useState("");

  // Initial useState to save the event city
  const [groupCity, setGroupCity] = useState("");

  // Initial useState to get all the options from API
  const [categoriesGroupValue, setGroupsCategoriesValue] = useState([]);

  // Initial useState to save the selection from the React Select Element
  const [selectedGroupItemsCategory, setSelectedGroupsItemsCategory] = useState(
    []
  );

  // Initial useState to display the selected items
  const [selectedGroupCategory, setSelectedGroupCategory] = useState(null);
  //initial fro group type filter
  const [selectedGroupType, setSelectedGroupType] = useState(null);
  const [groupFilterType, setGroupFilterType] = useState(null);

  const groupCategorySelectionHandler = (val) => {
    let challengeCategoryVal = val.map((item) => {
      return item;
    });

    if (challengeCategoryVal.length != 0) {
      setSelectedGroupCategory(); // only for display purpose
      setSelectedGroupsItemsCategory(challengeCategoryVal);
    }
  };

  const groupFilterOptions = [
    {
      id: 11,
      label: t("All Groups"),
      name: "AllGroups",
      value: "all",
    },
    {
      id: 12,
      label: t("My Groups"),
      name: "MyGroups",
      value: "mygroups",
    },
  ];

  const groupFilterSelection = (val) => {
    setSelectedGroupType();
    setGroupFilterType(val);
  };

  // function for search group
  const searchGroupHandler = (e) => {
    e.preventDefault();

    history.push("/groups");

    let data = {
      groupTitle,
      groupCity,
      selectedGroupItemsCategory,
      groupFilterType,
    };

    searchGroupsList(data);
    setGroupPageReset(true);
    setGroupTitle("");
    setGroupCity("");
    setSelectedGroupsItemsCategory([]);
    setSelectedGroupCategory(null);
    setSelectedGroupType(null);
    setGroupFilterType(null);
  };

  // function for refresh
  const refreshGroupHandler = (e) => {
    e.preventDefault();
    refreshGroupsLists(true);
    setGroupTitle("");
    setGroupCity("");
    setSelectedGroupsItemsCategory();
    setSelectedGroupCategory(null);
  };
  /* ----------- Group Tab requirements ends here ----------- */

  /* ----------- Course Tab requirements starts here ----------- */

  const courseFilterOption = [
    {
      id: 11,
      label: "All Courses",
      name: "all",
      value: "all",
    },
    {
      id: 10,
      label: "My Courses",
      name: "mycourse",
      value: "mycourse",
    },
  ];

  //use state for course filter
  const [selectCourseType, setSelectCourseType] = useState(null);
  const [selectedCourseFilter, setSelectedCourseFilter] = useState(null);

  // function for select status
  const courseSkillsSelectionHandler = (val) => {
    setSelectedStatus(val);
    setSkills(val); //set multiselect values in array
  };

  // function for select category
  const courseCategoryHandler = (val) => {
    setSelectedCategory(val);
    setCategory(val); //set multiselect values in array
  };

  // function for select course by filter
  const courseFilterSelection = (val) => {
    setSelectCourseType();
    setSelectedCourseFilter(val);
  };

  // function for search course
  const searchCourseHandler = (e) => {
    e.preventDefault();

    history.push("/courses");

    let data = {
      searchTitle: courseTitle,
      skills,
      category,
      selectedCourseFilter,
    };
    searchQueryValues(data);
    setCoursePageReset(true);
    setCourseTitle("");
    setSelectedCategory(null);
    setSelectedStatus(null);
    setSkills([]);
    setCategory([]);
    setSelectCourseType(null);
    setSelectedCourseFilter(null);
  };

  //function for refresh
  const refreshCourseHandler = (e) => {
    e.preventDefault();

    setCourseTitle("");
    setSelectedCategory(null);
    setSelectedStatus(null);
    resetCourseQuery(true);
  };
  /* ----------- Course Tab requirements ends here ----------- */

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#F0F0F0",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // function to get categories details from API
  const getCategories = async () => {
    try {
      const skillCategoryParentSlug = "CAT_SKILL";

      const skillCategoryResponse = await getAllCategories(
        skillCategoryParentSlug
      );

      if (skillCategoryResponse) {
        setCourseSkills(skillCategoryResponse);
        setChampCategoryList(skillCategoryResponse);
        setJobCategoryList(skillCategoryResponse);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function to get event categories
  const getEventCategories = async () => {
    try {
      const parentSlug = "CAT_EVENT";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setEventsCategoriesValue(response);
      }
    } catch (error) {}
  };

  //function to get group categories
  const getGroupCategories = async () => {
    try {
      const parentSlug = "CAT_GROUP";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setGroupsCategoriesValue(response);
      }
    } catch (error) {}
  };

  //function to get all catgories for course
  const getAllCourseCategories = async () => {
    try {
      const parentSlug = "COURSE";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setCourseCategoriesValue(response);
      }
    } catch (error) {}
  };

  // function for search by enter key
  const searchByEnter = (event) => {
    if (event.key === "Enter") {
      // condition for champion tab
      if (championsTab) {
        searchChampHandler(event);
      }

      // condition for event tab
      if (eventTab) {
        searchEventsHandler(event);
      }

      // condition for group tab
      if (groupTab) {
        searchGroupHandler(event);
      }

      // condition for challenge tab
      if (challengesTab) {
        searchJobsHandler(event);
      }

      // condition for course tab
      if (courseTab) {
        searchCourseHandler(event);
      }
    }
  };

  //function for search for react select
  const searchByEnterSelect = (event) => {
    if (event.key === "Enter") {
      // condition for champion tab
      if (championsTab && champCategory.length > 0) {
        searchChampHandler(event);
      }

      // condition for event tab
      if (eventTab) {
        if (selectedEventsItemsCategory.length > 0) {
          searchEventsHandler(event);
        }

        if (selectedFilterEventItem != null) {
          searchEventsHandler(event);
        }
      }

      // condition for group tab
      if (groupTab) {
        if (selectedGroupItemsCategory.length > 0) {
          searchGroupHandler(event);
        }

        if (groupFilterType != null) {
          searchGroupHandler(event);
        }
      }

      // condition for challenge tab
      if (challengesTab) {
        if (selectedJobCategory.length > 0) {
          searchJobsHandler(event);
        }

        if (jobFilter != null) {
          searchJobsHandler(event);
        }
      }

      // condition for course tab
      if (courseTab) {
        if (skills.length > 0) {
          searchCourseHandler(event);
        }

        if (category.length > 0) {
          searchCourseHandler(event);
        }

        if (selectedCourseFilter != null) {
          searchCourseHandler(event);
        }
      }
    }
  };

  // useeffect for get category lists
  useEffect(() => {
    getCategories();
    getGroupCategories();
    getEventCategories();
    getAllCourseCategories();
  }, []);

  // useEffect based on props
  useEffect(() => {
    // do something here
    if (props.module == "course") {
      setCourseTab(true);
    } else if (props.module == "event") {
      setEventTab(true);
    } else if (props.module == "groups") {
      setGroupTab(true);
    } else if (props.module == "champions") {
      setChampionsTab(true);
    } else if (props.module == "challenges") {
      setChallengesTab(true);
    } else {
      setChampionsTab(true);
    }
  }, [props.module]);

  return (
    <section className="sports_tabArea">
      <div className="container">
        <div className="sports_tabinnr">
          {/* -------------- Tab Activation links starts here -------------- */}
          <div className="sport_tabLinks">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {/* --------- Champions Link Start --------- */}
              <li className="nav-item">
                <Link
                  className={
                    championsTab == true ? "nav-link active" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => {
                    showChampionsTab(e);
                  }}
                >
                  Champions
                </Link>
              </li>
              {/* --------- Champions Link End --------- */}

              {/* --------- Event Link start --------- */}
              <li className="nav-item">
                <Link
                  className={eventTab == true ? "nav-link active" : "nav-link"}
                  to="#"
                  onClick={(e) => {
                    showEventTab(e);
                  }}
                >
                  {t("Events")}
                </Link>
              </li>
              {/* --------- Event Link End--------- */}

              {/* --------- Group Link Start --------- */}
              <li className="nav-item">
                <Link
                  className={groupTab == true ? "nav-link active" : "nav-link"}
                  to="#"
                  onClick={(e) => {
                    showGroupTab(e);
                  }}
                >
                  {t("Groups")}
                </Link>
              </li>
              {/* --------- Group Link End --------- */}

              {/* --------- Challenges Link Start --------- */}
              <li className="nav-item">
                <Link
                  className={
                    challengesTab == true ? "nav-link active" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => {
                    showChallengesTab(e);
                  }}
                >
                  {t("Challenges")}
                </Link>
              </li>
              {/* --------- Challenges Link End --------- */}

              {/* --------- Course Link start --------- */}
              <li className="nav-item">
                <Link
                  className={courseTab == true ? "nav-link active" : "nav-link"}
                  to="#"
                  onClick={(e) => {
                    showCourseTab(e);
                  }}
                >
                  {t("Course")}
                </Link>
              </li>
              {/* --------- Course Link end --------- */}
            </ul>
          </div>
          {/* -------------- Tab Activation links ends here -------------- */}

          {/* -------------- Tab Section starts here -------------- */}
          <div className="tab-content">
            {/* --------------- Champions section starts here --------------- */}
            <div className="tab-pane fade show active" id="champions_tab">
              {championsTab && (
                <div className="tab_formOuter">
                  {/* --------- Champions Title --------- */}
                  <div className="tab_fill model_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Name/Surname")}
                      value={champName}
                      onChange={(e) => setChampName(e.target.value)}
                      onKeyDown={searchByEnter}
                    />
                  </div>

                  {/* ------ Champions Categories multi-select ------ */}
                  <div className="tab_fill select_fill">
                    <Select
                      options={champCategoryLists}
                      value={selectedChampCategory}
                      isMulti={true}
                      onChange={(val) => champCategoryHandler(val)}
                      classNamePrefix="select2-selection"
                      placeholder={t("Select Skill")}
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>

                  {/* --------- Champions City --------- */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Location")}
                        value={championCity}
                        onChange={(e) => setChampionCity(e.target.value)}
                        onKeyDown={searchByEnter}
                      />
                    </div>
                  </div>

                  {/* --------- Champions Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    {/* ---------- Search button starts here ---------- */}
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchChampHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* ---------- Reset button starts here ---------- */}
                    {/* <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      onClick={(e) => refreshChampHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Champions section ends here --------------- */}

            {/* --------------- Event section starts here --------------- */}
            <div className="tab-pane fade show active" id="event_tab">
              {eventTab && (
                <div className="tab_formOuter">
                  {/* --------- Event Title --------- */}
                  <div className="tab_fill model_fill global_search_col-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Name/Details")}
                      value={eventTitle}
                      onChange={(e) => setEventTitle(e.target.value)}
                      onKeyDown={searchByEnter}
                    />
                  </div>

                  {/* ------ Event Categories multi-select ------ */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <Select
                      isMulti={true}
                      placeholder={t("Select Category")}
                      options={categoriesEventsValue}
                      value={selectedEventsCategory}
                      onChange={(val) => {
                        eventsCategorySelectionHandler(val);
                      }}
                      classNamePrefix="select2-selection"
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>

                  {/* --------- Event City --------- */}
                  <div className="tab_fill locate_fill global_search_col-4">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Location")}
                        value={eventCity}
                        onChange={(e) => setEventCity(e.target.value)}
                        onKeyDown={searchByEnter}
                      />
                    </div>
                  </div>

                  {/* ---- event filter start ---- */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <Select
                      isClearable
                      placeholder={t("Type")}
                      options={eventFilterOption} // show all options
                      value={selectedEventFilter} // show selected values
                      onChange={(val) => {
                        eventFilterSelection(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>
                  {/* ---- event filter end ---- */}

                  {/* --------- Event search and rest Button Start --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchEventsHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      onClick={(e) => resetEventsHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button> */}
                  </div>
                  {/* --------- Event search and rest Button end --------- */}
                </div>
              )}
            </div>
            {/* --------------- Event section ends here --------------- */}

            {/* --------------- Group section starts here --------------- */}
            <div className="tab-pane fade show active" id="group_tab">
              {groupTab && (
                <div className="tab_formOuter">
                  {/* --------- Group Title --------- */}
                  <div className="tab_fill model_fill global_search_col-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Title / Group Code")}
                      value={groupTitle}
                      onChange={(e) => setGroupTitle(e.target.value)}
                      onKeyDown={searchByEnter}
                    />
                  </div>

                  {/* ------ Group Categories multi-select ------ */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <Select
                      isMulti={true}
                      placeholder={t("Select Category")}
                      options={categoriesGroupValue} // show all options
                      value={selectedGroupCategory} // show selected values
                      onChange={(val) => {
                        groupCategorySelectionHandler(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>

                  {/* --------- Group City --------- */}
                  <div className="tab_fill locate_fill global_search_col-4">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Location")}
                        value={groupCity}
                        onChange={(e) => setGroupCity(e.target.value)}
                        onKeyDown={searchByEnter}
                      />
                    </div>
                  </div>

                  {/* ---- group filter start ---- */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <Select
                      isMulti={false}
                      placeholder={t("Type")}
                      options={groupFilterOptions} // show all options
                      value={selectedGroupType} // show selected values
                      onChange={(val) => {
                        groupFilterSelection(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>
                  {/* ---- group filter end ---- */}

                  {/* ------- Group Button search and refresh start ------ */}
                  <div className="submit_fill submit_rfreshOuter">
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchGroupHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      onClick={(e) => refreshGroupHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button> */}
                  </div>
                  {/* ------- Group Button search and refresh end ------ */}
                </div>
              )}
            </div>
            {/* --------------- Group section ends here --------------- */}

            {/* --------------- Challenges section starts here --------------- */}
            <div className="tab-pane fade show active" id="challenges_tab">
              {challengesTab && (
                <div className="tab_formOuter">
                  {/* --------- Job Title --------- */}
                  <div className="tab_fill model_fill global_search_col-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Title / Challenge Code")}
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      onKeyDown={searchByEnter}
                    />
                  </div>

                  {/* ------ Job Categories multi-select ------ */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <Select
                      isMulti={true}
                      placeholder={t("Select skills")}
                      options={jobCategoryList} // show all options
                      value={selectedChallengesCategory} // show selected values
                      onChange={(val) => {
                        challengesCategorySelectionHandler(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>

                  {/* --------- City --------- */}
                  <div className="tab_fill locate_fill global_search_col-4">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Location")}
                        value={jobCity}
                        onChange={(e) => setJobCity(e.target.value)}
                        onKeyDown={searchByEnter}
                      />
                    </div>
                  </div>

                  {/* ---- challenge filter start ---- */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <Select
                      isMulti={false}
                      placeholder={t("Type")}
                      options={challengeFilterOption} // show all options
                      value={selecetedChallengeFilter} // show selected values
                      onChange={(val) => {
                        challengeFilterSelection(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      // styles={customSelectStyle}
                      onKeyDown={searchByEnterSelect}
                    />
                  </div>
                  {/* ---- challenge filter end ---- */}

                  {/* ------- Challenges Button search and refresh start ------ */}
                  <div className="submit_fill submit_rfreshOuter">
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchJobsHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      onClick={(e) => resetJobsHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button> */}
                  </div>
                  {/* ------- Challenges Button search and refresh end ------ */}
                </div>
              )}
            </div>
            {/* --------------- Challenges section ends here --------------- */}

            {/* --------------- Course section starts here --------------- */}
            <div className="tab-pane fade show active" id="course_tab">
              {courseTab && (
                <div className="tab_formOuter">
                  {/* --------- Course Title --------- */}
                  <div className="tab_fill model_fill global_search_col-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Title / Course Code")}
                      value={courseTitle}
                      onChange={(e) => setCourseTitle(e.target.value)}
                      onKeyDown={searchByEnter}
                    />
                  </div>

                  {/* ------ Courses Skills multi-select ------ */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <div className="adress_fill">
                      <Select
                        isMulti={true}
                        placeholder={t("Skills")}
                        options={courseSkills}
                        classNamePrefix="select2-selection"
                        value={courseSkillsSelectedStatus}
                        onChange={(val) => {
                          courseSkillsSelectionHandler(val);
                        }}
                        // styles={customSelectStyle}
                        onKeyDown={searchByEnterSelect}
                      />
                    </div>
                  </div>

                  {/* ------ Course Categories multi-select ------ */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <div className="adress_fill">
                      <Select
                        isMulti={true}
                        placeholder={t("Category")}
                        options={courseCategoriesValue}
                        classNamePrefix="select2-selection"
                        value={selectedCategory} // show selected values
                        onChange={(val) => {
                          courseCategoryHandler(val);
                        }} // handler function
                        // styles={customSelectStyle}
                        onKeyDown={searchByEnterSelect}
                      />
                    </div>
                  </div>

                  {/* ---- course filter start ---- */}
                  <div className="tab_fill select_fill global_search_col-4">
                    <div className="adress_fill">
                      <Select
                        isMulti={false}
                        placeholder={t("Type")}
                        options={courseFilterOption} // show all options
                        value={selectCourseType} // show selected values
                        onChange={(val) => {
                          courseFilterSelection(val);
                        }} // handler function
                        classNamePrefix="select2-selection"
                        // styles={customSelectStyle}
                        onKeyDown={searchByEnterSelect}
                      />
                    </div>
                  </div>
                  {/* ---- course filter end ---- */}

                  {/* --------- Courses Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchCourseHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* <button
                      className="submit_btn form_submit refresh_btn"
                      style={{ marginLeft: "10px" }}
                      onClick={(e) => refreshCourseHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Course section ends here --------------- */}
          </div>
          {/* -------------- Tab Section starts here -------------- */}
        </div>
      </div>
    </section>
  );
};

export default TabArea;
